import React, { useEffect, useState } from 'react'
import { FaArrowAltCircleRight } from 'react-icons/fa'
import { useNavigate, useParams } from 'react-router-dom'
import { useAuth } from '../../middleware/AuthContext'
import { toast } from 'react-toastify'
import axios from 'axios'
import { server } from '../../server'
import AdminSidebar from './components/AdminSidebar'
import AdminHeadbar from './components/AdminHeadbar'
import Select from 'react-tailwindcss-select'

const AddReseller = () => {
    const [formData, setFormData] = useState(
        {   
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            companyName: '',
            country: '',
            address: '',
            password: '',
        }
    );

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
      const handleSubmit = async (e) => {
        e.preventDefault(); 
        try {
            // Send updated data to the server
            await axios.post(`${server}/resellers/admin-create-reseller`, formData);
            // Optionally, you can fetch the updated data again to reflect changes in the UI
            toast.success('Reseller Created successfully!');
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        } catch (error) {
            console.error('Error creating reseller:', error);
            toast.error({error});
        }
    };

  return (
    <div className='flex'>
      <div className='w-[20%]'>
        <AdminSidebar />
      </div>
      <main className='w-[80%]'>
        <AdminHeadbar />
        <form className='p-[40px] bg-[#f8f9fc]' onSubmit={handleSubmit}> 
            <div className='border-b-2 border-dashed py-[30px] flex items-center'>
                <span className='font-medium text-xl'>Create a Reseller</span>
            </div>
            <div className='flex p-[30px] border-b-2 border-dashed'>
                <div className='basis-[30%]'>
                    <h2 className='font-semibold mb-4'>Information</h2>
                    <p className='text-gray-600'>Add personal information</p>
                </div>
                <div className='basis-[70%] bg-white p-10'>
                    <div className=" mx-auto">
                        <div className="mb-5">
                            <label for="name" class="block mb-2 text-sm font-medium text-gray-900">Reseller First Name</label>
                            <input type="text" id="name" name='firstName' value={formData.firstName} onChange={handleChange} class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div className="mb-5">
                            <label for="price" class="block mb-2 text-sm font-medium text-gray-900">Reseller Last Name</label>
                            <input type="text" id="price" name='lastName' value={formData.lastName} onChange={handleChange}  class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="" required />
                        </div>
                        <div className="mb-5">
                            <label for="sku" class="block mb-2 text-sm font-medium text-gray-900">Email Address</label>
                            <input type="email" id="sku" name='email' value={formData.email} onChange={handleChange}  class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div className='mb-5'>
                            <label for="phoneNumber" class="block mb-2 text-sm font-medium text-gray-900">Phone Number</label>
                            <input type="tel" id="sku" name='phoneNumber' value={formData.phoneNumber} onChange={handleChange}  class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />                        </div>
                    </div> 
                </div>
            </div>
            <div className='flex p-[30px]'>
                <div className='basis-[30%]'>
                    <h2 className='font-semibold mb-4'>Company Information</h2>
                    <p className='text-gray-600'>Fill the form below or write us. We will help you create your account</p>
                </div>
                <div className='basis-[70%] bg-white p-10'>
                    <div className=" mx-auto">
                        <div className="mb-5">
                            <label for="warranty" class="block mb-2 text-sm font-medium text-gray-900">Company Name</label>
                            <input type="text" name="companyName" value={formData.companyName} onChange={handleChange} placeholder='company name' id="warranty" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div className="mb-5">
                            <label for="warranty" class="block mb-2 text-sm font-medium text-gray-900">Country</label>
                            <input type="text" name="country" value={formData.country} onChange={handleChange} placeholder='Kenya' id="warranty" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div className="mb-5">
                            <label for="warranty" class="block mb-2 text-sm font-medium text-gray-900">Address</label>
                            <input type="text" name="address" value={formData.address} onChange={handleChange} placeholder='St Boulevard' id="warranty" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div className="mb-5">
                            <label for="warranty" class="block mb-2 text-sm font-medium text-gray-900">Password</label>
                            <input type="text" name="password" value={formData.password} onChange={handleChange} placeholder='****' id="warranty" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                    </div> 
                </div>
            </div>
            <div className='w-full flex justify-between items-center'>
                <a href='/supplier/account' className='flex items-center gap-1 text-orange-600'>
                    <FaArrowAltCircleRight />
                    Go to account
                </a>
                <div className='flex gap-2'>
                    <a href='/admin/resellers' class="border border-orange-600 hover:bg-orange-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Cancel</a>
                    <button type="submit" class="text-white bg-orange-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Create Reseller</button>
                </div>
            </div>
        </form>
        </main>
    </div>
  )
}

export default AddReseller