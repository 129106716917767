import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";


const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    reseller: null,
    token: "",
    supplier: null,
    admin: null,
  });

  //default axios
  axios.defaults.headers.common["Authorization"] = auth?.token;

  useEffect(() => {
    const data = localStorage.getItem("auth");
    if (data) {
      const parseData = JSON.parse(data);
      setAuth({
        ...auth,
        reseller: parseData.reseller,
        token: parseData.token,
        supplier:parseData.supplier,
        admin:parseData.admin
      });
    }
    //eslint-disable-next-line
  }, []);
  return (
    <AuthContext.Provider value={[auth, setAuth]}>
      {children}
    </AuthContext.Provider>
  );
};

// custom hook
const useAuth = () => useContext(AuthContext);

export { useAuth, AuthContextProvider };