import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import spin from '../assets/Logo-1.gif'

const Spinner = ({ path = "login" }) => {
  const [count, setCount] = useState(3);
  const navigate = useNavigate();
  const location = useLocation();
  

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prevValue) => --prevValue);
    }, 1000);
    count === 0 &&
      navigate(`/${path}`, {
        state: location.pathname,
      });
    return () => clearInterval(interval);
  }, [count, navigate, location, path]);
  return (
    <>
      <div
        className=""
        style={{ height: "100vh", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}
      >
        <img src={spin} alt="" />
        <h1 className="Text-center">redirecting to you in {count} second </h1>
        {/* <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div> */}
      </div>
    </>
  );
};

export default Spinner;
