import React from 'react';
import { Link } from 'react-router-dom';


const BreadCrumbs = ({ items }) => {
  return (
    <div className="breadcrumbs">
      {items.map((item, index) => (
        <span key={index}>
          <Link to={item.path}>{item.name}</Link>
          {index < items.length - 1 && <span> {'>'} </span>}
        </span>
      ))}
    </div>
  );
};

export default BreadCrumbs;
