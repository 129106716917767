import React, { useEffect, useState } from 'react'
import AdminHeadbar from './components/AdminHeadbar'
import AdminSidebar from './components/AdminSidebar'
import profile from '../../assets/GooglePlayStore.png'
import axios from 'axios'
import { toast } from 'react-toastify'
import { server } from '../../server'

const PendingResellers = () => {
    const [resellers, setResellers] = useState([]);
    const [selectedReseller, setSelectedReseller] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isRejectModalOpen, setRejectModalOpen] = useState(false);

    useEffect(() => {
        const fetchResellers = async () => { 
            try {
                const response = await axios.get(`${server}/resellers/unapproved-resellers`);
                setResellers(response.data);
            } catch (error) {
                console.error(error);
            } 
        };
        
        fetchResellers();
    }, []);

    const handleApprove = async () => {
        try {
            const response = await axios.patch(`${server}/resellers/approve-reseller/${selectedReseller._id}`);
            toast.success(response.data.message);
            // Refresh the reseller list after approval
            const updatedResellers = resellers.filter(reseller => reseller._id !== selectedReseller._id);
            setResellers(updatedResellers);
            setIsModalOpen(false);
        } catch (error) {
            toast.error('Failed to approve reseller');
        }
    };
    const handleReject = async () => {
        try {
            const response = await axios.patch(`${server}/resellers/reject-reseller/${selectedReseller._id}`);
            toast.success(response.data.message);
            // Refresh the reseller list after approval
            const updatedResellers = resellers.filter(reseller => reseller._id !== selectedReseller._id);
            setResellers(updatedResellers);
            setIsModalOpen(false)
        } catch (error) {
            toast.error('Failed to reject reseller');
        }
    };

    

    const toggleModal = (reseller) => {
        setIsModalOpen(!isModalOpen);
        setSelectedReseller(reseller)
    };

    const toggleRejectModal = (reseller) => {
        setRejectModalOpen(!isRejectModalOpen);
        setSelectedReseller(reseller)
    }

    return (
        <div className='flex'>
            <div className='w-[20%]'>
                <AdminSidebar />
            </div>
            <main className='w-[80%]'>
                <AdminHeadbar />
                <div className='m-[30px] bg-[#f8f9fc]'>
                    <div className='bg-[#fff] flex justify-between p-[20px] items-center mb-10'>
                        <div className='border-l-4 border-orange-500 rounded-lg p-4'>
                            <span className='text-2xl font-semibold'>Pending Resellers</span>
                        </div>
                    </div>
                    <div className='bg-[#fff] h-[70vh]'>
                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg overflow-y-auto">
                            <table className="w-full text-sm text-left rtl:text-right text-gray-500 overflow-y-auto">
                                <thead className="text-xs text-gray-700 uppercase bg-[#fff]">
                                    <tr>
                                        <th scope="col" className="p-4">
                                            <div className="flex items-center">
                                                <input id="checkbox-all-search" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                                <label for="checkbox-all-search" className="sr-only">checkbox</label>
                                            </div>
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Name
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Email
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Status
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {resellers && resellers.map((reseller) => (
                                        <tr className="bg-white border-b hover:bg-gray-50" key={reseller._id}>
                                            <td className="w-4 p-4">
                                                <div className="flex items-center">
                                                    <input id="checkbox-table-search-1" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                                    <label for="checkbox-table-search-1" className="sr-only">checkbox</label>
                                                </div>
                                            </td>
                                            <th scope="row" className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap">
                                                <img className="w-10 h-10 rounded-full" src={profile} alt="Jese images"/>
                                                <div className="ps-3">
                                                    <div className="text-base font-semibold">{reseller.firstName} {reseller.lastName}</div>
                                                    <div className="font-normal text-gray-500">{reseller.email}</div>
                                                </div>  
                                            </th>
                                            <td className="px-6 py-4">
                                                <div className='flex'>
                                                    <span class="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full">{reseller.email}</span>
                                                </div>
                                            </td>
                                            <td className="px-6 py-4">
                                                <div className="flex items-center">
                                                    <span class="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded  border border-red-400">{reseller.status}</span>
                                                </div>
                                            </td>
                                            <td className="px-6 py-4">
                                                <div className='flex items-center justify-center gap-2'>
                                                <div className=''>
                                                    <button onClick={() => toggleModal(reseller)} className="text-green-500" type="button">Approve</button>
                                                </div>
                                                <div className=''>
                                                    <button onClick={() => toggleRejectModal(reseller)} className="text-red-500" type="button">Reject</button>
                                                </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}       
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </main>
            {isModalOpen && (
                <div id="popup-modal" tabIndex="-1" className="fixed top-[100px] left-[40%] z-50 justify-center items-center w-full">
                    <div className="relative p-4 w-full max-w-md max-h-full">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <button type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={toggleModal}>
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                            <div className="p-4 md:p-5 text-center">
                                <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                                </svg>
                                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to approve this reseller?</h3>
                                <button data-modal-hide="popup-modal" type="button" onClick={handleApprove} className="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                                    Yes, I'm sure
                                </button>
                                <button data-modal-hide="popup-modal" onClick={toggleModal} type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">No, cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {isRejectModalOpen && (
                <div id="popup-modal" tabIndex="-1" className="fixed top-[100px] left-[40%] z-50 justify-center items-center w-full">
                    <div className="relative p-4 w-full max-w-md max-h-full">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <button type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={toggleRejectModal}>
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                            <div className="p-4 md:p-5 text-center">
                                <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                                </svg>
                                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to reject this reseller?</h3>
                                <button data-modal-hide="popup-modal" type="button" onClick={handleReject} className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                                    Yes, I'm sure
                                </button>
                                <button data-modal-hide="popup-modal" onClick={toggleRejectModal} type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">No, cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default PendingResellers;
