import React, { useState } from 'react'
import { useAuth } from '../../../middleware/AuthContext';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';


const SupplierHeadbar = () => {
    const [open, setOpen] = useState(false);
    const [auth, setAuth] = useAuth();
    const supplier = auth.supplier;

    const navigate = useNavigate()

    const toggleDropdown = () => {
        setOpen(!open);
    };

    const handleLogout = () => {
        setAuth({
            ...auth,
            supplier: null,
            token: "",
        });
        localStorage.removeItem("auth");
        toast.success("Logout Successfully");
        navigate('/supplier-login')
    };


    return (
        <div className='flex items-center bg-white h-[60px] shadow-md justify-between sm:px-5'>
            <form class="sm:w-[400px] hidden sm:block h-[40px]">
                <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only">Search</label>
                <div class="relative">
                    <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                        <svg class="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                        </svg>
                    </div>
                    <input type="search" id="default-search" class="block w-[400px] h-[40px] p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500" placeholder="Search Here..." required />
                    <button type="submit" class="text-white absolute end-1 bottom-0.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2">Search</button>
                </div>

            </form>
            <div class="flex items-center justify-end w-full h-[40px] gap-1">
                {/* <Notifications
                    data={data}
                    className="sm:block hidden"
                /> */}
                
                <div className="bg-white shadow-md w-50 flex justify-center items-center">
                    <div onClick={toggleDropdown} className={`relative border-b-4 border-transparent py-3 ${open ? 'border-indigo-700 transform transition duration-300' : ''}`}
                        style={{ transitionProperty: 'border-color, transform' }}
                    >
                        <div className="flex justify-center items-center space-x-3 cursor-pointer h-[30px]">
                            <div className="w-[40px] h-[40px] rounded-full overflow-hidden border-2 border-gray-900">
                                <img src="https://images.unsplash.com/photo-1610397095767-84a5b4736cbd?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80" alt="" className="w-full h-full object-cover" />
                            </div>
                            <div className="font-semibold text-gray-900 text-[12px]">
                                <div className="cursor-pointer">{supplier.firstName} {supplier.lastName}</div>
                                <div className='text-gray-500'>{supplier.roles}</div>
                            </div>
                        </div>
                        {open && (
                            <div className="absolute w-[150px] px-5 py-3 bg-white rounded-lg shadow border mt-5 z-10">
                                <ul className="space-y-3">
                                    <li className="font-medium">
                                        <a href="/supplier/account" className="flex items-center transform transition-colors duration-200 border-r-4 border-transparent hover:border-indigo-700">
                                            <div className="mr-3">
                                                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path></svg>
                                            </div>
                                            Account
                                        </a>
                                    </li>
                                    <hr className="dark:border-gray-700" />
                                    <li className="font-medium">
                                        <button onClick={handleLogout} className="flex items-center transform transition-colors duration-200 border-r-4 border-transparent hover:border-red-600">
                                            <div className="mr-3 text-red-600">
                                                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path></svg>
                                            </div>
                                            Logout
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            </div>

        </div>
        //  </div>
    )
}

export default SupplierHeadbar