import React, { useEffect, useState } from 'react'
import { FaArrowRight, FaLocationDot } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import axios from 'axios';
import { server } from '../server';


const Header = () => {
  const [info, setInfo] = useState([])

    useEffect(() => {
        const fetchSuppliers = async () => { 
          try {
            const response = await axios.get(`${server}/info`);
            setInfo(response.data[0]);
          } catch (error) {
            
          } 
        };
        fetchSuppliers();
      }, []);
  return (
    <div className='hidden sm:bg-slate-100 sm:w-full sm:h-[30px] sm:flex sm:justify-between sm:items-center sm:px-5'>
        
        <div className='flex items-center justify-between w-[400px]'>
            <span className='flex items-center gap-1 text-[12px]'>
            <FaPhoneAlt size={15} color='green' />
            {info.phone}
            </span>
            <span className='flex items-center gap-1 text-[12px]'>
            <FaLocationDot size={15} color='blue' />
            {info.address}
            </span>
            <span className='flex items-center gap-1 text-[12px]'>
            <MdEmail size={15} color='red' />
            {info.email}
            </span>
        </div>
        <span className='text-[12px]'>Welcome to ResellerSprint. All in one catalogue</span>
        <a className='text-orange-600 text-[12px] flex items-center gap-1' href="/supplier-login">
            Supplier Login/Register
            <FaArrowRight />
        </a>
    </div>
  )
}

export default Header