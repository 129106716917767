import React, { useEffect, useState } from 'react'
import { FaArrowAltCircleRight } from 'react-icons/fa'
import SupplierHeadbar from './components/SupplierHeadbar'
import SupplierSidebar from './components/SupplierSidebar'
import { useNavigate, useParams } from 'react-router-dom'
import { useAuth } from '../../middleware/AuthContext'
import { toast } from 'react-toastify'
import axios from 'axios'
import { server } from '../../server'
import Select from 'react-tailwindcss-select'

const EditSupplier = () => {
    const {id} = useParams();
    const [categories, setCategories] = useState([]);
    const [brands, setBrands] = useState([]);
    const [supplier, setSupplier] = useState(
        {   
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            companyName: '',
            companyEmail: '', 
            companyType: '',
            dollarExchangeRate: '',
            country: '',
            address: '',
            password: '',
            categories: [],
            brand: ''
        }
    );

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSupplier(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    useEffect(() => {
        const fetchCategoriesAndBrands = async () => {
            try {
                const categoriesResponse = await axios.get(`${server}/categories`);
                setCategories(categoriesResponse.data);

                const brandsResponse = await axios.get(`${server}/brands`);
                setBrands(brandsResponse.data);
            } catch (error) {
                console.error('Error fetching categories and brands:', error);
            }
        };

        fetchCategoriesAndBrands();
    }, []);


    useEffect(() => {
        const fetchSupplier = async () => { 
          try {
            const response = await axios.get(`${server}/suppliers/get-supplier/${id}`);
            setSupplier(response.data.supplier);
          } catch (error) {
            
          } 
        };
        fetchSupplier();
      }, [id]);

      const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Send updated data to the server
            await axios.patch(`${server}/suppliers/update-supplier/${id}`, supplier);
            // Optionally, you can fetch the updated data again to reflect changes in the UI
            toast.success('Data updated successfully!');
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        } catch (error) {
            console.error('Error updating supplier:', error);
            toast.error({error});
        }
    };

  return (
    <div className='flex'>
      <div className='w-[20%]'>
        <SupplierSidebar />
      </div>
      <main className='w-[80%]'>
        <SupplierHeadbar />
        <form className='sm:p-[40px] px-[10px] bg-[#f8f9fc]' onSubmit={handleSubmit}> 
            <div className='border-b-2 border-dashed py-[30px] flex items-center'>
                <span className='font-medium text-xl'>Edit Your Account</span>
            </div>
            <div className='sm:flex sm:p-[30px] px-[10px] border-b-2 border-dashed'>
                <div className='basis-[30%]'>
                    <h2 className='font-semibold mb-4'>Information</h2>
                    <p className='text-gray-600'>Edit personal information</p>
                </div>
                <div className='basis-[70%] bg-white sm:p-10 p-2'>
                    <div className=" mx-auto">
                        <div className="mb-5">
                            <label for="name" class="block mb-2 text-sm font-medium text-gray-900">Supplier First Name</label>
                            <input type="text" id="name" name='firstName' value={supplier.firstName} onChange={handleChange} class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div className="mb-5">
                            <label for="price" class="block mb-2 text-sm font-medium text-gray-900">Supplier Last Name</label>
                            <input type="text" id="price" name='lastName' value={supplier.lastName} onChange={handleChange}  class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="" required />
                        </div>
                        <div className="mb-5">
                            <label for="sku" class="block mb-2 text-sm font-medium text-gray-900">Email Address</label>
                            <input type="email" id="sku" name='email' value={supplier.email} onChange={handleChange}  class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div className='mb-5'>
                            <label for="phoneNumber" class="block mb-2 text-sm font-medium text-gray-900">Phone Number</label>
                            <input type="tel" id="sku" name='phoneNumber' value={supplier.phoneNumber} onChange={handleChange}  class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />                        </div>
                    </div> 
                </div>
            </div>
            <div className='sm:flex sm:p-[30px] px-[10px]'>
                <div className='basis-[30%]'>
                    <h2 className='font-semibold mb-4'>Company Information</h2>
                    <p className='text-gray-600'>Fill the form below or write us. We will help you create your account</p>
                </div>
                <div className='basis-[70%] bg-white sm:p-10 p-2'>
                    <div className=" mx-auto">
                        <div className="mb-5">
                            <label for="name" class="block mb-2 text-sm font-medium text-gray-900">Category</label>
                            <select name="category" id="" value={supplier.categories} onChange={handleChange} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                {categories && categories.map((category) => (
                                    <option key={category._id} value={category.name}>{category.name}</option>
                                ))}                                
                            </select>
                        </div>
                        <div className="mb-5">
                            <label for="name" class="block mb-2 text-sm font-medium text-gray-900">Category</label>
                            <select name="category" id="" value={supplier.brand} onChange={handleChange} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                {brands && brands.map((brand) => (
                                    <option key={brand._id} value={brand.name}>{brand.name}</option>
                                ))}                                
                            </select>
                        </div>
                        <div className="mb-5">
                            <label for="warranty" class="block mb-2 text-sm font-medium text-gray-900">Company Name</label>
                            <input type="text" name="companyName" value={supplier.companyName} onChange={handleChange} placeholder='12' id="warranty" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div className="mb-5">
                            <label for="warranty" class="block mb-2 text-sm font-medium text-gray-900">Company Email</label>
                            <input type="email" name="companyEmail" value={supplier.companyEmail} onChange={handleChange} placeholder='12' id="warranty" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div className="mb-5">
                            <label class="block mb-2 text-sm font-medium text-gray-900">Company Type</label>
                            <select name="companyType" id="" value={supplier.companyType} onChange={handleChange}  class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                <option value="" disabled>Company Type</option>
                                <option value="supplier">Supplier</option>
                                <option value="manufacturer">Manufacturer</option>
                            </select>
                        </div>
                        <div className="mb-5">
                            <label for="warranty" class="block mb-2 text-sm font-medium text-gray-900">Address</label>
                            <input type="text" name="address" value={supplier.address} onChange={handleChange} placeholder='12' id="warranty" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div className="mb-5">
                            <label for="warranty" class="block mb-2 text-sm font-medium text-gray-900">Dollar Exchange Rate</label>
                            <input type="number" name="dollarExchangeRate" value={supplier.dollarExchangeRate} onChange={handleChange} placeholder='12' id="warranty" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                    </div> 
                </div>
            </div>
            <div className='w-full sm:mb-4 mb-0 flex justify-between items-center'>
                <a href='/supplier/account' className='sm:flex hidden items-center gap-1 text-orange-600'>
                    <FaArrowAltCircleRight />
                    Go to account
                </a>
                <div className='flex gap-2 '>
                    <a href='/supplier/account' class="border border-orange-600 hover:bg-orange-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Account</a>
                    <button type="submit" class="text-white bg-orange-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Update Details</button>
                </div>
            </div>
        </form>
        </main>
    </div>
  )
}

export default EditSupplier