import React, { useEffect, useState } from 'react'
import SupplierHeadbar from './components/SupplierHeadbar'
import SupplierSidebar from './components/SupplierSidebar'
import logo from '../../assets/GooglePlayStore.png'
import { FaExternalLinkAlt, FaRegEdit } from 'react-icons/fa'
import { MdOutlineEmail } from 'react-icons/md'
import { CiLocationOn, CiPhone } from 'react-icons/ci'
import { FaCartFlatbed, FaDollarSign} from 'react-icons/fa6'
import { RiAdvertisementLine } from 'react-icons/ri'
import { useAuth } from '../../middleware/AuthContext'
import axios from 'axios'
import { server } from '../../server'

const SupplierAccountPage = () => {
    const [auth, setAuth] = useAuth();
    const [supplierProducts, setSupplierProducts] = useState([])
    const supplier = auth.supplier;
    const [supplierAds, setSupplierAds] = useState([])
  
    useEffect(() => {
        const fetchSuppliers = async () => { 
          try {
            const response = await axios.get(`${server}/products/get-products-supplier/${supplier._id}`);
            setSupplierProducts(response.data.products);
            
          } catch (error) {
            
          } 
        };
        fetchSuppliers();
      }, [supplier]);
      
      useEffect(() => {
        const fetchAds = async () => { 
          try {
            const response = await axios.get(`${server}/ads/get-ads-supplier/${supplier._id}`);
            setSupplierAds(response.data.ads); 
          } catch (error) {
            
          } 
        };
        fetchAds();
      }, [supplier]);
  return (
    <div className='flex'>
        <div className='w-[20%]'>
            <SupplierSidebar />
        </div>
        <main className='w-[80%]'>
        <SupplierHeadbar />
        <div className=''>
            <div className='w-full bg-orange-500 relative sm:h-[200px] h-[100px] mb-[50px]'>
                <div className='sm:w-[100px] w-[60px] h-[60px] sm:h-[100px] rounded-full  absolute sm:-bottom-[50px] -bottom-[25px] left-6 border-blue-600 bg-white border flex items-center justify-center'>
                    <img src={logo} alt="" className='p-1 w-[90%] h-[90%] rounded-full bg-slate-300' />
                </div>
            </div>
            <div className='px-5 py-2'>
                <div className='flex gap-2 items-center mb-5'>
                    <h1 className='text-lg font-medium'>{supplier.companyName}</h1>
                    {/* <a href="/">
                    <FaExternalLinkAlt color='blue' />
                    </a> */}
                </div>
                <div className='sm:grid sm:grid-cols-3'>
                    <a href='mailto:cotek@gmail.com' className='sm:border-r-2 border-b-2 flex gap-2 items-center p-3'>
                        <MdOutlineEmail size={25} />
                        <p className='text-[15px]'>
                            {supplier.email}
                        </p>
                    </a>
                    <div className='sm:border-r-2 border-b-2 flex gap-2 items-center p-3'>
                        <CiLocationOn size={25} />
                        <p className='text-[15px]'>
                            {supplier.address}
                        </p>
                    </div>
                    <div className='sm:border-r-2 border-b-2 flex gap-2 items-center p-3'>
                        <CiPhone size={25} />
                        <p className='text-[15px] mr-5'>
                            {supplier.phoneNumber}
                        </p>
                        <a href={`/supplier/edit-account/${supplier._id}`} className='bg-green-600 w-[80px] flex items-center justify-center border-none rounded-xl text-white gap-1 py-0.5'>
                            <FaRegEdit />
                            Edit
                        </a>
                    </div>
                </div>
            </div>
            <div className='sm:flex sm:gap-[50px] sm:px-[40px] px-[10px] mb-8'>
                <div className=' bg-slate-100 rounded-sm shadow-md sm:w-[40%] w-[100%] sm:mb-0 mb-4 py-[20px] px-5'>
                    <div className='border-b-2 py-2 border-dashed flex justify-between'>
                        <div className=''>
                            <h2 className='font-medium mb-1'>Registered Since</h2>
                            <p>24th, January 2024</p>
                        </div>
                        <div>
                            <h2 className='font-medium mb-1'>Status</h2>
                            <div className='flex gap-1 items-center'>
                                <div className='w-[10px] h-[10px] rounded-full bg-green-600'></div>
                                Active
                            </div>
                        </div>
                    </div>
                    <div>
                        <h1 className='text-xl font-semibold'>Owner Information</h1>
                        <div className='grid grid-cols-2'>
                            <div className='border-r-2 p-4'>
                                <h2 className='font-medium mb-1'>First Name</h2>
                                <p>{supplier.firstName}</p>
                            </div>
                            <div className='p-4'>
                                <h2 className='font-medium mb-1'>Last Name</h2>
                                <p>{supplier.lastName}</p>
                            </div>
                            <div className='p-4'>
                                <h2 className='font-medium mb-1'>Email Address</h2>
                                <p>{supplier.email}</p>
                            </div>
                        </div>
                        
                    </div>
                    <div className='w-[100%]'>
                        <a className="w-[100%] px-4 py-2 bg-orange-400 text-white" href={`/supplier/change-password/${supplier?._id}`}>Change Password</a>
                    </div>
                </div>
                <div className='sm:w-[58%] w-[100%] bg-slate-100 rounded-sm shadow-md p-5'>
                    <div className='sm:grid sm:grid-cols-2 gap-4'>
                        <div className='flex sm:mb-0 mb-3 border rounded-md shadow-sm bg-white justify-between p-4 items-center'>
                            <div>
                                <p className='text-xl font-semibold'>{supplierProducts.length}</p>
                                <h1>Total <a href="/supplier/products" className='text-blue-500 underline'>Products</a></h1>
                            </div>
                            <div className='w-[50px] h-[50px] bg-gray-300 rounded-full border border-blue-600 flex items-center justify-center'>
                                <FaCartFlatbed size={28} color='orange' />
                            </div>
                        </div>
                        <div className='flex sm:mb-0 mb-3 border rounded-md shadow-sm bg-white justify-between p-4 items-center'>
                            <div>
                                <p className='text-xl font-semibold'>{supplierAds.length}</p>
                                <h1>Total <a href="/supplier/ads" className='text-blue-500 underline'>Ads</a></h1>
                            </div>
                            <div className='w-[50px] h-[50px] bg-gray-300 rounded-full border border-green-600 flex items-center justify-center'>
                                <RiAdvertisementLine size={28} color='blue' />
                            </div>
                        </div>
                        <div className='flex border rounded-md shadow-sm bg-white justify-between p-4 items-center'>
                            <div>
                                <p className='text-xl font-semibold'>KES {supplier.dollarExchangeRate}</p>
                                <h1>Dollar Exchange Rate</h1>
                            </div>
                            <div className='w-[50px] h-[50px] bg-gray-300 rounded-full border border--600 flex items-center justify-center'>
                                <FaDollarSign size={28} color='green' />
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        </main>
  </div>
  )
}

export default SupplierAccountPage