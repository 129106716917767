import React, { useEffect, useState } from 'react'    
import { FaArrowAltCircleRight } from 'react-icons/fa'
import SupplierSidebar from './components/SupplierSidebar'
import SupplierHeadbar from './components/SupplierHeadbar'
import { useAuth } from '../../middleware/AuthContext'
import axios from 'axios'
import { toast } from 'react-toastify'
import { server } from '../../server'

const SupplierAddAd = () => {
    const [auth, setAuth] = useAuth();
    const supplier = auth.supplier;
    
    const [formData, setFormData] = useState({
        supplierId: supplier._id,
        title: '',
        description: '',
        productName: '',
        initialPrice: '',
        newPrice: '',
        startDate: '',
        endDate: '',
        isActive: true,
        status: 'featured',
      });

      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };

      const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          await axios.post(`${server}/ads/create-ad`, formData);
          // Optionally, you can reset the form after successful submission
          setFormData({
            supplierId: '',
            title: '',
            description: '',
            productName: '',
            initialPrice: '',
            newPrice: '',
            startDate: '',
            endDate: '',
            isActive: true,
            status: 'featured',
          });
          toast.success('Ad created successfully!');
        } catch (error) {
          toast.error('Error creating ad. Please try again.');
        }
      };

  return (
    <div className='flex'>
      <div className='w-[20%]'>
        <SupplierSidebar />
      </div>
      <main className='w-[80%]'>
        <SupplierHeadbar />
        <form className='sm:p-[40px] p-[20px] bg-[#f8f9fc]' onSubmit={handleSubmit}> 
            <div className='border-b-2 border-dashed py-[30px] flex items-center'>
                <span className='font-medium text-xl'>Create New Ad</span>
            </div>
            <div className='sm:flex sm:p-[30px] border-b-2 border-dashed'>
                <div className='sm:basis-[30%] basis-[100%] sm:mb-0 mb-4'>
                    <h2 className='font-semibold mb-4'>Information</h2>
                    <p className='text-gray-600'>Add ad information and create a new ad from here</p>
                </div>
                <div className='sm:basis-[70%] bg-white sm:p-10 p-4'>
                    <div className="mx-auto">
                        <div className="mb-5">
                            <label for="name" class="block mb-2 text-sm font-medium text-gray-900">Ad Title</label>
                            <input type="text" id="name" name='title' value={formData.title} onChange={handleChange} class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div className="mb-5">
                            <label for="price" class="block mb-2 text-sm font-medium text-gray-900">Ad Description</label>
                            <textarea name="description" value={formData.description} onChange={handleChange}  id="description" cols="30" rows="10" class="shadow-sm bg-gray-50 border outline-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"></textarea>
                        </div>
                        <div className="mb-5">
                            <label for="sku" class="block mb-2 text-sm font-medium text-gray-900">Product Name</label>
                            <input type="text" id="sku" name='productName' value={formData.productName} onChange={handleChange}  class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div className="mb-5">
                            <label for="sku" class="block mb-2 text-sm font-medium text-gray-900">Initial Price</label>
                            <input type="number" id="sku" name='initialPrice' value={formData.initialPrice} onChange={handleChange}  class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div className="mb-5">
                            <label for="sku" class="block mb-2 text-sm font-medium text-gray-900">New Price</label>
                            <input type="number" id="sku" name='newPrice' value={formData.newPrice} onChange={handleChange}  class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div className="mb-5">
                            <label for="sku" class="block mb-2 text-sm font-medium text-gray-900">Start Date</label>
                            <input type="date" id="sku" name='startDate' value={formData.startDate} onChange={handleChange}  class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div className='mb-5'>
                            <label for="description" class="block mb-2 text-sm font-medium text-gray-900">End Date</label>
                            <input type="date" id="sku" name='endDate' value={formData.endDate} onChange={handleChange}  class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div class="flex items-start mb-5">
                            <div class="flex items-center h-5">
                            <input id="terms" type="checkbox" name='isActive' checked={formData.isActive} onChange={() => setFormData({ ...formData, isActive: !formData.isActive })} class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300" required />
                            </div>
                            <label for="terms" className="ms-2 text-sm font-medium text-gray-900 mr-2">isActive?</label>    
                        </div>
                    </div> 
                </div>
            </div>
            
            <div className='w-full sm:flex justify-between items-center'>
                <a href='/supplier/ads' className='flex items-center gap-1 text-orange-600'>
                    <FaArrowAltCircleRight />
                    Go to ads
                </a>
                <div className='flex gap-2'>
                    <a href="/suppliers/ads" className="border border-orange-600 hover:bg-orange-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Cancel</a>
                    <button type="submit" className="text-white bg-orange-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Create Ad</button>
                </div>
            </div>
        </form>
        </main>
    </div>
  )
}

export default SupplierAddAd