import React, { useEffect, useState } from 'react'
import MainNav from '../components/MainNav'
import SupplierCard from '../components/SupplierCard'
import Footer from '../components/Footer'
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa6'
import PlayImg from '../assets/play.png'
import AppImg from '../assets/appstore.png'
import axios from 'axios'
import { server } from '../server'
import BreadCrumbs from '../components/BreadCrumbs'

const SuppliersPage = () => {
  const [suppliers, setSuppliers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filterType, setFilterType] = useState('All');
  const [filterCategory, setFilterCategory] = useState('All');
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchSuppliers = async () => {
      try {
        const response = await axios.get(`${server}/suppliers/approved-suppliers`);
        setSuppliers(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${server}/categories`);
        setCategories(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchSuppliers();
    fetchCategories();
  }, []);

  // Filter suppliers based on selected filter options
  const filteredSuppliers = suppliers.filter(supplier => {
    const typeMatch = filterType === 'All' || supplier.companyType === filterType;
    const categoryMatch = filterCategory === 'All' || supplier.categories.includes(filterCategory);
    const searchTermMatch = supplier.companyName.toLowerCase().includes(searchTerm.toLowerCase());
    return typeMatch && categoryMatch && searchTermMatch;
  });

  const breadcrumbs = [{ name: 'Home', path: '/home' }, { name: 'Suppliers', path: '/suppliers' }]; // Define breadcrumbs


  return (
    <div>
      <MainNav />
      <div className='sm:h-[150px] h-[100px] top-section mb-3'>
        <div className='top-overlay px-16' style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "end" }}>
          <h1 className='sm:text-6xl text-3xl text-center text-orange-600 font-bold mb-5 counter-text'>Suppliers</h1>
        </div>
      </div>
      <div>
        <BreadCrumbs items={breadcrumbs} />
      </div>
      <div>
        
        <div className=''>
          <div className='sm:grid sm:grid-cols-2 sm:w-full px-[20px] py-[10px] gap-3 bg-[#fff] mb-4'>
            <div>
              <h2 className='text-gray-400 mb-2'>Filter by type</h2>
              <select
                value={filterType}
                onChange={e => setFilterType(e.target.value)}
                className='rounded-md w-full h-[50px] border outline-none border-gray-400'
              >
                <option value="All">All</option>
                <option value="supplier">Supplier</option>
                <option value="manufacturer">Manufacturer</option>
              </select>
            </div>
            <div>
              <h2 className='text-gray-400 mb-2'>Filter by product categories</h2>
              <select
                value={filterCategory}
                onChange={e => setFilterCategory(e.target.value)}
                className='rounded-md w-full h-[50px] border outline-none border-gray-400'
              >
                <option value="All">All</option>
                {categories.map(category => (
                  <option key={category._id} value={category.name}>{category.name}</option>
                ))}
              </select>
            </div>
          </div>
          <div className='flex items-center justify-center mb-6'>
            <form class="sm:w-[600px] w-[300px] sm:mx-0 mx-auto h-[40px]">
              <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only">Search</label>
              <div class="relative">
                <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                  <svg class="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                  </svg>
                </div>
                <input
                  type="search"
                  id="default-search"
                  value={searchTerm}
                  onChange={e => setSearchTerm(e.target.value)}
                  class="block sm:w-[600px] w-[300px] h-[40px] p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-orange-500 focus:border-orange-500 outline-none"
                  placeholder="Search Here..."
                  required
                />
                {/* <button type="submit" class="text-white absolute end-1 bottom-0.5 bg-orange-700 hover:bg-orange-800 focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium rounded-lg text-sm px-4 py-2">Search</button> */}
              </div>
            </form>
          </div>
        </div>
        <div className='flex flex-wrap gap-3 items-center justify-center p-5'>
          {filteredSuppliers.map(supplier => (
            <SupplierCard key={supplier._id} supplier={supplier} />
          ))}
        </div>
        <Footer />
        <div className='w-[100%] bg-orange-400 p-10'>
          <div className='w-full'>
            <h2 className='text-white text-2xl text-center font-bold mb-2'>Access our services from your mobile device.</h2>
            <p className='text-white text-center text-xl font-semibold mb-5'>Mobile App Available</p>
            <div className='flex w-full items-center justify-center gap-4'>
              <a href="https://play.google.com/store/apps/details?id=com.codemunity.resellersprint">
                <img src={PlayImg} alt="" />
              </a>
              <a href="/">
                <img src={AppImg} alt="" />
              </a>
            </div>
          </div>
        </div>
        <div className='w-[100%] px-10 text-black flex items-center justify-between'>
          <p className='text-sm'> <span>&copy;</span> Reseller Sprint. All rights reserved.</p>
          <div className='flex gap-2 items-center'>
            <a href="/privacy" className='underline text-sm text-orange-600'>Terms & conditions</a>
            <a href="/privacy" className='underline text-sm text-orange-600'>Privacy Policy</a>
          </div>
          <div className='flex items-center gap-3'>
            <FaFacebook size={18} color='#4267B2' />
            <FaInstagram size={18} color='#cd486b' />
            <FaTwitter size={18} color='#1DA1F2' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SuppliersPage;
