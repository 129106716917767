import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { server } from '../../server';
import { toast } from 'react-toastify';
import AdminSidebar from './components/AdminSidebar';
import AdminHeadbar from './components/AdminHeadbar';

const EditTerm = () => {
    const {id} = useParams();
    const [term, setTerm] = useState(
        {   
            name: '',
            description: '',
            status: true,    
        }
    )

    const handleChange = (e) => {
        const { name, value } = e.target;
        setTerm(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Send updated data to the server
            await axios.patch(`${server}/terms/update-term/${id}`, term);
            // Optionally, you can fetch the updated data again to reflect changes in the UI
            toast.success('Data updated successfully!');
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        } catch (error) {
            toast.error({error});
        }
    };

    useEffect(() => {
        const fetchSuppliers = async () => { 
          try {
            const response = await axios.get(`${server}/terms/get-term/${id}`);
            setTerm(response.data.term);
            
          } catch (error) {
            
          } 
        };
        fetchSuppliers();
      }, [id]);
    
  return (
    <div className='flex'>
    <div className='w-[20%]'>
      <AdminSidebar />
    </div>
    <main className='w-[80%]'>
      <AdminHeadbar />
      <div className='p-[40px] bg-[#f8f9fc]'>
            <div className='border-b-2 border-dashed py-[30px] flex items-center'>
                <span className='font-medium text-xl'>Edit Term & Condition</span>
            </div>
            <div className='flex p-[30px]'>
                <div className='basis-[40%]'>
                    <h2 className='font-semibold mb-4'>Information</h2>
                    <p className='text-gray-600'>Edit term & condition title & desc</p>
                </div>
                <div className='basis-[60%] bg-white p-6'>
                    <form onSubmit={handleSubmit} class="max-w-sm mx-auto">
                        <div class="mb-5">
                            <label for="name" class="block mb-2 text-sm font-medium text-gray-900">Title</label>
                            <input type="name" id="name" name='name' value={term.name} onChange={handleChange} class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div class="mb-5">
                            <label for="name" class="block mb-2 text-sm font-medium text-gray-900">Description</label>
                            <textarea name="description" id="" cols="30" rows="10" value={term.description} onChange={handleChange}  class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"></textarea>
                        </div>
                        
                        <div class="flex items-start mb-5">
                            <div class="flex items-center h-5">
                            <input id="terms" type="checkbox" checked={term.status} onChange={() => setTerm({ ...term, status: !term.status })} class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300" required />
                            </div>
                            <label for="terms" class="ms-2 text-sm font-medium text-gray-900">isActive?</label>
                        </div>
                        <button type="submit" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Edit T&C</button>
                    </form>
                </div>
            </div>
        </div>
      </main>
  </div>
  )
}

export default EditTerm