import React, { useEffect, useState } from 'react'
import Footer from '../components/Footer'
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa6'
import MainNav from '../components/MainNav'
import AccordionItem from '../components/AccordionItem'
import axios from 'axios'
import { server } from '../server'

const data = [
    {
     question: 'What are accordion components?',
     answer: 'Accordion components are user interface elements used for organizing and presenting content in a collapsible manner. They typically consist of a header, content, and an expand/collapse action.' ,
    },
    {
     question: 'What are they used for?',
     answer: 'They are commonly employed in various contexts, including FAQs, product descriptions, navigation menus, settings panels, and data tables, to save screen space and provide a structured and user-friendly interface for presenting information or options.',
    },
    {
     question: 'Accordion as a musical instrument',
     answer: 'The accordion is a musical instrument with a keyboard and bellows. It produces sound by air passing over reeds when the player expands or compresses the bellows, used in various music genres.',
    },
    {
     question: 'Can I create an accordion component with a different framework?',
     answer: 'Yes of course, it is very possible to create an accordion component with another framework.',
    }
   ];

const FAQPage = () => {
    const [activeIndex, setActiveIndex] = useState(null);
    const [faqData, setFaqData] = useState([]); // State to store FAQ data

    useEffect(() => {
        // Function to fetch FAQ data when the component mounts
        const fetchFAQData = async () => {
            try {
               
                const response = await axios.get(`${server}/faq`); 
                setFaqData(response.data); 
            } catch (error) {
                console.error('Error fetching FAQ data:', error);
            }
        };

        fetchFAQData(); // Call the fetchFAQData function when the component mounts
    }, []); //

    const handleItemClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };
  return (
    <div>
      <MainNav />  
      <div className='sm:h-[150px] h-[100px] top-section bg-red-500 mb-5'>
        <div className='top-overlay px-16' style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "end"}}>
          <h1 className='sm:text-6xl text-4xl text-center text-orange-600 font-bold mb-5 counter-text'>Frequently Asked Questions</h1>
        </div>
      </div>
      <div> 
        <div className='px-[40px] mb-5'>
        <div className='container'>
          {faqData.map((item, index) => (
              <AccordionItem
                  key={index}
                  question={item.title}
                  answer={item.description}
                  isOpen={activeIndex === index}
                  onClick={() => handleItemClick(index)}
              />
          ))}
        </div>
        </div>
        <Footer />
        <div className='sm:w-[100%] hidden px-10 text-black sm:flex sm:items-center sm:justify-between'>
          <p className='text-sm'> <span>&copy;</span> Reseller Sprint. All rights reserved.</p>
          <div className='flex gap-2 items-center'>
            <a href="/privacy" className='underline text-sm text-orange-600'>Terms & conditions</a>
            <a href="/privacy" className='underline text-sm text-orange-600'>Privacy Policy</a>
          </div>
          <div className='flex items-center gap-3'>
            <FaFacebook size={18} color='#4267B2'/>
            <FaInstagram size={18} color='#cd486b' />
            <FaTwitter size={18} color='#1DA1F2' />
            </div>
        </div>
      </div>
    </div>
  )
}

export default FAQPage