import React, { useEffect, useState } from 'react'
import Footer from '../../components/Footer'
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa6'
import MainNav from '../../components/MainNav'
// import Select from 'react-tailwindcss-select'
import { toast } from 'react-toastify'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { server } from '../../server'
import {Multiselect} from 'multiselect-react-dropdown'

const SupplierSignUp = () => {
    const [categories, setCategories] = useState([]);
    const [categoriesData, setCategoriesData] = useState([]);
    const [companyType, setCompanyType] = useState('');
    const navigate = useNavigate()  
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        companyName: '',
        companyEmail: '',
        country: '',
        address: '',
        password: '',
        dollarExchangeRate: '',
    });

   

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get(`${server}/categories`);
                setCategoriesData(response.data);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        fetchCategories();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataCopy = { ...formData };
        formDataCopy.categories = categories
        formDataCopy.companyType = companyType;

        try {
            // Make API call to submit form data
            await axios.post(`${server}/suppliers/register-supplier`, formDataCopy);
            setFormData({
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
                companyName: '',
                companyEmail: '',
                country: '',
                address: '',
                password: '',
                dollarExchangeRate: '',
                companyType: 'supplier',
                categories: [],
            })
            toast.success('Registration Successful. Kindly check your email for further instructions!');
            setTimeout(() => {
                navigate('/supplier-login');
              }, 2000);
        } catch (error) {
            toast.error({error: error.message});
        }
    };

    const options = categoriesData.map(categoryData => ({
        value: categoryData.name,
        label: categoryData.name
    }));

    const handleChange = (selectedOptions) => {
        const categoryNames = selectedOptions.map(selectedOption => selectedOption.value);  
        setCategories(categoryNames);
    };

    const handleCompanyTypeChange = (e) => {
        const { value } = e.target;
        setCompanyType(value);
    };

  return (
    <div>
        <MainNav />
      <div className='sm:h-[150px] h-[100px] top-section bg-red-500 mb-5'>
        <div className='top-overlay px-16' style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
          <h1 className='sm:text-6xl text-3xl text-center text-orange-600 font-bold mb-5 counter-text'>Supplier Application</h1>
        </div>
      </div>
      <div>
        <form className='m-[40px] gap-4 flex bg-white shadow-md p-[30px]' onSubmit={handleSubmit}>  
            <div className='w-[100%]'>
                <div className='mb-3'>
                    <h1 className='text-xl font-medium'>Personal Information</h1>
                    <p className='sm:text-lg hidden'>Fill the form below or write us. We will help you create your account</p>
                </div>
                <div className='border-b-2 pb-6'>
                    <div className='sm:flex sm:flex-wrap sm:gap-2 w-full sm:items-center sm:justify-center'>
                        <div className='sm:w-[48%] w-full mb-4 sm:mb-0'>
                            <label className='mb-5'>First Name*</label>
                            <input type="text" name='firstName' value={formData.firstName} onChange={handleInputChange} placeholder=' First Name of registering person' class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div className='sm:w-[48%] w-full mb-4 sm:mb-0'>
                            <label className='mb-5'>Last Name*</label>
                            <input type="text" name='lastName' value={formData.lastName} onChange={handleInputChange} placeholder='Last Name of registering person' class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div className='sm:w-[48%] w-full mb-4 sm:mb-0'>
                            <label className='mb-5'>Email Address*</label>
                            <input type="email" name='email' value={formData.email} onChange={handleInputChange} placeholder='Email Address of registering person' class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div className='sm:w-[48%] w-full mb-4 sm:mb-0'>
                            <label className='mb-5'>Phone Number*</label>
                            <input type="tel" name='phoneNumber' value={formData.phoneNumber} onChange={handleInputChange} placeholder='Phone Number of registering person eg +2547..' class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                    </div>
                </div>
                <div className='mb-3'>
                    <h1 className='text-xl font-medium'>Company Information</h1>
                    <p className='sm:text-lg hidden'>Fill the form below or write us. We will help you create your account</p>
                </div>
                <div className=''>
                    <div className='sm:flex sm:flex-wrap sm:gap-2 w-full sm:items-center sm:justify-center'>
                        <div className='sm:w-[48%] w-full mb-4 sm:mb-0'>
                            <label className='mb-5'>Company Name*</label>
                            <input type="text" name='companyName' value={formData.companyName} onChange={handleInputChange} placeholder='Enter Company Name' class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div className='sm:w-[48%] w-full mb-4 sm:mb-0'>
                            <label className='mb-5'>Company Type*</label>
                            <select name="companyType" id="" value={companyType} onChange={handleCompanyTypeChange}  class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                <option value="" disabled>Company Type</option>
                                <option value="supplier">Supplier</option>
                                <option value="manufacturer">Manufacturer</option>
                            </select>
                        </div>
                        <div className='sm:w-[48%] w-full mb-4 sm:mb-0'>
                            <label className='mb-5'>Company Email Address*</label>
                            <input type="email" name='companyEmail' value={formData.companyEmail} onChange={handleInputChange} placeholder='Enter company email address' class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div className='sm:w-[48%] w-full mb-4 sm:mb-0'>
                            <label className='mb-5'>Country*</label>
                            <input type="text" name='country' value={formData.country} onChange={handleInputChange} placeholder='Country' class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div className='sm:w-[48%] w-full mb-4 sm:mb-0'>
                            <label className='mb-5'>Address*</label>
                            <input type="text" name='address' value={formData.address} onChange={handleInputChange} placeholder='Boulevard St. AMbank Building' class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div className='sm:w-[48%] w-full mb-4 sm:mb-0'>
                            <label className='mb-5'>Dollar Exchange Rate*</label>
                            <input type="number" name='dollarExchangeRate' value={formData.dollarExchangeRate} onChange={handleInputChange} placeholder='Your current dollar exchange rate' class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div className='sm:w-[97%] w-full mb-4 sm:mb-0'>
                            <label className='mb-5'>Categories*</label>
                             <Multiselect name="categories" options={options} value={categories} displayValue="label" onSelect={handleChange} />
                        </div>
                        <div className='sm:w-[48%] w-full mb-4 sm:mb-0'>
                            <label className='mb-3'>Password*</label>
                            <input type="password" name="password" value={formData.password} onChange={handleInputChange} placeholder='Must be more than 6 characters' class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div class="ml-3 text-sm">
                                <label for="terms" class="font-light text-gray-500">I accept the <a class="font-medium text-primary-600 hover:underline" href="/">Terms and Conditions</a></label>
                        </div>
                        <div className='w-full' style={{display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center"}}>
                            <button type='submit' className='bg-orange-600 text-white p-2 mb-3 rounded-md'>Create Supplier Account</button>
                            <p>Already have an Account? <a href="/supplier-login" className='text-blue-500 underline'>Log In</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <Footer />
        <div className='w-[100%] px-10 text-black hidden sm:flex items-center justify-between'>
          <p className='text-sm'> <span>&copy;</span> Reseller Sprint. All rights reserved.</p>
          <div className='flex gap-2 items-center'>
            <a href="/privacy" className='underline text-sm text-orange-600'>Terms & conditions</a>
            <a href="/privacy" className='underline text-sm text-orange-600'>Privacy Policy</a>
          </div>
          <div className='flex items-center gap-3'>
            <FaFacebook size={18} color='#4267B2'/>
            <FaInstagram size={18} color='#cd486b' />
            <FaTwitter size={18} color='#1DA1F2' />
            </div>
        </div>
      </div>
    </div>
  )
}

export default SupplierSignUp