import React, { useEffect, useState } from 'react'
import { FaArrowAltCircleRight } from 'react-icons/fa'
import { useNavigate, useParams } from 'react-router-dom'
import { useAuth } from '../../middleware/AuthContext'
import { toast } from 'react-toastify'
import axios from 'axios'
import { server } from '../../server'
import AdminSidebar from './components/AdminSidebar'
import AdminHeadbar from './components/AdminHeadbar'
import Select from 'react-tailwindcss-select'

const EditReseller = () => {
    const {id} = useParams()
    const [reseller, setReseller] = useState(
        {   
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            companyName: '',
            country: '',
            address: '',
            password: '',
        }
    )

    const handleChange = (e) => {
        const { name, value } = e.target;
        setReseller(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Send updated data to the server
            await axios.put(`${server}/resellers/update-reseller/${id}`, reseller);
            // Optionally, you can fetch the updated data again to reflect changes in the UI
            toast.success('Data updated successfully!');
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        } catch (error) {
            console.error('Error updating supplier:', error);
            toast.error({error});
        }
    };

    useEffect(() => {
        const fetchResellers = async () => { 
          try {
            const response = await axios.get(`${server}/resellers/get-reseller/${id}`);
            setReseller(response.data.reseller);
            
          } catch (error) {
            
          } 
        };
        fetchResellers();
      }, [id]);
  return (
    <div className='flex'>
      <div className='w-[20%]'>
        <AdminSidebar />
      </div>
      <main className='w-[80%]'>
        <AdminHeadbar />
        <form className='p-[40px] bg-[#f8f9fc]' onSubmit={handleSubmit}> 
            <div className='border-b-2 border-dashed py-[30px] flex items-center'>
                <span className='font-medium text-xl'>Update Reseller</span>
            </div>
            <div className='flex p-[30px] border-b-2 border-dashed'>
                <div className='basis-[30%]'>
                    <h2 className='font-semibold mb-4'>Information</h2>
                    <p className='text-gray-600'>Edit personal information</p>
                </div>
                <div className='basis-[70%] bg-white p-10'>
                    <div className=" mx-auto">
                        <div className="mb-5">
                            <label for="name" class="block mb-2 text-sm font-medium text-gray-900">Reseller First Name</label>
                            <input type="text" id="name" name='firstName' value={reseller.firstName} onChange={handleChange} class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div className="mb-5">
                            <label for="price" class="block mb-2 text-sm font-medium text-gray-900">Reseller Last Name</label>
                            <input type="text" id="price" name='lastName' value={reseller.lastName} onChange={handleChange}  class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="" required />
                        </div>
                        <div className="mb-5">
                            <label for="sku" class="block mb-2 text-sm font-medium text-gray-900">Email Address</label>
                            <input type="email" id="sku" name='email' value={reseller.email} onChange={handleChange}  class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div className='mb-5'>
                            <label for="phoneNumber" class="block mb-2 text-sm font-medium text-gray-900">Phone Number</label>
                            <input type="tel" id="sku" name='phoneNumber' value={reseller.phoneNumber} onChange={handleChange}  class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />                        </div>
                    </div> 
                </div>
            </div>
            <div className='flex p-[30px]'>
                <div className='basis-[30%]'>
                    <h2 className='font-semibold mb-4'>Company Information</h2>
                    <p className='text-gray-600'>Fill the form below or write us. We will help you create your account</p>
                </div>
                <div className='basis-[70%] bg-white p-10'>
                    <div className=" mx-auto">
                        <div className="mb-5">
                            <label for="warranty" class="block mb-2 text-sm font-medium text-gray-900">Company Name</label>
                            <input type="text" name="companyName" value={reseller.companyName} onChange={handleChange} placeholder='company name' id="warranty" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div className="mb-5">
                            <label for="warranty" class="block mb-2 text-sm font-medium text-gray-900">Country</label>
                            <input type="text" name="country" value={reseller.country} onChange={handleChange} placeholder='Kenya' id="warranty" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div className="mb-5">
                            <label for="warranty" class="block mb-2 text-sm font-medium text-gray-900">Address</label>
                            <input type="text" name="address" value={reseller.address} onChange={handleChange} placeholder='St Boulevard' id="warranty" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                    </div> 
                </div>
            </div>
            <div className='w-full flex justify-between items-center'>
                <a href='/admin/resellers' className='flex items-center gap-1 text-orange-600'>
                    <FaArrowAltCircleRight />
                    Go back
                </a>
                <div className='flex gap-2'>
                    <a href='/admin/resellers' class="border border-orange-600 hover:text-white hover:bg-orange-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Cancel</a>
                    <button type="submit" class="text-white bg-orange-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Edit Reseller</button>
                </div>
            </div>
        </form>
        </main>
    </div>
  )
}

export default EditReseller