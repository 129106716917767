import React, { useEffect, useState } from 'react'
import { FaRegEdit } from 'react-icons/fa'
import { FaPlus, FaTrash } from 'react-icons/fa6'
import AdminSidebar from './components/AdminSidebar'
import AdminHeadbar from './components/AdminHeadbar'
import profile from '../../assets/GooglePlayStore.png'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { server } from '../../server'
import { toast } from 'react-toastify'

const Terms = () => {
    const [terms, setTerms] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedTerm, setSelectedTerm] = useState(null)
    const [modalOpen, setModalOpen] = useState(false)

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get(`${server}/terms`);
            setTerms(response.data);
        } catch (error) {
            console.error('Error fetching T&C data:', error);
        }
    };

    const filteredTerms = terms.filter((term) =>
        term.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const toggleModal = (term) => {
        setModalOpen(!modalOpen);
        setSelectedTerm(term)
    };

    const handleDelete = async (e) => {
        try {
            await axios.delete(`${server}/terms/delete-term/${selectedTerm._id}`);

            toast.success('Data deleted successfully!');
            window.location.reload();
        } catch (error) {
            toast.error({ error });
        }
    };

    return (
        <div className='flex'>
            <div className='w-[20%]'>
                <AdminSidebar />
            </div>
            <main className='w-[80%]'>
                <AdminHeadbar />
                <div className='m-[30px] bg-[#f8f9fc]'>
                    <div className='bg-[#fff] flex justify-between p-[20px] items-center mb-10'>
                        <div className='border-l-4 border-orange-500 rounded-lg p-4'>
                            <span className='text-2xl font-semibold'>Terms & Conditions</span>
                        </div>
                        <div className='flex justify-between gap-[75px] w-[700px]'>
                            <form className="w-full">
                                <label for="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only">Search</label>
                                <div className="relative">
                                    <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                        <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                        </svg>
                                    </div>
                                    <input type="search" id="default-search" value={searchQuery} onChange={handleSearchChange} className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-orange-500 focus:border-orange-500" placeholder="Search by name..." required />
                                    <button type="submit" className="text-white absolute end-2.5 bottom-2.5 bg-orange-700 hover:bg-orange-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2">Search</button>
                                </div>
                            </form>
                            <Link to='../add-term' className='flex items-center justify-center bg-orange-600 gap-4 rounded-xl w-[200px] text-white'>
                                <FaPlus />
                                Add Terms
                            </Link>
                        </div>
                    </div>
                    <div className='bg-[#fff] h-[70vh]'>
                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg overflow-y-auto">

                            <table className="w-full text-sm text-left rtl:text-right text-gray-500 overflow-y-auto">
                                <thead className="text-xs text-gray-700 uppercase bg-[#fff]">
                                    <tr>
                                        <th scope="col" className="p-4">
                                            <div className="flex items-center">
                                                <input id="checkbox-all-search" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                <label for="checkbox-all-search" className="sr-only">checkbox</label>
                                            </div>
                                        </th>
                                        <th scope="col" className="px-4 py-3">
                                            Name
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Description
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Status
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Issued By
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {filteredTerms.length === 0 ? (
                                        <tr>
                                            <td colSpan="6" className="px-6 py-4 text-center text-gray-500">
                                                No  T&Cs found.
                                            </td>
                                        </tr>
                                    ) : (
                                        filteredTerms && filteredTerms.map((term, index) => (
                                            <tr className="bg-white border-b hover:bg-gray-50" key={index}>
                                                <td className="w-4 p-4">
                                                    <div className="flex items-center">
                                                        <input id="checkbox-table-search-1" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                        <label for="checkbox-table-search-1" className="sr-only">checkbox</label>
                                                    </div>
                                                </td>
                                                <th scope="row" className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap">
                                                    <img className="w-10 h-10 rounded-full" src={profile} alt="Jese images" />
                                                    <div className="ps-3">
                                                        <div className="text-sm font-semibold">{term.name}</div>
                                                        <div className="font-normal text-gray-500"></div>
                                                    </div>
                                                </th>
                                                <td className="px-6 py-4">
                                                    <div className='flex'>
                                                        <p>{term.description}</p>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4">
                                                    <div className="flex items-center">
                                                        <span
                                                            className={`bg-${term.status ? 'green' : 'red'}-100 text-${term.status ? 'green' : 'red'}-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded border border-${term.status ? 'green' : 'red'}-400`}
                                                        >
                                                            {term.status ? 'Active' : 'Inactive'}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4">
                                                    <div className="flex items-center">
                                                        <p>Super_Admin</p>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4">
                                                    <div className='flex gap-2'>
                                                        <a href={`/admin/edit-term/${term._id}`} data-twe-toggle='tooltip' title='Edit Term'>
                                                            <FaRegEdit color='blue' size={20} />
                                                        </a>
                                                        <button onClick={() => toggleModal(term)} data-twe-toggle='tooltip' title='Delete Term'>
                                                            <FaTrash size={20} color='grey' />
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    )}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </main>
            {modalOpen && (
                <div id="popup-modal" tabIndex="-1" className="fixed top-[100px] left-[40%] z-50 justify-center items-center w-full">
                    <div className="relative p-4 w-full max-w-md max-h-full">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <button type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={toggleModal}>
                                <svg className="w-3 h-3" onClick={toggleModal} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                            <div className="p-4 md:p-5 text-center">
                                <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                </svg>
                                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to delete this term?</h3>
                                <button data-modal-hide="popup-modal" type="button" onClick={handleDelete} className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                                    Yes, I'm sure
                                </button>
                                <button data-modal-hide="popup-modal" onClick={toggleModal} type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">No, cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Terms