import React, { useState } from 'react'
import Footer from '../../components/Footer'
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa6'
import MainNav from '../../components/MainNav';
import axios from 'axios';
import { toast } from 'react-toastify';
import { server } from '../../server';

const SignUp = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        country: '',
        address: '',
        companyName: '',
        phoneNumber: '',
        password: '',
    });

    

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {

            await axios.post(`${server}/resellers/signup`, formData);
            setFormData({
                firstName: '',
                lastName: '',
                email: '',
                country: '',
                address: '',
                companyName: '',
                phoneNumber: '',
                password: '',
            })
            // Show success message or redirect to another page
            toast.success('Registration Successful. Kindly check your email for further instructions!');
        } catch (error) {
            // Handle error
            toast.error({error: error.message});
        }
    };

  return (
    <div>
      <MainNav />
      <div className='sm:h-[150px] h-[100px] top-section bg-red-500 mb-5'>
        <div className='top-overlay px-16' style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
          <h1 className='sm:text-6xl text-3xl text-center text-orange-600 font-bold mb-5 counter-text'>Reseller Application</h1>
        </div>
      </div>
      <div>
        
        <form className='m-[40px] gap-4 flex bg-white shadow-md p-[30px]' onSubmit={handleSubmit}>  
            <div className='w-[100%]'>
                <div className='mb-3'>
                    <h1 className='text-xl font-medium'>Personal Information</h1>
                    <p className='sm:text-lg sm:block hidden'>Fill the form below or contact us. We will help you create your account</p>
                </div>
                <div className='border-b-2 mb-4'>
                    <div className='sm:flex sm:flex-wrap sm:gap-2 w-full sm:items-center sm:justify-center mb-4'>
                        <div className='sm:w-[48%] w-full mb-4 sm:mb-0'>
                            <label className='mb-3'>First Name*</label>
                            <input type="text" name='firstName' value={formData.firstName} onChange={handleInputChange} placeholder=' First Name of registering person' class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div className='sm:w-[48%] w-full mb-4 sm:mb-0'>
                            <label className='mb-3'>Last Name*</label>
                            <input type="text" name='lastName' value={formData.lastName} onChange={handleInputChange} placeholder='Last Name of registering person' class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div className='sm:w-[48%] w-full mb-4 sm:mb-0'>
                            <label className='mb-3'>Email Address*</label>
                            <input type="email" name='email' value={formData.email} onChange={handleInputChange} placeholder='Email Address of registering person' class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div className='sm:w-[48%] w-full mb-4 sm:mb-0'>
                            <label className='mb-3'>Phone Number*</label>
                            <input type="tel" name='phoneNumber' value={formData.phoneNumber} onChange={handleInputChange} placeholder='Phone Number of registering person eg +2547..' class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                    </div>
                </div>
                <div className='mb-3'>
                    <h1 className='text-xl font-medium'>Company Information</h1>
                    <p className='sm:text-lg sm:block hidden'>Fill the form below or write us. We will help you create your account</p>
                </div>
                <div className=''>
                    <div className='sm:flex sm:flex-wrap sm:gap-2 w-full sm:items-center sm:justify-center'>
                        <div className='sm:w-[48%] w-full mb-4 sm:mb-0'>
                            <label className='mb-3'>Company Name*</label>
                            <input type="text" name='companyName' value={formData.companyName} onChange={handleInputChange} placeholder='Enter Company Name' class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div className='sm:w-[48%] w-full mb-4 sm:mb-0'>
                            <label className='mb-3'>Country*</label>
                            <input type="text" name='country' value={formData.country} onChange={handleInputChange} placeholder='Country' class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div className='sm:w-[48%] w-full mb-4 sm:mb-0'>
                            <label className='mb-3'>Address*</label>
                            <input type="text" name='address' value={formData.address} onChange={handleInputChange} placeholder='Boulevard St. AMbank Building' class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div className='sm:w-[48%] w-full mb-4 sm:mb-0'>
                            <label className='mb-3'>Password*</label>
                            <input type="password" name='password'value={formData.password} onChange={handleInputChange} placeholder='Must be more than 6 characters' class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div className='w-full' style={{display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center"}}>
                            <button type='submit' className='bg-orange-600 rounded-md mb-3 text-white p-2'>Create Reseller Account</button>
                            <p>Already have an Account? <a href="/login" className='text-blue-500 underline'>Log In</a></p>
                        </div>
                    </div>
                </div>
            </div>
            
        </form>
        <Footer />
        <div className='w-[100%] px-10 text-black hidden sm:flex items-center justify-between'>
          <p className='text-sm'> <span>&copy;</span> Reseller Sprint. All rights reserved.</p>
          <div className='flex gap-2 items-center'>
            <a href="/privacy" className='underline text-sm text-orange-600'>Terms & conditions</a>
            <a href="/privacy" className='underline text-sm text-orange-600'>Privacy Policy</a>
          </div>
          <div className='flex items-center gap-3'>
            <FaFacebook size={18} color='#4267B2'/>
            <FaInstagram size={18} color='#cd486b' />
            <FaTwitter size={18} color='#1DA1F2' />
            </div>
        </div>
      </div>
    </div>
  )
}

export default SignUp