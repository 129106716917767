import React, { useEffect, useState } from 'react'
import { FaArrowCircleRight } from 'react-icons/fa'
import { MdOutlineProductionQuantityLimits } from "react-icons/md";
import { FaDollarSign } from "react-icons/fa6";
import SupplierSidebar from './components/SupplierSidebar';
import SupplierHeadbar from './components/SupplierHeadbar';
import { useAuth } from '../../middleware/AuthContext';
import { RiAdvertisementLine } from 'react-icons/ri';
import { CiCircleQuestion } from 'react-icons/ci';
import axios from 'axios';
import { server } from '../../server';
import { toast } from 'react-toastify';

const SupplierDashboard = () => {
    const [date, setDate] = useState(new Date());
    const [auth, setAuth] = useAuth();
    const [supplierProducts, setSupplierProducts] = useState([])
    const [supplier, setSupplier] = useState('')
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [dollarExchangeRate, setExchangeRate] = useState('');
    const [supplierAds, setSupplierAds] = useState([])
  
    useEffect(() => {
        const fetchSuppliers = async () => { 
          try {
            const response = await axios.get(`${server}/products/get-products-supplier/${supplier._id}`);
            setSupplierProducts(response.data.products);
          } catch (error) {
            
          } 
        };
        fetchSuppliers();
      }, [supplier]);

      useEffect(() => {
        const fetchSupplier = async () => { 
          try {
            const response = await axios.get(`${server}/suppliers/get-supplier/${auth?.supplier?._id}`);
            setSupplier(response.data.supplier);
          } catch (error) {
            
          } 
        };
        fetchSupplier();
      }, []);
      useEffect(() => {
        const fetchAds = async () => { 
          try {
            const response = await axios.get(`${server}/ads/get-ads-supplier/${supplier._id}`);
            setSupplierAds(response.data.ads); 
          } catch (error) {
            
          } 
        };
        fetchAds();
      }, [supplier]);

      const handleSubmit = async (event) => {
        event.preventDefault();
        
        try {
            // Send request to update the exchange rate
            const response = await axios.patch(`${server}/suppliers/update-exchange/${supplier._id}`, { dollarExchangeRate });
            setExchangeRate(response.data.dollarExchangeRate);
            console.log(response.data.dollarExchangeRate)
            toast.success('Exchange rate updated.');

            setIsModalOpen(!isModalOpen);
            window.location.reload();
             // Reload the page
        } catch (error) {
            toast.error('Failed to update exchange rate');
        } 
    };

  
      const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

  return (
    
    <div className='flex'>
      <div className='w-[20%]'>
        <SupplierSidebar />
      </div>
      <main className='w-[80%]'>
        <SupplierHeadbar />
        <div className='flex justify-between px-10 py-8 items-center'>
          <div>
          <h2 className='font-bold text-xl'>Welcome back, {supplier?.firstName}</h2>
          <p className='text-gray-400 sm:block hidden'>Your current status and analytics are here</p>
          </div>
          <p className='rounded-xl p-2 border sm:block hidden border-orange-500'>{date.toDateString()}</p>
        </div>
        <div className='w-full sm:px-10 px-4 flex'>
          <div className='w-[100%] sm:flex gap-4'>
            <div className='shadow bg-white sm:w-[32%] sm:mb-0 mb-4 w-[100%] h-[150px] p-4 flex justify-between items-center'>
              <div className='w-[70%]'>
                <h2 className='text-orange-600 mb-3 text-2xl counter-text '>Dollar Exchange Rate</h2>
                <p className='text-2xl mb-3 font-bold'>KES {supplier.dollarExchangeRate}</p>
                <button onClick={toggleModal} className='text-gray-400 underline'>Edit Rate</button>
              </div>
              <div className='w-[30%]'>
                <FaDollarSign size={35} color='green' />
              </div>
            </div>
            <div className='shadow bg-white sm:w-[32%] sm:mb-0 mb-4 w-[100%] h-[150px] p-4 flex items-center justify-between'>
              <div className='w-[70%]'>
                <h2 className='text-orange-600 mb-3 text-2xl counter-text'>Products</h2>
                <p className='text-2xl mb-3 font-bold'>{supplierProducts?.length}</p>
                <a href='/supplier/products' className='text-gray-400 underline'>Go to products</a>
              </div>
              <div className='w-[30%]'>
                <MdOutlineProductionQuantityLimits size={35} color='red' />
              </div>
            </div>
            <div className='shadow bg-white sm:w-[32%] sm:mb-0 mb-4 w-[100%] h-[150px] p-4 flex items-center justify-between'>
              <div className='w-[70%]'>
                <h2 className='text-orange-600 mb-3 text-2xl counter-text'>Ads & Events</h2>
                <p className='text-2xl mb-3 font-bold'>{supplierAds?.length}</p>
                <a href='/supplier/ads' className='text-gray-400 underline'>Go to ads</a>
              </div>
              <div>
                <RiAdvertisementLine size={35} color='blue' />
              </div>
            </div>
            
          </div>
        </div>
        <div className='w-full shadow px-10 mt-5'>
          <div className='w-[100%]'>
            <div className='flex justify-between'>
            <h2 className='font-bold text-xl mb-3'>Recent Products</h2>
            <a href="/supplier/products" className='text-orange-600 underline flex items-center justify-center gap-1'>
              Go to products
              <FaArrowCircleRight />
            </a>
            </div>
            <table className='shadow bg-slate-100 block p-8 overflow-y-scroll'> 
              <thead className="text-xs text-gray-700 uppercase bg-[#fff]">
                        <tr className='border-b-2'>
                            <th scope="col" className="p-4">
                                <div className="flex items-center">
                                    <input id="checkbox-all-search" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                    <label for="checkbox-all-search" className="sr-only">checkbox</label>
                                </div>
                            </th>
                            <th scope="col" className="px-4 py-3">
                                ID
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Name
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Price
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Category
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Brand
                            </th>
                            <th scope="col" className="px-4 py-3">
                                Warranty(M)
                            </th>
                            <th scope="col" className="px-6 py-3">
                                isfeatured?
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Status
                            </th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        {supplierProducts && supplierProducts.map(supplierProduct => (
                            <tr className="bg-white border-b-2 hover:bg-gray-50">
                            <td className="w-4 p-4">
                                <div className="flex items-center">
                                    <input id="checkbox-table-search-1" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                    <label for="checkbox-table-search-1" className="sr-only">checkbox</label>
                                </div>
                            </td>
                            <td className='w-4 p-4'>
                                <span>{supplierProduct.sku}</span>
                            </td>
                            <th scope="row" className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap">
                                <div className="ps-3">
                                    <div className="text-base font-semibold">{supplierProduct.name}</div>
                                    <div className="font-normal text-gray-500">{supplierProduct.category}</div>
                                </div>  
                            </th>
                            <td className="px-4 py-4">
                                <span>${supplierProduct.price}</span>
                            </td>
                            <td className="px-4 py-4">
                                <span>{supplierProduct.category}</span>
                            </td>
                            <td className="px-4 py-4">
                                <span>{supplierProduct.brand}</span>
                            </td>
                            <td className="px-4 py-4">
                                <span>{supplierProduct.warranty}</span>
                            </td>
                            <td className="px-6 py-4">
                                <div className="flex items-center">
                                    <span class="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded  border border-green-400">Yes</span>
                                </div>
                            </td>
                            <td className="px-6 py-4">
                                <div className="flex items-center gap-1">
                                    <div class="bg-green-600 w-[12px] h-[12px] rounded-full"></div>
                                    <span>Available</span>
                                    <CiCircleQuestion className='cursor-pointer' data-twe-toggle="tooltip" title="Large quantity" />
                                </div>
                            </td>
                        </tr>
                        ))}
                    </tbody>
            </table>
          </div>
        </div>
        {isModalOpen && (
                <form onSubmit={handleSubmit} id="popup-modal" tabIndex="-1" className="fixed top-[100px] sm:left-[40%] z-50 justify-center items-center w-full">
                    <div className="relative p-4 w-full max-w-md max-h-full">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <button type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={toggleModal}>
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                            <div className="p-4 md:p-5 text-center">
                            <div className="mb-5">
                              <label for="exchange Rate" class="block mb-2 text-start text-sm font-medium text-gray-900">Dollar Exchange Rate</label>
                              <input type="number" name="dollarExchangeRate" id="email" value={dollarExchangeRate} onChange={(e) => setExchangeRate(e.target.value)} class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" required=""/>
                          </div>
                                <button data-modal-hide="popup-modal" type="submit"  className="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                                    Update
                                </button>
                                <button data-modal-hide="popup-modal" onClick={toggleModal} type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">No, cancel</button>
                            </div>
                        </div>
                    </div>
                </form>
            )}
      </main>
    </div>
  )
}

export default SupplierDashboard