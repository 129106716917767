import React, { useState } from 'react'
import AdminHeadbar from './components/AdminHeadbar'
import AdminSidebar from './components/AdminSidebar'
import axios from 'axios';
import { toast } from 'react-toastify';
import { server } from '../../server';

const AddFAQ = () => {
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        status: true, // Default value for status
      });
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          await axios.post(`${server}/faq`, formData);
          // Optionally, you can reset the form after successful submission
          setFormData({
            title: '',
            description: '',
            status: true,
          });
          toast.success('FAQ added successfully!');
        } catch (error) {
          toast.error('Error adding faq. Please try again.');
        }
      };
  return (
    <div className='flex'>
      <div className='w-[20%]'>
        <AdminSidebar />
      </div>
      <main className='w-[80%]'>
        <AdminHeadbar />
        <div className='p-[40px] bg-[#f8f9fc]'>
            <div className='border-b-2 border-dashed py-[30px] flex items-center'>
                <span className='font-medium text-xl'>Create New FAQ</span>
            </div>
            <div className='flex p-[30px]'>
                <div className='basis-[40%]'>
                    <h2 className='font-semibold mb-4'>Information</h2>
                    <p className='text-gray-600'>Add faq title & desc and create a new faq from here</p>
                </div>
                <div className='basis-[60%] bg-white p-6'>
                    <form onSubmit={handleSubmit} class="max-w-sm mx-auto">
                        <div class="mb-5">
                            <label for="name" class="block mb-2 text-sm font-medium text-gray-900">FAQ Title</label>
                            <input type="text" id="name" name='title' value={formData.title} onChange={handleChange} class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div class="mb-5">
                            <label for="name" class="block mb-2 text-sm font-medium text-gray-900">FAQ Description</label>
                            <textarea name="description" id="desc" cols="30" rows="10" value={formData.description} onChange={handleChange}  class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"></textarea>
                        </div>
                        
                        <div class="flex items-start mb-5">
                            <div class="flex items-center h-5">
                            <input id="terms" type="checkbox" checked={formData.status} onChange={() => setFormData({ ...formData, status: !formData.status })} class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300" required />
                            </div>
                            <label for="terms" class="ms-2 text-sm font-medium text-gray-900">isActive?</label>
                        </div>
                        <button type="submit" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Add FAQ</button>
                    </form>
                </div>
            </div>
        </div>
        </main>
    </div>
  )
}

export default AddFAQ