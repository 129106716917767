import React, { useEffect, useState } from 'react'
import { FaArrowAltCircleRight } from 'react-icons/fa'
import SupplierSidebar from './components/SupplierSidebar'
import SupplierHeadbar from './components/SupplierHeadbar'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { server } from '../../server'
import { useAuth } from '../../middleware/AuthContext'
import { toast } from 'react-toastify'

const SupplierEditAd = () => {
    const [auth, setAuth] = useAuth();
    const supplier = auth.supplier;
    const {id} = useParams()
    const [ad, setAd] = useState(
        {   
            supplierId: supplier._id,
            title: '',
            description: '',
            productName: '',
            initialPrice: '',
            newPrice: '',
            startDate: '',
            endDate: '',
            isActive: true,
            status: 'featured',
        }
    )

    const handleChange = (e) => {
        const { name, value } = e.target;
        setAd(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Send updated data to the server
            await axios.patch(`${server}/ads/update-ad/${id}`, ad);
            // Optionally, you can fetch the updated data again to reflect changes in the UI
            toast.success('Data updated successfully!');
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        } catch (error) {
            console.error('Error updating supplier:', error);
            toast.error({error});
        }
    };

    useEffect(() => {
        const fetchAds = async () => { 
          try {
            const response = await axios.get(`${server}/ads/get-ad/${id}`);
            setAd(response.data.ad);
            
          } catch (error) {
            
          } 
        };
        fetchAds();
      }, [id]);

  return (
    <div className='flex'>
      <div className='w-[20%]'>
        <SupplierSidebar />
      </div>
      <main className='w-[80%]'>
        <SupplierHeadbar />
        <form className='sm:p-[40px] p-[10px] bg-[#f8f9fc]' onSubmit={handleSubmit}> 
            <div className='border-b-2 border-dashed py-[30px] flex items-center'>
                <span className='font-medium text-xl'>Edit Ad</span>
            </div>
            <div className='sm:flex sm:p-[30px] p-[10px] border-b-2 border-dashed'>
                <div className='sm:basis-[30%] basis-[100%]'>
                    <h2 className='font-semibold mb-4'>Information</h2>
                    <p className='text-gray-600'>Edit ad information</p>
                </div>
                <div className='sm:basis-[70%] basis-[100%] bg-white sm:p-10 p-2'>
                    <div className=" mx-auto">
                        <div className="mb-5">
                            <label for="name" class="block mb-2 text-sm font-medium text-gray-900">Ad Title</label>
                            <input type="text" id="name" name='title' value={ad.title} onChange={handleChange} class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div className="mb-5">
                            <label for="price" class="block mb-2 text-sm font-medium text-gray-900">Ad Description</label>
                            <textarea name="description" value={ad.description} onChange={handleChange}  id="description" cols="30" rows="10" class="shadow-sm bg-gray-50 border outline-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"></textarea>
                        </div>
                        <div className="mb-5">
                            <label for="sku" class="block mb-2 text-sm font-medium text-gray-900">Product Name</label>
                            <input type="text" id="sku" name='productName' value={ad.productName} onChange={handleChange}  class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div className="mb-5">
                            <label for="sku" class="block mb-2 text-sm font-medium text-gray-900">Initial Price</label>
                            <input type="number" id="sku" name='initialPrice' value={ad.initialPrice} onChange={handleChange}  class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div className="mb-5">
                            <label for="sku" class="block mb-2 text-sm font-medium text-gray-900">New Price</label>
                            <input type="number" id="sku" name='newPrice' value={ad.newPrice} onChange={handleChange}  class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div className="mb-5">
                            <label for="sku" class="block mb-2 text-sm font-medium text-gray-900">Start Date</label>
                            <input type="date" id="sku" name='startDate' value={ad.startDate} onChange={handleChange}  class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div className='mb-5'>
                            <label for="description" class="block mb-2 text-sm font-medium text-gray-900">End Date</label>
                            <input type="date" id="sku" name='endDate' value={ad.endDate} onChange={handleChange}  class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div class="flex items-start mb-5">
                            <div class="flex items-center h-5">
                            <input id="terms" type="checkbox" name='isActive' checked={ad.isActive} onChange={() => setAd({ ...ad, isActive: !ad.isActive })} class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300" required />
                            </div>
                            <label for="terms" class="ms-2 text-sm font-medium text-gray-900 mr-2">isActive?</label>    
                        </div>
                    </div> 
                </div>
            </div>
            
            <div className='w-full flex justify-between items-center'>
                <a href='/supplier/ads' className='sm:flex hidden items-center gap-1 text-orange-600'>
                    <FaArrowAltCircleRight />
                    Go to ads
                </a>
                <div className='flex gap-2'>
                    <a href="/suppliers/ads" class="border border-orange-600 hover:bg-orange-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">See Ads</a>
                    <button type="submit" class="text-white bg-orange-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Edit Ad</button>
                </div>
            </div>
        </form>
        </main>
    </div>
  )
}

export default SupplierEditAd