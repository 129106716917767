import React, { useEffect, useState } from 'react'
import { FaArrowAltCircleRight } from 'react-icons/fa'
import AdminHeadbar from './components/AdminHeadbar'
import AdminSidebar from './components/AdminSidebar'
import { toast } from 'react-toastify'
import axios from 'axios'
import { server } from '../../server'

const Info = () => {
    const [formData, setFormData] = useState({
        country: '',
        city: '',
        address: '',
        phone: '',
        email: '' // Default value for status
      });
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          await axios.post(`${server}/info`, formData);
          // Optionally, you can reset the form after successful submission
          setFormData({
            country: '',
            city: '',
            address: '',
            phone: '',
            email: ''
          });
          toast.success('Information updated successfully!');
        //   window.location.reload();
        } catch (error) {
          toast.error('Error adding info. Please try again.');
        }
      };

      useEffect(() => {
        // Fetch existing data from an API endpoint or other source
        const fetchData = async () => {
            try {
                const response = await axios.get(`${server}/info`);
                const existingData = response.data[0]; // Assuming API returns data in the same format as formData
                setFormData(existingData);
            } catch (error) {
                console.error('Error fetching existing data:', error.message);
            }
        };

        fetchData();
    }, []);
  return (
    <div className='flex'>
      <div className='w-[20%]'>
        <AdminSidebar />
      </div>
      <main className='w-[80%]'>
        <AdminHeadbar />
        <form className='p-[40px] bg-[#f8f9fc]' onSubmit={handleSubmit}>
            <div className='border-b-2 border-dashed py-[30px] flex items-center'>
                <span className='font-medium text-xl'>Company Information</span>
            </div>
            <div className='flex p-[30px]'>
                <div className='basis-[30%]'>
                    <h2 className='font-semibold mb-4'>Information</h2>
                    <p className='text-gray-600'>Change your company information from here.</p>
                </div>
                <div className='basis-[70%] bg-white p-10'>
                    <div class="mb-5 flex justify-between gap-4">
                        <div className='w-full'>
                            <label for="name" class="block mb-2 text-sm font-medium text-gray-900">Country</label>
                            <input type="text" id="name" name='country' value={formData.country} onChange={handleChange} class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div className='w-full'>
                        <label for="name" class="block mb-2 text-sm font-medium text-gray-900">City</label>
                            <input type="text" id="name" name='city' value={formData.city} onChange={handleChange} class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                    </div>
                    <div class="mb-5">
                        <label for="name" class="block mb-2 text-sm font-medium text-gray-900">Street Address</label>
                        <textarea id="" cols="30" rows="10" name='address' value={formData.address} onChange={handleChange} class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"></textarea>
                    </div>
                    <div class="mb-5">
                        <label for="name" class="block mb-2 text-sm font-medium text-gray-900">Contact Number</label>
                        <input type="tel" id="name" name='phone' value={formData.phone} onChange={handleChange} class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder='+254....' required />
                    </div>
                    <div class="mb-5">
                        <label for="name" class="block mb-2 text-sm font-medium text-gray-900">Email Address</label>
                        <input type="email" id="name" name='email' value={formData.email} onChange={handleChange} class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder='info@gma...' required />
                    </div>
                </div>
            </div>
            <div className='w-full flex justify-between items-center'>
                <a href='/' className='flex items-center gap-1 text-orange-600'>
                    <FaArrowAltCircleRight />
                    go to site
                </a>
                <div className='flex gap-2'>
                    <button type="submit" class="border border-orange-600 hover:bg-orange-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Reset</button>
                    <button type="submit" class="text-white bg-orange-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Update Information</button>
                </div>
            </div>
        </form>
        </main>
    </div>
  )
}

export default Info