import React, { useState } from 'react'
import AdminSidebar from './components/AdminSidebar'
import AdminHeadbar from './components/AdminHeadbar'
import axios from 'axios'
import { server } from '../../server'
import { toast } from 'react-toastify'

const AddAdmin = () => {
    const [formData, setFormData ] = useState({
        username: '',
        email: '',
        password: '',
    })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          await axios.post(`${server}/admins`, formData);
          // Optionally, you can reset the form after successful submission
          setFormData({
            username: '',
            email: '',
            password: ''
          });
          toast.success('Admin created successfully!');
        } catch (error) {
          toast.error('Error creating admin. Please try again.');
        }
      };

  return (
    <div className='flex'>
      <div className='w-[20%]'>
        <AdminSidebar />
      </div>
      <main className='w-[80%]'>
        <AdminHeadbar />
        <div className='p-[40px] bg-[#f8f9fc]'>
            <div className='border-b-2 border-dashed py-[30px] flex items-center'>
                <span className='font-medium text-xl'>Create New Admin</span>
            </div>
            <div className='flex p-[30px]'>
                <div className='basis-[40%]'>
                    <h2 className='font-semibold mb-4'>Information</h2>
                    <p className='text-gray-600'>Add admin information and create a new admin from here</p>
                </div>
                <div className='basis-[60%] bg-white p-6'>
                    <form class="max-w-sm mx-auto" onSubmit={handleSubmit}>
                        <div class="mb-5">
                            <label for="name" class="block mb-2 text-sm font-medium text-gray-900">Username</label>
                            <input type="text" id="name" name='username' value={formData.username} onChange={handleChange} class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="John Doe" required />
                        </div>
                        <div class="mb-5">
                            <label for="email" class="block mb-2 text-sm font-medium text-gray-900">Email Address</label>
                            <input type="email" name='email' value={formData.email} onChange={handleChange} placeholder='email@gmail.com' id="password" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div class="mb-5">
                            <label for="password" class="block mb-2 text-sm font-medium text-gray-900">Password</label>
                            <input type="password" name='password' value={formData.password} onChange={handleChange} id="password" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <button type="submit" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Add Admin</button>
                    </form>
                </div>
            </div>
        </div>
        </main>
    </div>
  )
}

export default AddAdmin