import axios from 'axios';
import React, { useState } from 'react'
import { toast } from 'react-toastify';
import logo from '../../assets/GooglePlayStore.png'
import { server } from '../../server';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          const res = await axios.post(`${server}/resellers/forgot-password`, {
            email,
          });
          setEmail({
            email: ''
          }, 
        )
          if (res) {
            toast.success("Please check your email for further instructions")
          } else {
            toast.error(res.data.message);
          }
        } catch (error) {
          console.log(error);
          toast.error("Something went wrong");
        }
      };
  return (
    <div>
        
        <section class="bg-gray-50">
            <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                <a href="/" class="flex items-center mb-6 text-2xl font-semibold text-gray-900">
                    <img class="w-8 h-8 mr-2" src={logo} alt="logo"/>
                    Reseller Sprint   
                </a>
                <div class="w-full p-6 bg-white rounded-lg shadow md:mt-0 sm:max-w-md sm:p-8">
                    <h1 class="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                        Forgot your password?
                    </h1>
                    <p class="font-light text-gray-500">Don't fret! Just type in your email and we will send you a link to reset your password!</p>
                    <form onSubmit={handleSubmit} class="mt-4 space-y-4 lg:mt-5 md:space-y-5" action="#">
                        <div>
                            <label for="email" class="block mb-2 text-sm font-medium text-gray-900">Your email</label>
                            <input type="email" name="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="name@email.com" required=""/>
                        </div>
                        <div class="flex items-start">
                            <div class="ml-3 text-sm">
                                <label for="terms" class="font-light text-gray-500">Go Back To <a class="font-medium text-primary-600 hover:underline" href="/login">Login</a></label>
                            </div>
                        </div>
                        <button type="submit" class="w-full text-white bg-orange-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Reset password</button>
                    </form>
                </div>
            </div>
        </section>
    </div>
  )
}

export default ForgotPassword