import React, { useEffect, useState } from 'react'
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar"; 
import { FaTachometerAlt, FaUsers } from "react-icons/fa";
import { RiUserStarLine } from "react-icons/ri";
import { CiShoppingCart } from "react-icons/ci";
// import { RiCustomerService2Line } from "react-icons/ri";
import { IoMdClose, IoMdLogOut } from "react-icons/io";
import { FaRegUserCircle } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import { IoMdMenu } from "react-icons/io";
import logo from '../../../assets/GooglePlayStore.png'
import { useAuth } from '../../../middleware/AuthContext';
import { toast } from 'react-toastify';

const AdminSidebar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [auth, setAuth] = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 979) {
        setCollapsed(true);
      } else {
        setCollapsed(false);
      }
    };

    // Call handleResize on initial load
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleLogout = () => {
    setAuth({
      ...auth,
      admin: null,
      token: "",
    });
    localStorage.removeItem("auth");
    toast.success("Logout Successfully");
    navigate('/admin/login')
  };

  return (
    <div id="app" style={({ height: "100vh" }, { display: "flex" })} className='fixed'>
      <Sidebar  
          collapsed={collapsed}
            
         backgroundColor='white' 
         className='h-screen relative ' >
          <button className="sb-button absolute top-0 right-2" onClick={() => setCollapsed(!collapsed)}>
          {collapsed ? <IoMdClose size={28} /> : <IoMdMenu size={28} />}
          </button>
           
            <div className='flex gap-3 py-5 items-center'>
                <img src={logo} alt="" width={70} height={100} />
                <h2 className='counter-text text-orange-500 font-semibold text-2xl'>Admin Panel</h2>
            </div>
            <Menu>
            <MenuItem component={<Link to="/admin" />} icon={<FaTachometerAlt />}> Dashboard </MenuItem>
            <div style={{ padding: '0 15px', }}>
              <span
                className='font-bold text-sm text-gray-400'
              >
                User Management
              </span>
            </div>
                <SubMenu icon={<FaUsers />} label="Admins">
                <MenuItem component={<Link to="/admin/admins" />}> All Admins </MenuItem>
                <MenuItem component={<Link to="/admin/add-admin" />}> Add new admin </MenuItem>
                </SubMenu>
                <SubMenu icon={<FaUsers />} label="Resellers">
                <MenuItem component={<Link to="/admin/resellers" />}> All Resellers </MenuItem>
                <MenuItem component={<Link to="/admin/add-reseller" />}> Add new reseller </MenuItem>
                <MenuItem component={<Link to="/admin/pending-resellers" />}> Pending Resellers </MenuItem>
                </SubMenu>
                <div style={{ padding: '0 15px', }}>
                    <span
                        className='font-bold text-sm text-gray-400'
                    >
                        Supplier Management
                    </span>
                </div>
                <SubMenu icon={<FaUsers />} label="Suppliers">
                <MenuItem component={<Link to="/admin/suppliers" />}> All Suppliers </MenuItem>
                <MenuItem component={<Link to="/admin/add-supplier" />} > Add new supplier </MenuItem>
                <MenuItem component={<Link to="/admin/pending-suppliers" />}> Pending Suppliers </MenuItem>
                </SubMenu>
                {/* <MenuItem icon={<RiUserStarLine />} style={{marginBottom: "20px"}}>My shops</MenuItem>   */}
                <div style={{ padding: '0 15px', }}>
                    <span
                        className='font-bold text-sm text-gray-400'
                    >
                        Product Management
                    </span>
                </div>
                <SubMenu icon={<FaUsers />} label="Products">
                <MenuItem component={<Link to="/admin/products" />}> All Products </MenuItem>
                <MenuItem component={<Link to="/admin/add-product" />}> Add new product </MenuItem>
                <MenuItem component={<Link to="/admin/add-bulk-products" />}> Add bulk products </MenuItem>
                </SubMenu>
                <MenuItem component={<Link to="/admin/categories" />} icon={<RiUserStarLine />}>Categories</MenuItem>  
                <MenuItem component={<Link to="/admin/brands" />} icon={<RiUserStarLine />} style={{marginBottom: "20px"}}>Brands</MenuItem>  
                <div style={{ padding: '0 15px', }}>
                    <span
                        className='font-bold text-sm text-gray-400'
                    >
                        Ad Management
                    </span>
                </div>
                <SubMenu icon={<FaUsers />} label="Ads">
                <MenuItem component={<Link to="/admin/ads" />}> All Ads </MenuItem>
                <MenuItem component={<Link to="/admin/add-ad" />}> Add new Ad </MenuItem>
                </SubMenu>
                  
                <div style={{ padding: '0 15px', }}>
                    <span
                        className='font-bold text-sm text-gray-400'
                    >
                        Analytics
                    </span>
                </div>
                <MenuItem component={<Link to="/admin/searches" />} icon={<RiUserStarLine />}>Searches</MenuItem>  
                <div style={{ padding: '0 15px', }}>
                    <span
                        className='font-bold text-sm text-gray-400'
                    >
                        Layout Controls
                    </span>
                </div>
                <SubMenu icon={<FaUsers />} label="FAQs">
                <MenuItem component={<Link to="/admin/faq" />}> All FAQs </MenuItem>
                <MenuItem component={<Link to="/admin/add-faq" />}> Add new faq </MenuItem>
                </SubMenu> 
                <SubMenu icon={<CiShoppingCart />} style={{marginBottom: "20px"}} label="Terms and Conditions">
                <MenuItem component={<Link to="/admin/terms" />}> All terms </MenuItem>
                <MenuItem component={<Link to="/admin/add-term" />}> Add new terms </MenuItem>
                </SubMenu>
                <div style={{ padding: '0 15px', }}>
                    <span
                        className='font-bold text-sm text-gray-400'
                    >
                        Site Management
                    </span>
                </div>
                <MenuItem component={<Link to="/admin/general" />} icon={<RiUserStarLine />}>General Settings</MenuItem>  
                <MenuItem component={<Link to="/admin/seo" />} icon={<RiUserStarLine />}>SEO Settings</MenuItem>  
                <MenuItem component={<Link to="/admin/maintenance" />} icon={<RiUserStarLine />}>Maintenance Settings</MenuItem>  
                <MenuItem component={<Link to="/admin/info" />} style={{marginBottom: "20px"}} icon={<RiUserStarLine />}>Company Info</MenuItem>  
                
                <MenuItem component={<Link to="/admin/account" />} icon={<FaRegUserCircle />}> My Account </MenuItem>
                <MenuItem onClick={handleLogout} icon={<IoMdLogOut />}> Logout </MenuItem>
            </Menu>
        </Sidebar>
    </div>
    
  )
}

export default AdminSidebar