import React, { useEffect, useState } from 'react'
import { FaEye, FaPlus, FaTrash, FaUserShield } from 'react-icons/fa6'
import { Link } from 'react-router-dom'
import AdminHeadbar from './components/AdminHeadbar'
import AdminSidebar from './components/AdminSidebar'
import { MdOutlineDangerous } from 'react-icons/md'
import profile from '../../assets/GooglePlayStore.png'
import { FaRegEdit } from 'react-icons/fa'
import axios from 'axios'
import { server } from '../../server'
import { toast } from 'react-toastify'

const Products = () => {
    const [products, setProducts] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [categories, setCategories] = useState([]);
    const [brands, setBrands] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedBrand, setSelectedBrand] = useState('');
    const [selectedSupplier, setSelectedSupplier] = useState('');
    const [deleteProduct, setDeleteProduct] = useState(null)
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const fetchSuppliers = async () => { 
      try {
        const response = await axios.get(`${server}/products/get-products`);
        setProducts(response.data);

        const suppliersResponse = await axios.get(`${server}/suppliers/get-suppliers`);
        setSuppliers(suppliersResponse.data);

        const categoriesResponse = await axios.get(`${server}/categories`);
        setCategories(categoriesResponse.data);

        const brandsResponse = await axios.get(`${server}/brands`);
        setBrands(brandsResponse.data);
      } catch (error) {
        
      } 
    };
    fetchSuppliers();
  }, []);

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    };


  const handleBrandChange = (event) => {
    setSelectedBrand(event.target.value);
  };

  const handleSupplierChange = (event) => {
    setSelectedSupplier(event.target.value);
  };

  const filteredProducts = products.filter(product => {
    return (
      (selectedCategory === '' || product.category === selectedCategory) &&
      (selectedBrand === '' || product.brand === selectedBrand) &&
      (selectedSupplier === '' || product.supplier === selectedSupplier) &&
      product.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  const toggleDeleteModal = (product) => {
    setDeleteModalOpen(!isDeleteModalOpen);
    setDeleteProduct(product)
}

  const handleDelete = async () => {
    try {
        const response = await axios.delete(`${server}/products/delete-product/${deleteProduct._id}`);
        toast.success(response.data.message);
        setDeleteModalOpen(false)
        window.location.reload();
    } catch (error) {
        toast.error('Failed to delete product');
    }
};
  return (
    <div className='flex'>
      <div className='w-[20%]'>
        <AdminSidebar />
      </div>
      <main className='w-[80%]'>
      <AdminHeadbar />
        <div className='m-[30px] bg-[#f8f9fc]'>
            <div className='bg-[#fff] shadow mb-3'>
                <div className='bg-[#fff] flex justify-between p-[20px] items-center mb-10 border-b-2'>
                    <div className='border-l-4 border-orange-500 rounded-lg p-4'>
                        <span className='text-2xl font-semibold'>Products</span>
                    </div>
                    <div className='flex justify-between gap-[75px] w-[700px]'>   
                        <form className="w-full">   
                            <label for="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only">Search</label>
                            <div className="relative">
                                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                    <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                                    </svg>
                                </div>
                                <input type="search" id="default-search" className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-orange-500 focus:border-orange-500" onChange={handleSearchChange} placeholder="Search by name..." required />
                                <button type="submit" className="text-white absolute end-2.5 bottom-2.5 bg-orange-700 hover:bg-orange-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2">Search</button>
                            </div>
                        </form>
                        <Link to='../add-product' className='flex items-center justify-center bg-orange-600 gap-4 rounded-xl w-[200px] text-white'>
                            <FaPlus />
                            Add Product
                        </Link>
                    </div>
                </div>
                <div className='grid grid-cols-3 w-full px-[20px] py-[10px] gap-3 bg-[#fff]'>
                    <div>
                        <h2 className='text-gray-400 mb-2'>Filter by category</h2>
                        <select name="" id="" className='rounded-md w-full h-[40px] border outline-none border-gray-400' onChange={handleCategoryChange}>
                            <option value="">All</option>
                            {categories.map(category => (
                            <option key={category._id} value={category.name}>{category.name}</option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <h2 className='text-gray-400 mb-2'>Filter by brand</h2>
                        <select name="" id="" className='rounded-md w-full h-[40px] border outline-none border-gray-400' onChange={handleBrandChange}>
                            <option value="">All</option>
                            {brands.map(brand => (
                        <option key={brand._id} value={brand.name}>{brand.name}</option>
                        ))}
                        </select>
                    </div>
                    <div>
                        <h2 className='text-gray-400 mb-2'>Filter by supplier</h2>
                        <select name="" id="" className='rounded-md w-full h-[40px] border outline-none border-gray-400' onChange={handleSupplierChange}>
                            <option value="">All</option>
                            {suppliers.map(supplier => (
                        <option key={supplier._id} value={supplier._id}>{supplier.companyName}</option>
                        ))}
                        </select>
                    </div>
                </div>
            </div>
            <div className='bg-[#fff] h-[70vh]'>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg overflow-y-auto">      
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 overflow-y-auto">
                    <thead className="text-xs text-gray-700 uppercase bg-[#fff]">
                        <tr>
                            <th scope="col" className="p-4">
                                <div className="flex items-center">
                                    <input id="checkbox-all-search" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                    <label for="checkbox-all-search" className="sr-only">checkbox</label>
                                </div>
                            </th>
                            <th scope="col" className="px-4 py-3">
                                SKU
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Name
                            </th>
                            <th scope="col" className="px-4 py-3">
                               Price
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Brand
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Supplier
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredProducts && filteredProducts.map(product => (
                            <tr key={product._id} className="bg-white border-b hover:bg-gray-50">
                            <td className="w-4 p-4">
                                <div className="flex items-center">
                                    <input id="checkbox-table-search-1" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                    <label for="checkbox-table-search-1" className="sr-only">checkbox</label>
                                </div>
                            </td>
                            <td className='w-4 p-4'>
                                <span>{product.sku}</span>
                            </td>
                            <th scope="row" className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap">
                                <img className="w-10 h-10 rounded-full" src={profile} alt="Jese images"/>
                                <div className="ps-3">
                                    <div data-twe-toggle="tooltip" title={product.name} className="text-base font-semibold">{product.name.length > 30 ? `${product.name.slice(0, 30)}...` : product.name}</div>
                                    <div className="font-normal text-gray-500">{product.category}</div>
                                </div>  
                            </th>
                            <td className="px-4 py-4">
                                <span>{product.price}</span>
                            </td>
                            <td className="px-4 py-4">
                                <span>{product.brand}</span>
                            </td>
                            <td className="px-6 py-4">
                                <span>{suppliers.find(supplier => supplier._id === product.supplier)?.companyName}</span>
                            </td>
                            
                            <td className="px-6 py-4">
                                <div className='flex gap-2'>
                                    <a href={`/admin/edit-product/${product._id}`} data-twe-toggle="tooltip" title="Edit product details">
                                        <FaRegEdit color='blue' size={20} />
                                    </a>  
                                    <button data-twe-toggle="tooltip" title="Delete Product">
                                        <FaTrash size={20} color="grey" onClick={() => toggleDeleteModal(product)} />
                                    </button>
                                </div>
                            </td>
                        </tr>
                        ))}
                        
                    </tbody>
                </table>
            </div>
        </div>
        </div>
      </main>
      {isDeleteModalOpen && (
                <div id="popup-modal" tabIndex="-1" className="fixed top-[100px] left-[40%] z-50 justify-center items-center w-full">
                    <div className="relative p-4 w-full max-w-md max-h-full">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <button type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={toggleDeleteModal}>
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                            <div className="p-4 md:p-5 text-center">
                                <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                                </svg>
                                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to delete this product?</h3>
                                <button data-modal-hide="popup-modal" type="button" onClick={handleDelete} className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                                    Yes, I'm sure
                                </button>
                                <button data-modal-hide="popup-modal" onClick={toggleDeleteModal} type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">No, cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
    </div>
  )
}

export default Products