import React from 'react'

const AdCard = ({ad}) => {
  return (
    <div className="w-[100%] p-2 flex shadow-md border border-gray-400 rounded-md">
        <div className='border-r-2'>
            <h2 className='font-medium text-3xl counter-text text-orange-500'>{ad.title}</h2>
            <p className='font-semibold'>{ad.description}</p>
            <p>Valid till: <span className='text-orange-600'>{ad.endDate}</span></p>
        </div>
        <div className='ml-2'>
            <h2 className='font-medium'>{ad.productName}</h2>
            <div className='flex gap-2'>
                <p className="line-through">${ad.initialPrice}</p>
                <p className="text-green-500">${ad.newPrice}</p>
            </div>
        </div>
    </div>
  )
}

export default AdCard