import React, { useEffect, useState } from 'react'
import Footer from '../components/Footer'
import { FaEye, FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa6'
import PlayImg from '../assets/play.png'
import AppImg from '../assets/appstore.png'
import MainNav from '../components/MainNav'
import { CiCircleQuestion } from "react-icons/ci";
import axios from 'axios'
import { server } from '../server'
import { CSVLink } from 'react-csv';
import { FaFileCsv } from 'react-icons/fa';
import BreadCrumbs from '../components/BreadCrumbs'
import ProductReview from '../components/ProductReview'

const ProductsPage = () => {
  const [products, setProducts] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [brands, setBrands] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedBrand, setSelectedBrand] = useState('');
  const [selectedSupplier, setSelectedSupplier] = useState('');
  const [csvData, setCsvData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    const fetchSuppliers = async () => {
      try {
        const response = await axios.get(`${server}/products/get-products`);
        setProducts(response.data);

        const suppliersResponse = await axios.get(`${server}/suppliers/approved-suppliers`);
        setSuppliers(suppliersResponse.data);

        const categoriesResponse = await axios.get(`${server}/categories`);
        setCategories(categoriesResponse.data);

        const brandsResponse = await axios.get(`${server}/brands`);
        setBrands(brandsResponse.data);
      } catch (error) {

      }
    };
    fetchSuppliers();
  }, []);

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleBrandChange = (event) => {
    setSelectedBrand(event.target.value);
  };

  const handleSupplierChange = (event) => {
    setSelectedSupplier(event.target.value);
  };

  const toggleModal = (filteredProduct) => {
    setModalOpen(!modalOpen);
    setSelectedProduct(filteredProduct)
  };


  const filteredProducts = products.filter(product => {
    return (
      (selectedCategory === '' || product.category === selectedCategory) &&
      (selectedBrand === '' || product.brand === selectedBrand) &&
      (selectedSupplier === '' || product.supplier === selectedSupplier) &&
      (product.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        product.sku.toLowerCase().includes(searchQuery.toLowerCase()) ||
        product.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
        product.category.toLowerCase().includes(searchQuery.toLowerCase()) ||
        product.brand.toLowerCase().includes(searchQuery.toLowerCase()))
    );
  });

  const handleExport = () => {
    // Format data for CSV export
    const data = filteredProducts.map(product => ({
      ID: product.sku,
      Name: product.name,
      Price: `$${product.price}`,
      Category: product.category,
      Brand: product.brand,
      Supplier: `${suppliers.find(supplier => supplier._id === product.supplier)?.companyName}`,
      Warranty: product.warranty, // Assuming this is a static value
      Status: product.status, // Assuming this is a static value
    }));
    // Update CSV data
    setCsvData(data);
  };

  const breadcrumbs = [{ name: 'Home', path: '/home' }, { name: 'Products', path: '/products' }]; // Define breadcrumbs


  return (
    <div>
      <MainNav />
      <div className='sm:h-[150px] h-[100px] top-section bg-red-500 mb-3'>
        <div className='top-overlay px-16' style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "end" }}>
          <h1 className='sm:text-6xl text-3xl text-center text-orange-600 font-bold mb-5 counter-text'>Products</h1>
        </div>
      </div>
      <div>
        <BreadCrumbs items={breadcrumbs} />
      </div>
      <div className='p-[40px]'>
        <div className=''>
          <div className='sm:grid sm:grid-cols-3 w-full sm:px-[30px] py-[10px] gap-3 bg-[#fff] mb-4'>
            <div>
              <h2 className='text-gray-400 mb-2'>Filter by category</h2>
              <select name="" id="" className='rounded-md w-full h-[40px] border outline-none border-gray-400' onChange={handleCategoryChange}>
                <option value="">All</option>
                {categories.map(category => (
                  <option key={category._id} value={category.name}>{category.name}</option>
                ))}
              </select>
            </div>
            <div>
              <h2 className='text-gray-400 mb-2'>Filter by brand</h2>
              <select name="" id="" className='rounded-md w-full h-[40px] border outline-none border-gray-400' onChange={handleBrandChange}>
                <option value="">All</option>
                {brands.map(brand => (
                  <option key={brand._id} value={brand.name}>{brand.name}</option>
                ))}
              </select>
            </div>
            <div>
              <h2 className='text-gray-400 mb-2'>Filter by supplier</h2>
              <select name="" id="" className='rounded-md w-full h-[40px] border outline-none border-gray-400' onChange={handleSupplierChange}>
                <option value="">All</option>
                {suppliers.map(supplier => (
                  <option key={supplier._id} value={supplier._id}>{supplier.companyName}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className='flex flex-wrap gap-3 items-center justify-center'>
          <table className='shadow h-[500px] bg-slate-100 block p-8 overflow-scroll'>
            <div className='flex justify-between mb-4'>
              <form class="sm:w-[200px] mr-2 sm:mr-0 h-[40px]">
                <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only">Search</label>
                <div class="relative">
                  <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                    <svg class="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                    </svg>
                  </div>
                  <input type="search" onChange={handleSearchChange} id="default-search" class="block w-[200px] h-[40px] p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-orange-500 focus:border-orange-500 outline-none" placeholder="Search Here..." required />
                </div>
              </form>
              <CSVLink
                data={csvData}
                filename={`All_products.csv`}
                className="bg-blue-500 px-2 p-2 text-white flex gap-1 rounded-md items-center"
                target="_blank"
                data-twe-toggle="tooltip"
                title="Export as csv"
                onClick={handleExport}
              >
                Export
                <FaFileCsv />
              </CSVLink>
            </div>
            <thead className="text-xs text-gray-700 uppercase bg-[#fff]">
              <tr className='border-b-2'>
                <th scope="col" className="p-4">
                  <div className="flex items-center">
                    <input id="checkbox-all-search" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                    <label for="checkbox-all-search" className="sr-only">checkbox</label>
                  </div>
                </th>
                <th scope="col" className="px-4 py-3">
                  SKU
                </th>
                <th scope="col" className="px-6 py-3">
                  Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Price
                </th>
                <th scope="col" className="px-6 py-3">
                  Brand
                </th>
                <th scope="col" className="px-4 py-3">
                  Warranty(Months)
                </th>
                <th scope="col" className="px-6 py-3">
                  Supplier
                </th>
                <th scope="col" className="px-6 py-3">
                  Status
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredProducts && filteredProducts.map(filteredProduct => (
                <tr className="bg-white border-b-2 hover:bg-gray-50" key={filteredProduct._id} >
                  <td className="w-4 p-4">
                    <div className="flex items-center">
                      <input id="checkbox-table-search-1" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                      <label for="checkbox-table-search-1" className="sr-only">checkbox</label>
                    </div>
                  </td>
                  <td className='w-4 p-4'>
                    <span>{filteredProduct.sku}</span>
                  </td>
                  <th scope="row" className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap">
                    <div className="ps-3">
                      <div data-twe-toggle="tooltip" title={filteredProduct.name} className="text-base font-semibold">{filteredProduct.name.length > 30 ? `${filteredProduct.name.slice(0, 30)}...` : filteredProduct.name}</div>
                      <div className="font-normal text-start text-gray-500">{filteredProduct.category}</div>
                    </div>
                  </th>
                  <td className="px-4 py-4">
                    <span>${filteredProduct.price}</span>
                  </td>
                  <td className="px-4 py-4">
                    <span>{filteredProduct.brand}</span>
                  </td>
                  <td className="px-4 py-4">
                    <span>{filteredProduct.warranty}</span>
                  </td>
                  <td className="px-6 py-4">
                    <a href={`/supplier/preview/${filteredProduct.supplier}`} className='text-blue-700 underline'>{suppliers.find(supplier => supplier._id === filteredProduct.supplier)?.companyName}</a>
                  </td>
                  <td className="px-6 py-4">
                    {filteredProduct.status === 'available' && (
                      <div className="flex items-center gap-1">
                        <div className="bg-green-600 w-2 h-2 rounded-full"></div>
                        <span className="text-green-600">Available</span>
                        <CiCircleQuestion className="cursor-pointer" data-twe-toggle="tooltip" title="Large quantity" />
                      </div>
                    )}
                    {filteredProduct.status === 'limited' && (
                      <div className="flex items-center gap-1">
                        <div className="bg-orange-500 w-2 h-2 rounded-full"></div>
                        <span className="text-orange-500">Limited</span>
                        <CiCircleQuestion className="cursor-pointer" data-twe-toggle="tooltip" title="Limited quantity" />
                      </div>
                    )}
                    {filteredProduct.status === 'unavailable' && (
                      <div className="flex items-center gap-1">
                        <div className="bg-red-600 w-2 h-2 rounded-full"></div>
                        <span className="text-red-600">Unavailable</span>
                        <CiCircleQuestion className="cursor-pointer" data-twe-toggle="tooltip" title="Not available" />
                      </div>
                    )}
                  </td>
                  <td className="px-6 py-4">
                    <div className='flex gap-2'>
                      <button onClick={() => toggleModal(filteredProduct)} data-twe-toggle="tooltip" title="View Product Details">
                        <FaEye size={20} color="grey" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {modalOpen && (
            <div tabIndex="-1" className="fixed top-[150px] sm:left-[35%] z-50 justify-center items-center w-full">
              <div className="relative p-4 w-full max-w-md max-h-full">
                {/* Render the ResellerView component and pass the selected reseller data */}
                <ProductReview product={selectedProduct} closeModal={toggleModal} />
                {/* Close button or any other controls to close the modal */}
                <button onClick={() => setModalOpen(false)}>Close Modal</button>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='w-[100%] bg-orange-400 p-10'>
        <div className='w-full'>
          <h2 className='text-white text-2xl text-center font-bold mb-2'>Access our services from your mobile device.</h2>
          <p className='text-white text-center text-xl font-semibold mb-5'>Mobile App Available</p>
          <div className='flex w-full items-center justify-center gap-4'>
            <a href="https://play.google.com/store/apps/details?id=com.codemunity.resellersprint">
              <img src={PlayImg} alt="" />
            </a>
            <a href="/">
              <img src={AppImg} alt="" />
            </a>
          </div>
        </div>
      </div>
      <Footer />
      <div className='w-[100%] px-10 text-black hidden sm:flex items-center justify-between'>
        <p className='text-sm'> <span>&copy;</span> Reseller Sprint. All rights reserved.</p>
        <div className='flex gap-2 items-center'>
          <a href="/privacy" className='underline text-sm text-orange-600'>Terms & conditions</a>
          <a href="/privacy" className='underline text-sm text-orange-600'>Privacy Policy</a>
        </div>
        <div className='flex items-center gap-3'>
          <FaFacebook size={18} color='#4267B2' />
          <FaInstagram size={18} color='#cd486b' />
          <FaTwitter size={18} color='#1DA1F2' />
        </div>
      </div>

    </div>
  )
}

export default ProductsPage