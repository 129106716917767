import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { server } from '../../server';
import SupplierSidebar from './components/SupplierSidebar';
import SupplierHeadbar from './components/SupplierHeadbar';
import { useAuth } from '../../middleware/AuthContext';

const ChangePasswordSupplier = () => {
    const {id} = useParams()
    const [formData, setFormData] = useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
    });
    const [auth, setAuth] = useAuth();
    const navigate = useNavigate()

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleLogoutWithDelay = () => {
        // Set delay time in milliseconds (e.g., 3000 for 3 seconds)
        const delayTime = 3000; // Adjust as needed

        // Show a message to the user that they will be logged out
        toast.info("You will be logged out in a few seconds...");

        // Delay before performing logout
        setTimeout(() => {
            setAuth({
                ...auth,
                supplier: null,
                token: "",
            });
            localStorage.removeItem("auth");
            toast.success("Logout Successfully");
            navigate('/supplier-login')
        }, delayTime);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Send password change request to the server
            await axios.put(`${server}/suppliers/change-password/${id}`, formData);
            toast.success('Password updated successfully');
            setFormData({
                oldPassword: '',
                newPassword: '',
                confirmPassword: '',
            });
            handleLogoutWithDelay();
        } catch (error) {
            toast.error('Failed to update password');
        }
    };

    return (
        
    <div className='flex'>
      <div className='w-[20%]'>
        <SupplierSidebar />
      </div>
      <main className='w-[80%]'>
        <SupplierHeadbar />
        <div className='sm:p-[40px] p-[10px] bg-[#f8f9fc]'>
            <div className='border-b-2 border-dashed py-[30px] flex items-center'>
                <span className='font-medium text-xl'>Edit Password</span>
            </div>
            <div className='sm:flex sm:p-[30px]'>
                <div className='basis-[40%]'>
                    <h2 className='font-semibold mb-4'>Information</h2>
                    <p className='text-gray-600'>Edit your account password here</p>
                </div>
                <div className='basis-[60%] bg-white sm:p-6 p-1'>
                    <form onSubmit={handleSubmit} class="max-w-sm mx-auto">
                        <div class="mb-5">
                            <label for="name" class="block mb-2 text-sm font-medium text-gray-900">Old Password</label>
                            <input type="password" id="name" name='oldPassword' value={formData.oldPassword} onChange={handleChange}class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div class="mb-5">
                            <label for="name" class="block mb-2 text-sm font-medium text-gray-900">New Password</label>
                            <input type="password" id="name" name='newPassword' value={formData.newPassword} onChange={handleChange} class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div class="mb-5">
                            <label for="name" class="block mb-2 text-sm font-medium text-gray-900">Confirm New Password</label>
                            <input type="password" id="name" name='confirmPassword' value={formData.confirmPassword} onChange={handleChange} class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        
                        <button type="submit" class="text-white bg-orange-700 hover:bg-orange-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Edit Password</button>
                    </form>
                </div>
            </div>
        </div>
        </main>
    </div>
    );
};

export default ChangePasswordSupplier;
