import React, {useEffect, useState} from 'react'
import { FaLocationDot, FaPhone } from 'react-icons/fa6'
import logo from '../assets/GooglePlayStore.png'
import axios from 'axios'
import { server } from '../server'

const SupplierCard = ({supplier}) => {
    const [supplierProducts, setSupplierProducts] = useState([])
    const [supplierAds, setSupplierAds] = useState([])

    useEffect(() => {
        const fetchSupplierProducts = async () => { 
          try {
            const response = await axios.get(`${server}/products/get-products-supplier/${supplier._id}`);
            setSupplierProducts(response.data.products);   
          } catch (error) {
            
          } 
        };
        const fetchAds = async () => { 
          try {
            const response = await axios.get(`${server}/ads/get-ads-supplier/${supplier._id}`);
            setSupplierAds(response.data.ads);   
          } catch (error) {
            
          } 
        };
        fetchSupplierProducts();
        fetchAds();
      }, [supplier]);
  return (
    <a href={`/supplier/preview/${supplier._id}`} className='sm:shadow-md border sm:w-[48%] bg-white sm:mb-0 mb-4 w-full p-4'>
        <div className='sm:flex block items-center gap-2 mb-4'>
            <div className='w-[100px] hidden h-[100px] bg-gray-200 rounded-full sm:flex items-center justify-center'>
                <img src={logo} alt="" className='w-[80px] h-[80px]' />
            </div>
            <div className=''>
                <h1 className='text-lg font-medium mb-3'>{supplier.companyName}</h1>
                <span className='flex items-center sm:justify-center mb-2 text-gray-400'>
                    <FaLocationDot color='orange' />
                    {supplier.address}
                </span>
                <span className='flex items-center text-gray-400'>
                    <FaPhone color='orange' />
                    {supplier.phoneNumber}
                </span>
            </div>
        </div>
        <div className='sm:flex flex justify-between'>
            <div className='border-r-2 px-4' style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <span className='font-semibold'>{supplierProducts.length}</span>
                <p className='text-gray-400'>Products</p>
            </div>
            <div className='border-r-2 px-4' style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <span className='font-semibold'>{supplierAds.length}</span>
                <p className='text-gray-400'>Ads</p>
            </div>
            <div className='border-r-2 px-4' style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <span className='font-semibold'>{supplier.dollarExchangeRate}</span>
                <p className='text-gray-400'>Dollar Rate</p>
            </div>
            <div className=' px-4' style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <span className='font-semibold'>{supplier.companyType}</span>
                <p className='text-gray-400'>Type</p>
            </div>
        </div>
    </a>
  )
}

export default SupplierCard