import React, { useEffect, useState } from 'react'
import { FaArrowAltCircleRight } from 'react-icons/fa'
import AdminSidebar from './components/AdminSidebar'
import AdminHeadbar from './components/AdminHeadbar'
import axios from 'axios'
import { server } from '../../server'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import Multiselect from 'multiselect-react-dropdown'

const AdminEditSupplier = () => {
    const {id} = useParams();
    const [supplier, setSupplier] = useState(
        {   
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            companyName: '',
            companyEmail: '', 
            companyType: '',
            dollarExchangeRate: '',
            country: '',
            address: '',
        }
    )

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSupplier(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    // const options = categoriesData.map(categoryData => ({
    //     value: categoryData.name,
    //     label: categoryData.name
    // }));

    // const handleCategoryChange = (selectedOptions) => {
    //     const categoryNames = selectedOptions.map(selectedOption => selectedOption.value);  
    //     setCategories(categoryNames);
    // };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Send updated data to the server
            await axios.put(`${server}/suppliers/update-supplier/${id}`, supplier);
            // Optionally, you can fetch the updated data again to reflect changes in the UI
            toast.success('Data updated successfully!');
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        } catch (error) {
            toast.error({error});
        }
    };

    useEffect(() => {
        const fetchSuppliers = async () => { 
          try {
            const response = await axios.get(`${server}/suppliers/get-supplier/${id}`);
            setSupplier(response.data.supplier);
            
          } catch (error) {
            
          } 
        };
        fetchSuppliers();
      }, [id]);

    //   useEffect(() => {
    //     const fetchCategories = async () => {
    //         try {
    //             const response = await axios.get(`${server}/categories`);
    //             setCategoriesData(response.data);
    //         } catch (error) {
    //             console.error('Error fetching categories:', error);
    //         }
    //     };

    //     fetchCategories();
    // }, []);
  return (
    <div className='flex'>
      <div className='w-[20%]'>
        <AdminSidebar />
      </div>
      <main className='w-[80%]'>
        <AdminHeadbar />
        <form className='p-[40px] bg-[#f8f9fc]' onSubmit={handleSubmit}> 
            <div className='border-b-2 border-dashed py-[30px] flex items-center'>
                <span className='font-medium text-xl'>Edit Supplier</span>
            </div>
            <div className='flex p-[30px] border-b-2 border-dashed'>
                <div className='basis-[30%]'>
                    <h2 className='font-semibold mb-4'>Information</h2>
                    <p className='text-gray-600'>Edit personal information</p>
                </div>
                <div className='basis-[70%] bg-white p-10'>
                    <div className=" mx-auto">
                        <div className="mb-5">
                            <label for="name" class="block mb-2 text-sm font-medium text-gray-900">Supplier First Name</label>
                            <input type="text" id="name" name='firstName' value={supplier.firstName} onChange={handleChange} class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div className="mb-5">
                            <label for="price" class="block mb-2 text-sm font-medium text-gray-900">Supplier Last Name</label>
                            <input type="text" id="price" name='lastName' value={supplier.lastName} onChange={handleChange}  class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="" required />
                        </div>
                        <div className="mb-5">
                            <label for="sku" class="block mb-2 text-sm font-medium text-gray-900">Email Address</label>
                            <input type="email" id="sku" name='email' value={supplier.email} onChange={handleChange}  class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div className='mb-5'>
                            <label for="phoneNumber" class="block mb-2 text-sm font-medium text-gray-900">Phone Number</label>
                            <input type="tel" id="sku" name='phoneNumber' value={supplier.phoneNumber} onChange={handleChange}  class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />                        </div>
                    </div> 
                </div>
            </div>
            <div className='flex p-[30px]'>
                <div className='basis-[30%]'>
                    <h2 className='font-semibold mb-4'>Company Information</h2>
                    <p className='text-gray-600'>Edit company info</p>
                </div>
                <div className='basis-[70%] bg-white p-10'>
                    <div className=" mx-auto">
                        <div className="mb-5">
                            <label for="warranty" class="block mb-2 text-sm font-medium text-gray-900">Company Name</label>
                            <input type="text" name="companyName" value={supplier.companyName} onChange={handleChange} placeholder='company name' id="warranty" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div className="mb-5">
                            <label for="warranty" class="block mb-2 text-sm font-medium text-gray-900">Company Email</label>
                            <input type="email" name="companyEmail" value={supplier.companyEmail} onChange={handleChange} placeholder='company@company.com' id="warranty" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div className="mb-5">
                            <label class="block mb-2 text-sm font-medium text-gray-900">Company Type</label>
                            <select name="companyType" id="" value={supplier.companyType} onChange={handleChange}  class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                <option value="" disabled>Company Type</option>
                                <option value="supplier">Supplier</option>
                                <option value="manufacturer">Manufacturer</option>
                            </select>
                        </div>
                        <div className="mb-5">
                            <label for="warranty" class="block mb-2 text-sm font-medium text-gray-900">Country</label>
                            <input type="text" name="country" value={supplier.country} onChange={handleChange} placeholder='Kenya' id="warranty" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div className="mb-5">
                            <label for="warranty" class="block mb-2 text-sm font-medium text-gray-900">Address</label>
                            <input type="text" name="address" value={supplier.address} onChange={handleChange} placeholder='St Boulevard' id="warranty" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div className="mb-5">
                            <label for="warranty" class="block mb-2 text-sm font-medium text-gray-900">Dollar Exchange Rate</label>
                            <input type="number" name="dollarExchangeRate" value={supplier.dollarExchangeRate} onChange={handleChange} placeholder='12' id="warranty" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                    </div> 
                </div>
            </div>
            <div className='w-full flex justify-between items-center'>
                <a href='/admin/suppliers' className='flex items-center gap-1 text-orange-600'>
                    <FaArrowAltCircleRight />
                    Go back
                </a>
                <div className='flex gap-2'>
                    <a href='/admin/suppliers' class="border border-orange-600 hover:bg-orange-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Cancel</a>
                    <button type="submit" class="text-white bg-orange-600 hover:bg-orange-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Edit Supplier</button>
                </div>
            </div>
        </form>
        </main>
    </div>
  )
}

export default AdminEditSupplier