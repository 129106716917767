import { useState, useEffect } from "react";
import { useAuth } from "../middleware/AuthContext";
import { Outlet } from "react-router-dom";
import axios from "axios";
import SupplierSpinner from "../components/SupplierSpinner";
import { server } from "../server";

export default function SupplierRoute() {
  const [ok, setOk] = useState(false);
  const [auth, setAuth] = useAuth();

  useEffect(() => {
    const authCheck = async () => {
      const res = await axios.get(`${server}/suppliers/supplier-auth`);
      if (res.data.ok) {
        setOk(true);
      } else {
        setOk(false);
      }
    };
    if (auth?.token) authCheck();
  }, [auth?.token]);

  return ok ? <Outlet /> : <SupplierSpinner />;
}
