import React, { useEffect, useState } from 'react'
import MainNav from '../components/MainNav'
import SupplierCard from '../components/SupplierCard'
import { FaArrowRight, FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa6';
import PlayImg from '../assets/play.png'
import AppImg from '../assets/appstore.png'
import Footer from '../components/Footer';
import axios from 'axios';
import { server } from '../server';
import { useNavigate } from 'react-router-dom';

const HomePage = () => {
  const [suppliers, setSuppliers] = useState([]);
  const [brands, setBrands] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      // Send the search query to the backend to save it
      await axios.post(`${server}/search`, { query: searchQuery });
      // Redirect to search results page with the search query
      navigate(`/search-results?query=${searchQuery}`);
    } catch (error) {
      console.error('Error saving search query:', error);
      // Handle error if necessary
    }
  };

  useEffect(() => {
    const fetchSuppliers = async () => {
      try {
        const response = await axios.get(`${server}/suppliers/approved-suppliers`);
        setSuppliers(response.data);
      } catch (error) {

      }
    };
    fetchSuppliers();
  }, []);
  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const response = await axios.get(`${server}/brands`);
        setBrands(response.data);
      } catch (error) {

      }
    };
    fetchBrands();
  }, []);

  const handleBrandClick = (brand) => {
    navigate(`/brand-products?brand=${brand}`);
  };

  return (
    <div>
      <MainNav />
      <div className='hero-section mb-3'>
        <div className='hero-overlay  px-16' style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
          <h1 className='sm:text-5xl text-3xl text-center text-white font-bold mb-5'>Access Genuine Wholesale
            <span className='text-orange-600 counter-text text-4xl sm:text-6xl'> Suppliers</span> In Nairobi - Kenya & Beyond</h1>
          <form class="sm:w-[600px] h-[40px]">
            <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only">Search</label>
            <div class="relative">
              <div class="sm:absolute hidden inset-y-0 start-0 sm:flex items-center ps-3 pointer-events-none">
                <svg class="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                </svg>
              </div>
              <input type="search" id="default-search" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} class="block sm:w-[600px] w-[300px] sm:mx-0 mx-auto h-[40px] p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-orange-500 focus:border-orange-500 relative" placeholder="Search Here..." required />
              <button type="submit" onClick={(e) => handleSearch(e)} class="text-white sm:absolute absolute end-1 bottom-0.5 bg-orange-700 hover:bg-orange-800 focus:ring-4 focus:outline-none focus:ring-orange-300 font-medium rounded-lg text-sm px-4 py-2">Search</button>
            </div>
          </form>
        </div>
      </div>
      <div className='p-5'>
        <div className='mb-4 flex justify-between'>
          <div>
            <h2 className='sm:text-4xl text-2xl counter-text text-orange-600'>Featured Brands</h2>
            <p className='text-md text-gray-400'>Check out some of the featured brands</p>
          </div>
          <div>
          </div>
        </div>
        <div className='flex flex-wrap sm:gap-5 gap-2 justify-evenly w-full'>
          {brands && brands.map((brand) => (
            <div onClick={() => handleBrandClick(brand.name)} key={brand._id} className='h-[80px] sm:w-[20%] w-[48%] hover:w-[20%] bg-white shadow border rounded-md flex items-center justify-center hover:bg-orange-500 hover:text-white'>
              <p className='font-medium'>{brand.name}</p>
            </div>
          ))}
        </div>
      </div>
      <div className='p-5 hidden sm:block'>
        <div className='sm:flex justify-between mb-4'>
          <div>
            <h2 className='sm:text-4xl text-2xl counter-text text-orange-600'>Featured Manufacturers/Suppliers</h2>
            <p className='text-md text-gray-400'>Check out some of the featured brands</p>
          </div>
          <div>
            <a href="/suppliers" className='text-orange-600 hidden sm:flex gap-1 items-center justify-center'>
              Go To Suppliers
              <FaArrowRight />
            </a>
          </div>
        </div>
        <div className='sm:flex hidden sm:flex-wrap sm:gap-3 sm:items-center sm:justify-center'>
          {suppliers && suppliers.map(supplier => (
            <SupplierCard key={supplier._id} supplier={supplier} />
          ))}
        </div>
      </div>
      <div className='w-[100%] bg-orange-400 p-10'>
        <div className='w-full'>
          <h2 className='text-white text-2xl text-center font-bold mb-2'>Access our services from your mobile device.</h2>
          <p className='text-white text-center text-xl font-semibold mb-5'>Mobile App Available</p>
          <div className='flex w-full items-center justify-center gap-4'>
            <a href="https://play.google.com/store/apps/details?id=com.codemunity.resellersprint">
              <img src={PlayImg} alt="" />
            </a>
            <a href="/">
              <img src={AppImg} alt="" />
            </a>
          </div>
        </div>
      </div>
      <Footer />
      <div className='w-[100%] px-10 text-black flex items-center justify-between'>
        <p className='text-sm'> <span>&copy;</span> Reseller Sprint. All rights reserved.</p>
        <div className='flex gap-2 items-center'>
          <a href="/privacy" className='underline text-sm text-orange-600'>Terms & conditions</a>
          <a href="/privacy" className='underline text-sm text-orange-600'>Privacy Policy</a>
        </div>
        <div className='flex items-center gap-3'>
          <FaFacebook size={18} color='#4267B2' />
          <FaInstagram size={18} color='#cd486b' />
          <FaTwitter size={18} color='#1DA1F2' />
        </div>
      </div>
    </div>
  )
}

export default HomePage