import axios from 'axios';
import React, { useState } from 'react'
import { server } from '../server';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../middleware/AuthContext';
import MainNav from '../components/MainNav';

const ResellerEditPassword = () => {
    const { id } = useParams()
    const [formData, setFormData] = useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
    });
    const [auth, setAuth] = useAuth();
    const navigate = useNavigate()

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // const handleLogout = () => {
    //     setAuth({
    //         ...auth,
    //         reseller: null,
    //         token: "",
    //     });
    //     localStorage.removeItem("auth");
    //     toast.success("Logout Successfully");
    //     navigate('/')
    // };
    const handleLogoutWithDelay = () => {
        // Set delay time in milliseconds (e.g., 3000 for 3 seconds)
        const delayTime = 3000; // Adjust as needed

        // Show a message to the user that they will be logged out
        toast.info("You will be logged out in a few seconds...");

        // Delay before performing logout
        setTimeout(() => {
            setAuth({
                ...auth,
                reseller: null,
                token: "",
            });
            localStorage.removeItem("auth");
            toast.success("Logout Successfully");
            navigate('/login')
        }, delayTime);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Send password change request to the server
            await axios.put(`${server}/resellers/change-password/${id}`, formData);
            toast.success('Password updated successfully');
            setFormData({
                oldPassword: '',
                newPassword: '',
                confirmPassword: '',
            });
            handleLogoutWithDelay();
        } catch (error) {
            toast.error('Failed to update password');
        }
    };
    return (

        <div>
            <MainNav />
            <div className='sm:h-[150px] h-[100px] top-section bg-red-500 mb-3'>
                <div className='top-overlay px-16' style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "end" }}>
                    <h1 className='sm:text-6xl text-3xl text-center text-orange-600 font-bold mb-5 counter-text'>My Profile</h1>
                </div>
            </div>
            <div className='sm:p-[10px] p-[10px] w-full h-[70vh] flex items-center justify-center bg-[#f8f9fc]'>
                <div className=' w-[450px] items-center justify-center sm:p-[30px]'>
                    <div className='bg-white sm:p-3 p-1'>
                        <form onSubmit={handleSubmit} class="max-w-sm mx-auto">
                            <div class="mb-5">
                                <label for="name" class="block mb-2 text-sm font-medium text-gray-900">Old Password</label>
                                <input type="password" id="oldPassword" name='oldPassword' value={formData.oldPassword} onChange={handleChange} class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                            </div>
                            <div class="mb-5">
                                <label for="name" class="block mb-2 text-sm font-medium text-gray-900">New Password</label>
                                <input type="password" id="newPassword" name='newPassword' value={formData.newPassword} onChange={handleChange} class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                            </div>
                            <div class="mb-5">
                                <label for="name" class="block mb-2 text-sm font-medium text-gray-900">Confirm New Password</label>
                                <input type="password" id="confirmPassword" name='confirmPassword' value={formData.confirmPassword} onChange={handleChange} class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                            </div>

                            <button type="submit" class="text-white bg-orange-700 hover:bg-orange-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Edit Password</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResellerEditPassword