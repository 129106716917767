import React, { useEffect, useState } from 'react'
import MainNav from '../components/MainNav'
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa'
import Footer from '../components/Footer'
import logo from '../assets/GooglePlayStore.png'
import { FaEye, FaLocationDot, FaPhone } from 'react-icons/fa6'
import { TiContacts } from "react-icons/ti";
import { RiCoupon3Line } from 'react-icons/ri'
import { CiCircleQuestion } from 'react-icons/ci'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { server } from '../server'
import { CSVLink } from 'react-csv';
import { FaFileCsv } from 'react-icons/fa';
import BreadCrumbs from '../components/BreadCrumbs'
import ProductReview from '../components/ProductReview'

const SupplierPage = () => {
    const [products, setProducts] = useState([]);
    const [ads, setAds] = useState([]);
    const [supplier, setSupplier] = useState([]);
    const [categories, setCategories] = useState([]);
    const [brands, setBrands] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedBrand, setSelectedBrand] = useState('');
    const { id } = useParams();
    const [csvData, setCsvData] = useState([]);
    const [selectedCurrency, setSelectedCurrency] = useState('$');
    const [searchQuery, setSearchQuery] = useState('');
    const [modalOpen, setModalOpen] = useState(false)
    const [selectedProduct, setSelectedProduct] = useState(null)

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const toggleModal = (filteredProduct) => {
        setModalOpen(!modalOpen);
        setSelectedProduct(filteredProduct)
    };

    useEffect(() => {
        const fetchSuppliers = async () => {
            try {
                const response = await axios.get(`${server}/products/get-products-supplier/${id}`);
                setProducts(response.data.products);

                const adResponse = await axios.get(`${server}/ads/get-ads-supplier/${id}`);
                setAds(adResponse.data.ads);

                const suppliersResponse = await axios.get(`${server}/suppliers/get-supplier/${id}`);
                setSupplier(suppliersResponse.data.supplier);

                const categoriesResponse = await axios.get(`${server}/categories`);
                setCategories(categoriesResponse.data);

                const brandsResponse = await axios.get(`${server}/brands`);
                setBrands(brandsResponse.data);
            } catch (error) {

            }
        };
        fetchSuppliers();
    }, [id]);
    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    const handleBrandChange = (event) => {
        setSelectedBrand(event.target.value);
    };

    const handleCurrencyChange = (event) => {
        setSelectedCurrency(event.target.value);
    };

    const filteredProducts = products.filter(product => {
        return (
            (selectedCategory === '' || product.category === selectedCategory) &&
            (selectedBrand === '' || product.brand === selectedBrand) &&
            (product.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                product.sku.toLowerCase().includes(searchQuery.toLowerCase()) ||
                product.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
                product.category.toLowerCase().includes(searchQuery.toLowerCase()) ||
                product.brand.toLowerCase().includes(searchQuery.toLowerCase()))
        )
    });

    const handleExport = () => {
        // Format data for CSV export
        const data = filteredProducts.map(product => ({
            ID: product.sku,
            Name: product.name,
            Price: `$${product.price}`,
            Category: product.category,
            Brand: product.brand,
            Warranty: product.warranty, // Assuming this is a static value
            Status: product.status, // Assuming this is a static value
        }));
        // Update CSV data
        setCsvData(data);
    };

    const breadcrumbs = [{ name: 'Home', path: '/home' }, { name: 'Suppliers', path: '/suppliers' }, { name: supplier.companyName, path: `/supplier/${id}` }]; // Define breadcrumbs
    return (
        <div>
            <MainNav />
            <div className='sm:h-[150px] h-[100px] top-section bg-red-500 mb-3'>
                <div className='top-overlay px-16' style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "end" }}>
                    <h1 className='sm:text-6xl text-3xl text-center text-orange-600 font-bold mb-5 counter-text'>{supplier.companyName}</h1>
                </div>
            </div>
            <div>
                <div>
                    <BreadCrumbs items={breadcrumbs} />
                </div>

                <div className='sm:flex block w-full sm:py-[40px] sm:px-[40px] py-[20px] px-[20px] justify-between'>
                    <div className='sm:w-[30%] w-[100%] bg-white shadow-md rounded-md p-4 h-fit'>
                        <div className='flex gap-4 border-b-2 pb-3'>
                            <div className='w-[70px] h-[70px] rounded-full border-blue-600 bg-white border flex items-center justify-center'>
                                <img src={logo} alt="" className='p-1 w-[90%] h-[90%] rounded-full bg-slate-300' />
                            </div>
                            <div>
                                <p className='text-gray-400 text-sm'>Since 2023</p>
                                <h1 className='text-lg text-gray-900 font-medium'>{supplier.companyName}</h1>
                                <p className='text-gray-500'>{supplier.companyEmail}</p>
                            </div>
                        </div>
                        <div className='flex justify-evenly items-center py-5 border-b-2 border-dashed'>
                            <div>
                                <p className='text-gray-500 text-center'>{products?.length}</p>
                                <h1 className='text-center'>Products</h1>
                            </div>
                            <div>
                                <p className='text-gray-500 text-center'>{supplier.dollarExchangeRate}</p>
                                <h1 className='text-center'>Exchange Rate</h1>
                            </div>
                            <div>
                                <p className='text-gray-500 text-center'>{ads?.length}</p>
                                <h1 className='text-center'>Ads & Events</h1>
                            </div>
                        </div>
                        <div className='py-5'>
                            <div className='flex items-center gap-3 mb-3'>
                                <FaLocationDot />
                                <p>{supplier.address}</p>
                            </div>
                            <div className='flex items-center gap-3'>
                                <FaPhone />
                                <p>{supplier.phoneNumber}</p>
                            </div>
                        </div>
                        <div className='flex items-center justify-evenly'>
                            <a href={`mailto:${supplier.email}`} className='w-[40%] h-[80px] text-white bg-blue-300 rounded-md' style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                <TiContacts size={35} />
                                Email
                            </a>
                            <a href={`/supplier/ads/${supplier._id}`} className='w-[40%] h-[80px] text-white rounded-md bg-orange-200' style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                <RiCoupon3Line size={35} />
                                Ads & Events
                            </a>
                        </div>
                    </div>
                    <div className='sm:w-[68%] w-[100%]'>
                        <div className=''>
                            <div className='sm:grid sm:grid-cols-2 w-full px-[30px] py-[10px] gap-3 bg-[#fff] mb-4'>
                                <div>
                                    <h2 className='text-gray-400 mb-2'>Filter by category</h2>
                                    <select name="" id="" className='rounded-md w-full h-[40px] border outline-none border-gray-400' onChange={handleCategoryChange}>
                                        <option value="">All</option>
                                        {categories.map(category => (
                                            <option key={category._id} value={category.name}>{category.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div>
                                    <h2 className='text-gray-400 mb-2'>Filter by brand</h2>
                                    <select name="" id="" className='rounded-md w-full h-[40px] border outline-none border-gray-400' onChange={handleBrandChange}>
                                        <option value="">All</option>
                                        {brands.map(brand => (
                                            <option key={brand._id} value={brand.name}>{brand.name}</option>
                                        ))}
                                    </select>
                                </div>

                            </div>
                        </div>
                        {/* <div className='h-[500px] overflow-y-scroll'> */}
                        <table className='shadow w-full overflow-scroll h-[500px] overflow-y-scroll bg-gray-50 block p-8'>
                            <div className='flex justify-between sm:gap-0 gap-4 mb-4 sticky top-0 bg-white'>
                                <div className='flex gap-2 items-center justify-center'>
                                    <p>Currency</p>
                                    <select className="rounded-sm w-[70px] h-[30px] border outline-none border-gray-400" onChange={handleCurrencyChange} value={selectedCurrency}>
                                        <option value="$">$</option>
                                        <option value="KES">KES</option>
                                    </select>
                                </div>

                                <div className='flex gap-5 items-center justify-center'>
                                    <form class="w-[200px] h-[40px]">
                                        <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only">Search</label>
                                        <div class="relative">
                                            <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                                <svg class="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                                </svg>
                                            </div>
                                            <input type="search" onChange={handleSearchChange} id="default-search" class="block w-[200px] h-[40px] p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-orange-500 focus:border-orange-500 outline-none" placeholder="Search Here..." required />
                                        </div>
                                    </form>

                                    <CSVLink
                                        data={csvData}
                                        filename={`${supplier.companyName} products.csv`}
                                        className="bg-blue-500 px-2 p-2 text-white flex gap-1 rounded-md items-center"
                                        target="_blank"
                                        data-twe-toggle="tooltip"
                                        title="Export as csv"
                                        onClick={handleExport}
                                    >
                                        Export
                                        <FaFileCsv />
                                    </CSVLink>
                                </div>
                            </div>
                            <thead className="text-xs text-gray-700 uppercase bg-[#fff] sticky top-[40px]">
                                <tr className='border-b-2'>
                                    <th scope="col" className="p-4">
                                        <div className="flex items-center">
                                            <input id="checkbox-all-search" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            <label for="checkbox-all-search" className="sr-only">checkbox</label>
                                        </div>
                                    </th>
                                    <th scope="col" className="px-4 py-3">
                                        SKU
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Name
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Price
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Category
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Brand
                                    </th>
                                    <th scope="col" className="px-4 py-3">
                                        Warranty(M)
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Status
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredProducts && filteredProducts.map(filteredProduct => (
                                    <tr className="bg-white border-b-2 hover:bg-gray-50" key={filteredProduct._id} >
                                        <td className="w-4 p-4">
                                            <div className="flex items-center">
                                                <input id="checkbox-table-search-1" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                <label for="checkbox-table-search-1" className="sr-only">checkbox</label>
                                            </div>
                                        </td>
                                        <td className='w-4 p-4'>
                                            <span>{filteredProduct.sku}</span>
                                        </td>
                                        <th scope="row" className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap">
                                            <div className="ps-3">
                                                <div data-twe-toggle="tooltip" title={filteredProduct.name} className="text-base font-semibold">{filteredProduct.name.length > 30 ? `${filteredProduct.name.slice(0, 30)}...` : filteredProduct.name}</div>
                                                <div className="font-normal text-start text-gray-500">{filteredProduct.category}</div>
                                            </div>
                                        </th>
                                        <td className="px-4 py-4">
                                            <span>
                                                {selectedCurrency === '$' ? `$${filteredProduct.price}` : `KES ${filteredProduct.price * supplier.dollarExchangeRate}`}
                                            </span>
                                        </td>
                                        <td className="px-4 py-4">
                                            <span>{filteredProduct.category}</span>
                                        </td>
                                        <td className="px-4 py-4">
                                            <span>{filteredProduct.brand}</span>
                                        </td>
                                        <td className="px-4 py-4">
                                            <span>{filteredProduct.warranty}</span>
                                        </td>
                                        <td className="px-6 py-4">
                                            {filteredProduct.status === 'available' && (
                                                <div className="flex items-center gap-1">
                                                    <div className="bg-green-600 w-2 h-2 rounded-full"></div>
                                                    <span className="text-green-600">Available</span>
                                                    <CiCircleQuestion className="cursor-pointer" data-twe-toggle="tooltip" title="Large quantity" />
                                                </div>
                                            )}
                                            {filteredProduct.status === 'limited' && (
                                                <div className="flex items-center gap-1">
                                                    <div className="bg-orange-500 w-2 h-2 rounded-full"></div>
                                                    <span className="text-orange-500">Limited</span>
                                                    <CiCircleQuestion className="cursor-pointer" data-twe-toggle="tooltip" title="Limited quantity" />
                                                </div>
                                            )}
                                            {filteredProduct.status === 'unavailable' && (
                                                <div className="flex items-center gap-1">
                                                    <div className="bg-red-600 w-2 h-2 rounded-full"></div>
                                                    <span className="text-red-600">Unavailable</span>
                                                    <CiCircleQuestion className="cursor-pointer" data-twe-toggle="tooltip" title="Not available" />
                                                </div>
                                            )}
                                        </td>
                                        <td className="px-6 py-4">
                                            <div className='flex gap-2'>
                                                <button onClick={() => toggleModal(filteredProduct)} data-twe-toggle="tooltip" title="View Product Details">
                                                    <FaEye size={20} color="grey" />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                        {modalOpen && (
                            <div tabIndex="-1" className="fixed top-[150px] sm:left-[35%] z-50 justify-center items-center w-full">
                                <div className="relative p-4 w-full max-w-md max-h-full">
                                    {/* Render the ResellerView component and pass the selected reseller data */}
                                    <ProductReview product={selectedProduct} closeModal={toggleModal} />
                                    {/* Close button or any other controls to close the modal */}
                                    <button onClick={() => setModalOpen(false)}>Close Modal</button>
                                </div>
                            </div>
                        )}

                    </div>
                </div>

                <Footer />
                <div className='w-[100%] px-10 text-black hidden sm:flex items-center justify-between'>
                    <p className='text-sm'> <span>&copy;</span> Reseller Sprint. All rights reserved.</p>
                    <div className='flex gap-2 items-center'>
                        <a href="/privacy" className='underline text-sm text-orange-600'>Terms & conditions</a>
                        <a href="/privacy" className='underline text-sm text-orange-600'>Privacy Policy</a>
                    </div>
                    <div className='flex items-center gap-3'>
                        <FaFacebook size={18} color='#4267B2' />
                        <FaInstagram size={18} color='#cd486b' />
                        <FaTwitter size={18} color='#1DA1F2' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SupplierPage