import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { server } from '../server';
import { FaEye, FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa6';
import { CiCircleQuestion, CiExport } from 'react-icons/ci';
import axios from 'axios';
import Footer from '../components/Footer';
import PlayImg from '../assets/play.png'
import AppImg from '../assets/appstore.png'
import MainNav from '../components/MainNav';

const CategoryResults = () => {
    const [products, setProducts] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const searchQuery = queryParams.get('category');
    const [suppliers, setSuppliers] = useState([]);
    const [categories, setCategories] = useState([]);
    const [brands, setBrands] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedBrand, setSelectedBrand] = useState('');
    const [selectedSupplier, setSelectedSupplier] = useState('');

  useEffect(() => {
    // Mock fetch products from the database (replace with actual API call if needed)
    const fetchProducts = async () => {
      // Simulate fetching products from a database
      const response = await fetch(`${server}/products/get-products`);
      const data = await response.json();
      setProducts(data);

      const suppliersResponse = await axios.get(`${server}/suppliers/get-suppliers`);
        setSuppliers(suppliersResponse.data);

        const categoriesResponse = await axios.get(`${server}/categories`);
        setCategories(categoriesResponse.data);

        const brandsResponse = await axios.get(`${server}/brands`);
        setBrands(brandsResponse.data);
    };
    
    fetchProducts();
  }, []);

  useEffect(() => {
    // Filter products based on the search query
    const filteredProducts = products.filter((product) =>
      product.category.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setSearchResults(filteredProducts);
  }, [products, searchQuery]);

  const handleBrandChange = (event) => {
    setSelectedBrand(event.target.value);
  };

  const handleSupplierChange = (event) => {
    setSelectedSupplier(event.target.value);
  };

  const finalProducts = searchResults.filter(product => {
    return (
      (selectedCategory === '' || product.category === selectedCategory) &&
      (selectedBrand === '' || product.brand === selectedBrand) &&
      (selectedSupplier === '' || product.supplier === selectedSupplier)
    );
  });

  return (
    <div>  
      <MainNav />
      <div className='sm:h-[150px] h-[100px] top-section bg-red-500 mb-3'>
        <div className='top-overlay px-16' style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "end"}}>
          <h1 className='sm:text-6xl text-3xl text-center text-orange-600 font-bold mb-5 counter-text'>Search Query: {searchQuery}</h1>
        </div>
      </div>
      <div className='p-[40px]'>
        <div className=''>
          <div className='sm:grid sm:grid-cols-2 w-full sm:px-[30px] py-[10px] gap-3 bg-[#fff] mb-4'> 
            <div>
                <h2 className='text-gray-400 mb-2'>Filter by brand</h2>
                <select name="" id="" className='rounded-md w-full h-[40px] border outline-none border-gray-400' onChange={handleBrandChange}>
                    <option value="">All</option>
                    {brands.map(brand => (
                  <option key={brand._id} value={brand.name}>{brand.name}</option>
                ))}
                </select>
            </div>
            <div>
                <h2 className='text-gray-400 mb-2'>Filter by supplier</h2>
                <select name="" id="" className='rounded-md w-full h-[40px] border outline-none border-gray-400' onChange={handleSupplierChange}>
                    <option value="">All</option>
                    {suppliers.map(supplier => (
                  <option key={supplier._id} value={supplier._id}>{supplier.companyName}</option>
                ))}
                </select>
            </div>
          </div>
        </div>
        <div className='flex flex-wrap gap-3 items-center justify-center'>
        <table className='shadow bg-slate-100 block p-8 overflow-scroll'>
              {/* <div className='flex justify-between mb-4'> 
                <div className='flex gap-5 items-center justify-center'>
                  <form class="w-[200px] h-[40px]">   
                    <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only">Search</label>
                    <div class="relative">
                        <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                            <svg class="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                            </svg>
                        </div>
                        <input type="search" id="default-search" class="block w-[200px] h-[40px] p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-orange-500 focus:border-orange-500 outline-none" placeholder="Search Here..." required /> 
                    </div>   
                  </form>
                  <CiExport size={25} color='green' className='cursor-pointer' data-twe-toggle="tooltip" title="Export as csv" />
                </div>
              </div> */}
              <thead className="text-xs text-gray-700 uppercase bg-[#fff]">
                        <tr className='border-b-2'>
                            <th scope="col" className="p-4">
                                <div className="flex items-center">
                                    <input id="checkbox-all-search" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                    <label for="checkbox-all-search" className="sr-only">checkbox</label>
                                </div>
                            </th>
                            <th scope="col" className="px-4 py-3">
                                ID
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Name
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Price
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Category
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Brand
                            </th>
                            <th scope="col" className="px-4 py-3">
                                Warranty(M)
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Supplier
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {finalProducts && finalProducts.map(product => (
                            <tr className="bg-white border-b-2 hover:bg-gray-50" key={product._id} >
                            <td className="w-4 p-4">
                                <div className="flex items-center">
                                    <input id="checkbox-table-search-1" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                    <label for="checkbox-table-search-1" className="sr-only">checkbox</label>
                                </div>
                            </td>
                            <td className='w-4 p-4'>
                                <span>{product.sku}</span>
                            </td>
                            <th scope="row" className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap">
                                <div className="ps-3">
                                    <div className="text-base font-semibold">{product.name}</div>
                                    <div className="font-normal text-gray-500">{product.category}</div>
                                </div>  
                            </th>
                            <td className="px-4 py-4">
                                <span>${product.price}</span>
                            </td>
                            <td className="px-4 py-4">
                                <span>{product.category}</span>
                            </td>
                            <td className="px-4 py-4">
                                <span>{product.brand}</span>
                            </td>
                            <td className="px-4 py-4">
                                <span>{product.warranty}</span>
                            </td>
                            <td className="px-6 py-4">
                                <a href={`/supplier/preview/${product.supplier}`} className='text-blue-700 underline'>{suppliers.find(supplier => supplier._id === product.supplier)?.companyName}</a>
                            </td>
                            <td className="px-6 py-4">
                                <div className="flex items-center gap-1">
                                    <div class="bg-green-600 w-[12px] h-[12px] rounded-full"></div>
                                    <span>Available</span>
                                    <CiCircleQuestion className='cursor-pointer' data-twe-toggle="tooltip" title="Large quantity" />
                                </div>
                            </td>
                        </tr>
                        ))}
                    </tbody>
            </table>
        </div>
        </div>
        <div className='w-[100%] bg-orange-400 p-10'>
          <div className='w-full'>
            <h2 className='text-white text-2xl text-center font-bold mb-2'>Access our services from your mobile device.</h2>
            <p className='text-white text-center text-xl font-semibold mb-5'>Mobile App Available</p>
            <div className='flex w-full items-center justify-center gap-4'>
              <a href="https://play.google.com/store/apps/details?id=com.codemunity.resellersprint">
                <img src={PlayImg} alt="" />
              </a>
              <a href="/">
                <img src={AppImg} alt="" />
              </a>
            </div>
          </div>
      </div> 
      <Footer />
        <div className='w-[100%] px-10 text-black flex items-center justify-between'>
          <p className='text-sm'> <span>&copy;</span> Reseller Sprint. All rights reserved.</p>
          <div className='flex gap-2 items-center'>
            <a href="/privacy" className='underline text-sm text-orange-600'>Terms & conditions</a>
            <a href="/privacy" className='underline text-sm text-orange-600'>Privacy Policy</a>
          </div>
          <div className='flex items-center gap-3'>
            <FaFacebook size={18} color='#4267B2'/>
            <FaInstagram size={18} color='#cd486b' />
            <FaTwitter size={18} color='#1DA1F2' />
            </div>
        </div>
    </div>
  )
}

export default CategoryResults