import React, { useEffect, useState } from 'react'
import logo from '../assets/ResellerSprint logo.png'
import axios from 'axios';
import { useAuth } from '../middleware/AuthContext';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { server } from '../server';
import { RiMenu2Line } from "react-icons/ri";
import { RxCross2 } from "react-icons/rx";

const MainNav = () => {
  const [open, setOpen] = useState(false);
  const [navOpen, setNavOpen] = useState(false);
  const [auth, setAuth] = useAuth();
  const navigate = useNavigate()
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoriesResponse = await axios.get(`${server}/categories`);
        setCategories(categoriesResponse.data);
      } catch (error) {

      }
    };
    fetchCategories();
  }, []);

  const handleLogout = () => {
    setAuth({
      ...auth,
      reseller: null,
      token: "",
    });
    localStorage.removeItem("auth");
    toast.success("Logout Successfully");
    navigate('/')
  };

  const handleCategoryChange = (e) => {
    const selectedCategory = e.target.value;
    if (selectedCategory) {
      navigate(`/category-products?category=${selectedCategory}`);
    } else {
      navigate('/products');
    }
  };


  const toggleDropdown = () => {
    setOpen(!open);
  };
  return (
    <div
      // className='flex justify-between bg-white sm:px-10 px-2 h-[70px] items-center border border-b-2' style={{position: "sticky", top: 0}}
      // className="shadow-md w-full sm:flex sm:items-center sm:justify-between h-[70px] bg-white sm:absolute sticky top-0 left-0"
      className='navbar'
    >
      <div className=" h-70px sm:flex sm:items-center sm:justify-between w-full bg-transparent py-4 md:px-10 px-7">
        <div className='flex items-center justify-center gap-2'>
          <img src={logo} alt="" className='w-[150px] h-[50px] object-contain' />
          <div className="sm:block hidden">
            <form action="">
              <select onChange={handleCategoryChange} name="" id="" className='border border-grey-600 rounded-lg p-2 shadow-sm text-orange-600'>
                <option value="">Categories</option>
                {categories && categories.map((category) => (
                  <option key={category._id} value={category.name}>{category.name}</option>
                ))}
              </select>
            </form>
          </div>
        </div>
        <div>
          <div className="sm:hidden justify-end items-center flex">
            <div onClick={() => setNavOpen(!navOpen)} className='absolute right-8 top-6 cursor-pointer md:hidden w-7 h-7'>
              {navOpen ? <RxCross2 color='black' size={30} /> : <RiMenu2Line color='black' size={30} />}
            </div>
          </div>
          <ul
            // className={openNav ? 'block h-[100vh] fixed right-0 bg-white shadow-md px-2 pt-2 pb-4 sm:flex sm:p-0' : 'hidden'}
            className={`sm:flex sm:items-center sm:text-black sm:gap-5 sm:pb-0 pb-12 absolute sm:static bg-white sm:bg-transparent sm:z-auto z-[-1] left-0 text-end w-full sm:w-auto sm:pl-0 sm:pr-0 pl-9 pr-2 transition-all duration-500 ease-in ${navOpen ? 'top-18' : 'top-[-490px]'}`}
          >
            <li className='nav-item sm:mb-0 mb-10 text-xl sm:text-[15px] hover:text-orange-600'>
              <a href="/home">Home</a>
            </li>
            <li className='nav-item sm:mb-0 mb-10 text-xl sm:text-[15px] hover:text-orange-600'>
              <a href="/suppliers">Suppliers</a>
            </li>
            <li className='nav-item sm:mb-0 mb-10 text-xl sm:text-[15px] hover:text-orange-600'>
              <a href="/products">Products</a>
            </li>
            <li className='nav-item sm:mb-0 mb-10 text-xl sm:text-[15px] hover:text-orange-600'>
              <a href="/partners">Partnerships</a>
            </li>
            <li className='nav-item sm:mb-0 mb-10 text-xl sm:text-[15px] hover:text-orange-600'>
              <a href="/privacy">Privacy Policy</a>
            </li>
            <li className='nav-item sm:mb-0 mb-10 text-xl sm:text-[15px] hover:text-orange-600 mr-5'>
              <a href="/faq">FAQ</a>
            </li>
            {!auth?.reseller ? (
              <div className='flex items-center justify-between'>
                <div className='flex items-center text-[15px] gap-2 mr-3'>
                  <a href="/login" className='text-black border border-orange-600 sm:mb-0 mb-3 rounded-full p-1 px-2'>Sign In</a>
                  <a href="/signup" className='px-2 bg-orange-600 p-1 rounded-full text-white'>Create An Account</a>
                </div>
              </div>
            ) : (
              <>
              <li className='nav-item sm:mb-0 mb-5 text-xl sm:hidden block sm:text-[15px] hover:text-orange-600 mr-5'>
                <a href={`/account/${auth?.reseller?._id}`}>My Account</a>
              </li>
              <div className="bg-white shadow-md w-50 sm:flex hidden justify-center items-center">
                <div onClick={toggleDropdown} className={`relative border-b-4 border-transparent p-2 ${open ? 'border-indigo-700 transform transition duration-300' : ''}`}
                  style={{ transitionProperty: 'border-color, transform' }}
                >
                  <div className="flex justify-center items-center space-x-3 cursor-pointer h-[30px] px-2">
                    <div className="w-[30px] h-[30px] rounded-full overflow-hidden border-2 border-gray-900">
                      <img src="https://images.unsplash.com/photo-1610397095767-84a5b4736cbd?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80" alt="" className="w-full h-full object-cover" />
                    </div>
                    <div className="font-semibold text-gray-900 text-[12px]">
                      <div className="cursor-pointer">{auth?.reseller?.firstName} {auth?.reseller?.lastName}</div>
                      <div className='text-gray-500'>{auth?.reseller?.roles}</div>
                    </div>
                  </div>
                  {open && (
                    <div className="absolute w-[150px] px-5 py-3 bg-white rounded-lg shadow border mt-5 z-10">
                      <ul className="space-y-3">
                        <li className="font-medium">
                          <a href={`/account/${auth?.reseller?._id}`} className="flex items-center transform transition-colors duration-200 border-r-4 border-transparent hover:border-indigo-700">
                            <div className="mr-3">
                              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path></svg>
                            </div>
                            Account
                          </a>
                        </li>
                        <hr className="dark:border-gray-700" />
                        <li className="font-medium">
                          <button onClick={handleLogout} className="flex items-center transform transition-colors duration-200 border-r-4 border-transparent hover:border-red-600">
                            <div className="mr-3 text-red-600">
                              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path></svg>
                            </div>
                            Logout
                          </button>
                        </li>
                      </ul>
                    </div>
                    
                  )}
                </div>
              </div>
              </>
            )}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default MainNav