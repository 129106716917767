import React, { useState } from 'react'
import loginImage from '../../assets/supplier-removebg-preview.png'
import { toast } from 'react-toastify';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../middleware/AuthContext';
import { server } from '../../server';
import { FaSpinner } from 'react-icons/fa6';

const SupplierLogin = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [auth, setAuth] = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        try {
          const res = await axios.post(`${server}/suppliers/login`, {
            email,
            password,
          });
          if (res && res.data.success) {
            // toast.success(res.data && res.data.message);
            toast.success("Login Success")
            setAuth({
              ...auth,
              supplier: res.data.supplier,
              token: res.data.token,
            });
            localStorage.setItem("auth", JSON.stringify(res.data));
            // navigate(location.state || "/home");
            setTimeout(() => {
                navigate(location.state || "/supplier");
            }, 2000);
          } else {
            toast.error(res.data.message);
          }
        } catch (error) {
          if (error.response && error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
          }else {
            toast.error("An error occurred during login");
          }
        } finally {
          setLoading(false); // Set loading to false after login attempt is finished
        }
      };
  return (
    <div className='grid content-center justify-center h-[100vh]'>
        <div className='flex gap-2 shadow bg-slate-100'>
            <div className='sm:w-[60%] hidden p-4' id='login-form'>
                <h2 className='counter-text text-orange-500 text-4xl'>Supplier/Manufacturer Portal</h2>
                <img src={loginImage} alt="" />
                <h2>Reseller Sprint</h2>
                <p>"Business is business"</p>
            </div>
            <form className='sm:w-[40%] bg-orange-500 p-5 text-white' onSubmit={handleSubmit}>
                <div className='mb-5'>
                    <h1 className='text-xl font-medium text-center'>Welcome Back</h1>
                    <p className='text-center'>Sign in to your account</p>
                </div>
                <div>
                    <div className='w-full mb-3'>
                        <label>Email Address</label>
                        <input type="email" name='email' value={email} onChange={(e) => setEmail(e.target.value)} placeholder='' class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                    </div>
                    <div className='w-full mb-4'>
                        <label>Password</label>
                        <input type="password" name='password' value={password} onChange={(e) => setPassword(e.target.value)} placeholder='' class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                    </div>
                    <div className='w-full mb-3'>
                        <div className='sm:flex sm:justify-between sm:items-center block'>
                            <label>
                                <input type="checkbox" />
                                Remember me?
                            </label>
                            <a href="/supplier-forgot-password" className='text-white underline'>Forgot your password?</a>
                        </div>
                    </div>
                    <div className='w-full mb-2'>
                    {loading ? (
                      <button className='w-full bg-black p-2.5 flex items-center justify-center' disabled>
                        <FaSpinner className='animate-spin mr-2' /> Loading...
                      </button>
                    ) : (
                      <button type='submit' className='w-full bg-black rounded-md p-2.5'>LOGIN</button>
                    )}
                    </div>
                    <div className='sm:flex sm:justify-between'>
                        <p>Don't have an account? <a href="/supplier-register" className='underline text-blue-600'>Register</a></p>
                        <a href="/" className='text-blue-600 underline'>Back to Home</a>
                    </div>
                </div>
            </form>
        </div>
    </div>
  )
}

export default SupplierLogin