import React, { useEffect, useState } from 'react'
import AdminHeadbar from './components/AdminHeadbar'
import AdminSidebar from './components/AdminSidebar'
import logo from '../../assets/GooglePlayStore.png'
import { FaExternalLinkAlt, FaRegEdit } from 'react-icons/fa'
import { MdOutlineEmail } from 'react-icons/md'
import { CiLocationOn, CiPhone } from 'react-icons/ci'
import { FaCartFlatbed, FaDollarSign} from 'react-icons/fa6'
import { RiAdvertisementLine } from 'react-icons/ri'
import { useAuth } from '../../middleware/AuthContext'
import axios from 'axios'
import { server } from '../../server'
import AdminEditModal from './components/AdminEditModal'

const AdminAccount = () => {
    const [auth, setAuth] = useAuth();
    const [products, setProducts] = useState([])
    const [selectedAdmin, setSelectedAdmin] = useState(null);
    const [resellers, setResellers] = useState([])
    const [suppliers, setSuppliers] = useState([])
    const [isReviewModalOpen, setReviewModalOpen] = useState(false);
    const admin = auth.admin
  
    useEffect(() => {
        const fetchProducts = async () => { 
          try {
            const response = await axios.get(`${server}/products/get-products`);
            setProducts(response.data);

            const resellersResponse = await axios.get(`${server}/resellers/get-resellers`);
            setResellers(resellersResponse.data);

            const suppliersResponse = await axios.get(`${server}/suppliers/get-suppliers`);
            setSuppliers(suppliersResponse.data);
            
          } catch (error) {
            
          } 
        };
        fetchProducts();
      }, []);
      const toggleViewModal = (admin) => {
        setReviewModalOpen(!isReviewModalOpen);
        setSelectedAdmin(admin)
    }
  return (
    <div className='flex'>
        <div className='w-[20%]'>
        <AdminSidebar />
        </div>
        <main className='w-[80%]'>
        {/* <AdminHeadbar /> */}
        <AdminHeadbar />
        <div className=''>
            <div className='w-full bg-orange-500 relative h-[200px] mb-[50px]'>
                <div className='w-[100px] h-[100px] rounded-full  absolute -bottom-[50px] left-6 border-blue-600 bg-white border flex items-center justify-center'>
                    <img src={logo} alt="" className='p-1 w-[90%] h-[90%] rounded-full bg-slate-300' />
                </div>
            </div>
            <div className='px-5 py-2'>
                <div className='flex gap-2 items-center mb-5'>
                    <h1 className='text-lg font-medium'>{auth?.admin?.username}</h1>
                    <a href="/">
                    <FaExternalLinkAlt color='blue' />
                    </a>
                </div>
                <div className='grid grid-cols-3'>
                    <a href='mailto:cotek@gmail.com' className='border-r-2 flex gap-2 items-center p-3'>
                        <MdOutlineEmail size={25} />
                        <p className='text-[15px]'>
                        {auth?.admin?.email}
                        </p>
                    </a>
                    <div className='border-r-2 flex gap-2 items-center p-3'>
                        <CiLocationOn size={25} />
                        <p className='text-[15px]'>
                            1740 Bedford Street, Alabama, Michigan, 35203, USA
                        </p>
                    </div>
                    <div className='border-r-2 flex gap-2 items-center p-3'>
                        <CiPhone size={25} />
                        <p className='text-[15px] mr-5'>
                            +254 91448827
                        </p>
                        <a href={`/admin/edit-account/${auth?.admin?._id}`}  className='bg-green-600 w-[80px] flex items-center justify-center border-none rounded-xl text-white gap-1 py-0.5'>
                            <FaRegEdit />
                            Edit
                        </a>
                    </div>
                </div>
            </div>
            <div className='flex gap-[50px]  px-[40px] mb-8'>
                <div className=' bg-slate-100 rounded-sm shadow-md w-[40%] py-[20px] px-5'>
                    <div className='border-b-2 py-2 border-dashed flex justify-between'>
                        <div className=''>
                            <h2 className='font-medium mb-1'>Registered Since</h2>
                            <p>24th, January 2024</p>
                        </div>
                        <div>
                            <h2 className='font-medium mb-1'>Status</h2>
                            <div className='flex gap-1 items-center'>
                                <div className='w-[10px] h-[10px] rounded-full bg-green-600'></div>
                                Active
                            </div>
                        </div>
                    </div>
                    <div>
                        <h1 className='text-xl font-semibold'>Personal Information</h1>
                        <div className='grid grid-cols-2'>
                            <div className='border-r-2 p-4'>
                                <h2 className='font-medium mb-1'>UserName</h2>
                                <p>{auth?.admin?.username}</p>
                            </div>
                            <div className='p-4'>
                                <h2 className='font-medium mb-1'>Role</h2>
                                <p>{auth?.admin?.role}</p>
                            </div>
                            <div className='p-4'>
                                <h2 className='font-medium mb-1'>Email Address</h2>
                                <p>{auth?.admin?.email}</p>
                            </div>
                        </div>
                        <div className='w-[100%]'>
                            <a className="w-[100%] px-4 py-2 bg-orange-400 text-white" href={`/admin/change-password/${auth?.admin?._id}`}>Change Password</a>
                        </div>
                    </div>
                </div>
                <div className='w-[58%] bg-slate-100 rounded-sm shadow-md p-5'>
                    <div className='grid grid-cols-2 gap-4'>
                        <div className='flex border rounded-md shadow-sm bg-white justify-between p-4 items-center'>
                            <div>
                                <p className='text-xl font-semibold'>{products.length}</p>
                                <h1>Total <a href="/admin/products" className='text-blue-500 underline'>Products</a></h1>
                            </div>
                            <div className='w-[50px] h-[50px] bg-gray-300 rounded-full border border-blue-600 flex items-center justify-center'>
                                <FaCartFlatbed size={28} color='orange' />
                            </div>
                        </div>
                        <div className='flex border rounded-md shadow-sm bg-white justify-between p-4 items-center'>
                            <div>
                                <p className='text-xl font-semibold'>{resellers.length}</p>
                                <h1>Total <a href="/admin/resellers" className='text-blue-500 underline'>Resellers</a></h1>
                            </div>
                            <div className='w-[50px] h-[50px] bg-gray-300 rounded-full border border-green-600 flex items-center justify-center'>
                                <RiAdvertisementLine size={28} color='blue' />
                            </div>
                        </div>
                        <div className='flex border rounded-md shadow-sm bg-white justify-between p-4 items-center'>
                            <div>
                                <p className='text-xl font-semibold'>{suppliers.length}</p>
                                <h1>Total <a href="/admin/suppliers" className='text-blue-500 underline'>Suppliers</a></h1>
                            </div>
                            <div className='w-[50px] h-[50px] bg-gray-300 rounded-full border border--600 flex items-center justify-center'>
                                <FaDollarSign size={28} color='green' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </main>
        {isReviewModalOpen && (
                <div tabIndex="-1" className="fixed top-[200px] left-[40%] z-50 justify-center items-center w-full">
                    <div className="relative p-4 w-full max-w-md max-h-full">
                        {/* Render the ResellerView component and pass the selected reseller data */}
                        <AdminEditModal admin={selectedAdmin} closeModal={toggleViewModal} />
                    </div>
                </div>
            )}
  </div>
  )
}

export default AdminAccount
