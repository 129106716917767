import React, { useEffect, useState } from 'react'
import { FaRegEdit } from 'react-icons/fa'
import { FaPlus, FaTrash } from 'react-icons/fa6'
import AdminSidebar from './components/AdminSidebar'
import AdminHeadbar from './components/AdminHeadbar'
import profile from '../../assets/GooglePlayStore.png'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { server } from '../../server'
import { toast } from 'react-toastify'

const Searches = () => {
    const [searchQueries, setSearchQueries] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');


    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get(`${server}/search`);
            setSearchQueries(response.data.searchQueries);
        } catch (error) {
            console.error('Error fetching Brand data:', error);
        }
    };

    const filteredSearchQueries = searchQueries.filter((search) =>
        search.query.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };
  return (
    <div className='flex'>
    <div className='w-[20%]'>
      <AdminSidebar />
    </div>
    <main className='w-[80%]'>
    <AdminHeadbar />
      <div className='m-[30px] bg-[#f8f9fc]'>
      <div className='bg-[#fff] flex p-[20px] items-center mb-10'>
          <div className='border-l-4 border-orange-500 rounded-lg p-4'>
              <span className='text-2xl font-semibold'>Search Queries</span>
          </div>
          <div className='flex justify-between gap-[75px] w-[500px]'>   
              <form className="w-full">   
                  <label for="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only">Search</label>
                  <div className="relative">
                      <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                          <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                          </svg>
                      </div>
                      <input type="search" id="default-search" value={searchQuery} onChange={handleSearchChange} className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-orange-500 focus:border-orange-500" placeholder="Search by name..." required />
                      <button type="submit" className="text-white absolute end-2.5 bottom-2.5 bg-orange-700 hover:bg-orange-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2">Search</button>
                  </div>
              </form>
          </div>
      </div>
      <div className='bg-[#fff] h-[70vh]'>
          <div className="relative overflow-x-auto shadow-md sm:rounded-lg overflow-y-auto">    
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 overflow-y-auto">
                  <thead className="text-xs text-gray-700 uppercase bg-[#fff]">
                      <tr>
                          <th scope="col" className="p-4">
                              <div className="flex items-center">
                                  <input id="checkbox-all-search" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                  <label for="checkbox-all-search" className="sr-only">checkbox</label>
                              </div>
                          </th>
                          <th scope="col" className="px-6 py-3">
                              Query
                          </th>
                          
                          <th scope="col" className="px-6 py-3">
                              Date
                          </th>
                      </tr>
                  </thead>
                  <tbody>
                      
                      {filteredSearchQueries.length === 0 ? (
                        <tr>
                            <td colSpan="6" className="px-6 py-4 text-center text-gray-500">
                                No  queries found.
                            </td>
                        </tr>
                    ) : (
                        filteredSearchQueries && filteredSearchQueries.map((search, index) => (
                            <tr key={search._id} className="bg-white border-b hover:bg-gray-50">
                            <td className="w-4 p-4">
                                <div className="flex items-center">
                                    <input id="checkbox-table-search-1" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                    <label for="checkbox-table-search-1" className="sr-only">checkbox</label>
                                </div>
                            </td>
                            <th scope="row" className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap">
                                <img className="w-10 h-10 rounded-full" src={profile} alt="Jese images"/>
                                <div className="ps-3">
                                    <div className="text-base font-semibold">{search.query}</div>
                                    <div className="font-normal text-gray-500"></div>
                                </div>  
                            </th>
                            
                            <td className="px-6 py-4">
                                <div className="ps-3">
                                    <div className="text-base font-semibold">{search.timestamp}</div>   
                                </div>    
                            </td>     
                        </tr> 
                        ))
                    )}
                         
                  </tbody>
              </table>
          </div>
      </div>
      </div>
    </main>
  </div>
  )
}

export default Searches