import { BrowserRouter, Routes, Route} from 'react-router-dom';
import './App.css';
import Landing from './pages/Landing';
import Dashboard from './pages/Admin/Dashboard';
import { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import Admins from './pages/Admin/Admins';
import AddAdmin from './pages/Admin/AddAdmin';
import Resellers from './pages/Admin/Resellers';
import Suppliers from './pages/Admin/Suppliers';
import Products from './pages/Admin/Products';
import AddProduct from './pages/Admin/AddProduct';
import Categories from './pages/Admin/Categories';
import AddCategory from './pages/Admin/AddCategory';
import FAQ from './pages/Admin/FAQ';
import AddFAQ from './pages/Admin/AddFAQ';
import Terms from './pages/Admin/Terms';
import AddTerm from './pages/Admin/AddTerm';
import Maintenance from './pages/Admin/Maintenance';
import General from './pages/Admin/General';
import SEO from './pages/Admin/SEO';
import Info from './pages/Admin/Info';
import axios from 'axios';
import MaintPage from './components/MaintPage';
import Brands from './pages/Admin/Brands';
import AddBrand from './pages/Admin/AddBrand';
import HomePage from './pages/HomePage';
import SuppliersPage from './pages/SuppliersPage';
import ProductsPage from './pages/ProductsPage';
import PartnersPage from './pages/PartnersPage';
import AboutPage from './pages/AboutPage';
import PolicyPage from './pages/PolicyPage';
import FAQPage from './pages/FAQPage';
import SupplierSignUp from './pages/auth/SupplierSignUp';
import SupplierLogin from './pages/auth/SupplierLogin';
import SignUp from './pages/auth/SignUp';
import Login from './pages/auth/Login';
import SupplierDashboard from './pages/Supplier/SupplierDashboard';
import EditSupplier from './pages/Supplier/EditSupplier.jsx';
import SupplierAccountPage from './pages/Supplier/SupplierAccountPage';
import SupplierAddProduct from './pages/Supplier/SupplierAddProduct';
import SupplierAddAd from './pages/Supplier/SupplierAddAd';
import SupplierEditAd from './pages/Supplier/SupplierEditAd';
import SupplierProds from './pages/Supplier/SupplierProds';
import SupplierAds from './pages/Supplier/SupplierAds';
import PrivateRoute from './middleware/ProtectedRoute';
import SupplierRoute from './middleware/SupplierRoutes';
import ForgotPassword from './pages/auth/ForgotPassword';
import AdminLogin from './pages/Admin/AdminLogin';
import SupplierPage from './pages/SupplierPage';
import SupplierForgotPass from './pages/auth/SupplierForgotPass';
import PendingResellers from './pages/Admin/PendingResellers';
import ResellerProfile from './pages/ResellerProfile';
import PendingSuppliers from './pages/Admin/PendingSuppliers';
import AdminRoute from './middleware/AdminRoute';
import { server } from './server';
import SearchResults from './pages/SearchResults';
import CategoryResults from './pages/CategoryResults';
import AdminAccount from './pages/Admin/AdminAccount';
import EditAccount from './pages/Admin/EditAccount';
import ChangePassword from './pages/Admin/ChangePassword';
import BulkProductUpload from './pages/Supplier/BulkProductUpload';
import AddSupplier from './pages/Admin/AddSupplier.jsx';
import AddReseller from './pages/Admin/AddReseller.jsx';
import EditReseller from './pages/Admin/EditReseller.jsx';
import EditCategory from './pages/Admin/EditCategory.jsx';
import BrandResults from './pages/BrandResults.jsx';
import ChangePasswordSupplier from './pages/Supplier/ChangePasswordSupplier.jsx';
import SupplierEditProduct from './pages/Supplier/SupplierEditProduct.jsx';
import AdminEditSupplier from './pages/Admin/AdminEditSupplier.jsx';
import AdminEditProduct from './pages/Admin/AdminEditProduct.jsx';
import EditBrand from './pages/Admin/EditBrand.jsx';
import Searches from './pages/Admin/Searches.jsx';
import SupplierPreviewAds from './pages/SupplierPreviewAds.jsx';
import Ads from './pages/Admin/Ads.jsx';
import AdminAddAd from './pages/Admin/AdminAddAd.jsx';
import AdminEditAd from './pages/Admin/AdminEditAd.jsx';
import EditFaq from './pages/Admin/EditFaq.jsx';
import EditTerm from './pages/Admin/EditTerm.jsx';
import ResellerEditPassword from './pages/ResellerEditPassword.jsx';
import AddBulkProducts from './pages/Admin/AddBulkProducts.jsx';

axios.defaults.withCredentials = true;

function App() {
  const [maintenanceMode, setMaintenanceMode] = useState(null);
  // const {loggedIn} = useContext(AuthContext);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    fetchMaintenanceModeStatus(); // Fetch maintenance mode status when the component mounts
  }, []);

  const fetchMaintenanceModeStatus = async () => {
    try {
      // Fetch maintenance mode status from server
      const response = await axios.get(`${server}/maintenance`);  
      const maintenanceStatus = response.data[0].isActive; 
      setMaintenanceMode(maintenanceStatus);
    } catch (error) {
      console.error('Error fetching maintenance mode status:', error.message);
    }
  };

  return (
    <div>
      <BrowserRouter>
        <Routes>
          {maintenanceMode ? (
            <Route path='*' element={<MaintPage />} />
          ) : (
            <>
              <Route path="/" element={<Landing />} />
              <Route element={<PrivateRoute />}>
                <Route path='/home' element={<HomePage />} />
                <Route path='/products' element={<ProductsPage />} />
                <Route path='/suppliers' element={<SuppliersPage />} />
                <Route path='/account/:id' element={<ResellerProfile />} />
                <Route path="/search-results" element={<SearchResults />} />
                <Route path="/category-products" element={<CategoryResults />} />
                <Route path="/brand-products" element={<BrandResults />} />
                <Route path='/supplier/preview/:id' element={<SupplierPage/>} />
                <Route path='/supplier/ads/:id' element={<SupplierPreviewAds />} />
                <Route path='/edit-password/:id' element={<ResellerEditPassword />} />
              </Route>
              
              
              <Route path='/partners' element={<PartnersPage/>} />
              <Route path='/about' element={<AboutPage />} />
              <Route path='/privacy' element={<PolicyPage />} />
              <Route path='/faq' element={<FAQPage />} />
              <Route path='/supplier-register' element={<SupplierSignUp />} />
              <Route path='/supplier-login' element={<SupplierLogin />} />
              <Route path='/login' element={<Login />} />
              <Route path='/admin/login' element={<AdminLogin />} />
              <Route path='/forgot-password' element={<ForgotPassword />} />
              <Route path='/supplier-forgot-password' element={<SupplierForgotPass />} />
              <Route path='/signup' element={<SignUp />} />
              <Route element={<SupplierRoute />}>
              <Route
                path="/supplier/*"
                element={
                  // <Layout>
                  <Routes>
                    <Route path='/' element={<SupplierDashboard />} />
                    <Route path='account' element={<SupplierAccountPage />} />
                    <Route path='add-ad' element={<SupplierAddAd />} />
                    <Route path='edit-ad/:id' element={<SupplierEditAd />} />
                    <Route path='edit-product/:id' element={<SupplierEditProduct />} />
                    <Route path='products' element={<SupplierProds/>} />
                    <Route path='ads' element={<SupplierAds/>} />
                    <Route path='add-product' element={<SupplierAddProduct />} />
                    {/* <Route path='add-category' element={<AddCategory />} /> */}
                    <Route path='add-bulk-products' element={<BulkProductUpload />} />
                    <Route path='edit-account/:id' element={<EditSupplier />} />
                    <Route path='change-password/:id' element={<ChangePasswordSupplier />} />
                  </Routes>
                  // </Layout>
                }
              />
              </Route>
            </>
          )}
          <Route element={<AdminRoute />}>
            <Route
              path="/admin/*"
              element={
                // <Layout>
                <Routes>
                  <Route path='/' element={<Dashboard />} />
                  <Route path='admins' element={<Admins />} />
                  <Route path='add-admin' element={<AddAdmin />} />
                  <Route path='resellers' element={<Resellers />} />
                  <Route path='pending-resellers' element={<PendingResellers />} />
                  <Route path='pending-suppliers' element={<PendingSuppliers />} />
                  <Route path='suppliers' element={<Suppliers />} />
                  <Route path='products' element={<Products />} />
                  <Route path='categories' element={<Categories />} />
                  <Route path='brands' element={<Brands />} />
                  <Route path='ads' element={<Ads />} />
                  <Route path='faq' element={<FAQ />} />
                  <Route path='edit-faq/:id' element={<EditFaq />} />
                  <Route path='terms' element={<Terms />} />
                  <Route path='edit-term/:id' element={<EditTerm />} />
                  <Route path='searches' element={<Searches />} />
                  <Route path='add-product' element={<AddProduct />} />
                  <Route path='add-bulk-products' element={<AddBulkProducts />} />
                  <Route path='edit-product/:id' element={<AdminEditProduct />} />
                  <Route path='add-ad' element={<AdminAddAd />} />
                  <Route path='edit-ad/:id' element={<AdminEditAd />} />
                  <Route path='add-category' element={<AddCategory />} />
                  <Route path='edit-category/:id' element={<EditCategory />} />
                  <Route path='add-brand' element={<AddBrand />} />
                  <Route path='edit-brand/:id' element={<EditBrand />} />
                  <Route path='add-faq' element={<AddFAQ />} />
                  <Route path='add-supplier' element={<AddSupplier />} />
                  <Route path='edit-supplier/:id' element={<AdminEditSupplier />} />
                  <Route path='add-reseller' element={<AddReseller />} />
                  <Route path='edit-reseller/:id' element={<EditReseller />} />
                  <Route path='add-term' element={<AddTerm />} />
                  <Route path='maintenance' element={<Maintenance />} />
                  <Route path='general' element={<General />} />
                  <Route path='seo' element={<SEO />} />
                  <Route path='info' element={<Info />} />
                  <Route path='account' element={<AdminAccount />} />
                  <Route path='edit-account/:id' element={<EditAccount />} />
                  <Route path='change-password/:id' element={<ChangePassword />} />
                  
                  {/* <Route path='/admin/pending-vendors' element={<PendingVendors />} /> */}
                </Routes>
                // </Layout>
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
