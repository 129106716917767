import React, {useState, useEffect} from 'react'
import { server } from '../../../server';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom'

const AdminEditModal = () => {
    const { id } = useParams();
    const [admin, setAdmin] = useState(
        {   
            email: '', 
            username: '',
            
        }
    );

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setAdmin(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    useEffect(() => {
        const fetchAdmin = async () => { 
          try {
            const response = await axios.get(`${server}/admins/get-admin/${id}`);
            setAdmin(response.data.admin);
          } catch (error) {
            
          } 
        };
        fetchAdmin();
      }, [id]);

      const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Send updated data to the server
            await axios.put(`${server}/admins/update-admin/${id}`, admin);
            // Optionally, you can fetch the updated data again to reflect changes in the UI
            toast.success('Data updated successfully!');
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        } catch (error) {
            console.error('Error updating reseller:', error);
            toast.error(error);
        }
    };
  return (
    <div>
        <div className='p-5 w-[400px] rounded-md shadow-md bg-white'>
        <form class="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                  <div>
                      <label for="email" class="block mb-2 text-sm font-medium text-gray-900">Your email</label>
                      <input type="email" name="email" id="email" value={admin.email} onChange={handleInputChange} class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="admin@company.com" required=""/>
                  </div>
                  <div>
                      <label for="password" class="block mb-2 text-sm font-medium text-gray-900">Username</label>
                      <input type="text" name="username" value={admin.username} onChange={handleInputChange} id="username" placeholder="••••••••" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" required=""/>
                  </div>
                  <button type="submit" class="w-full text-white bg-orange-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Edit</button>
              </form>
        </div>
    </div>
  )
}

export default AdminEditModal