import React, { useEffect, useState } from 'react'
import MainNav from '../components/MainNav'
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa'
import Footer from '../components/Footer'
import logo from '../assets/GooglePlayStore.png'
import { FaLocationDot, FaPhone } from 'react-icons/fa6'
import { TiContacts } from "react-icons/ti";
import { MdOutlineShoppingBag } from "react-icons/md";
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { server } from '../server'
import BreadCrumbs from '../components/BreadCrumbs'
import AdCard from '../components/AdCard'
import { Add } from '@mui/icons-material'

const SupplierPreviewAds = () => {
    const [products, setProducts] = useState([]);
    const [ads, setAds] = useState([]);
    const [supplier, setSupplier] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        const fetchSuppliers = async () => {
            try {
                const response = await axios.get(`${server}/products/get-products-supplier/${id}`);
                setProducts(response.data.products);

                const adResponse = await axios.get(`${server}/ads/get-ads-supplier/${id}`);
                setAds(adResponse.data.ads);
                console.log(adResponse.data.ads[0])

                const suppliersResponse = await axios.get(`${server}/suppliers/get-supplier/${id}`);
                setSupplier(suppliersResponse.data.supplier);
            } catch (error) {

            }
        };
        fetchSuppliers();
    }, [id]);
    

    const breadcrumbs = [{ name: 'Home', path: '/home' }, { name: 'Suppliers', path: '/suppliers' }, { name: supplier.companyName, path: `/supplier/${id}` }]; // Define breadcrumbs
    return (
        <div>
            <MainNav />
            <div className='sm:h-[150px] h-[100px] top-section bg-red-500 mb-3'>
                <div className='top-overlay px-16' style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "end" }}>
                    <h1 className='sm:text-6xl text-3xl text-center text-orange-600 font-bold mb-5 counter-text'>{supplier.companyName}</h1>
                </div>
            </div>
            <div>
                <div>
                    <BreadCrumbs items={breadcrumbs} />
                </div>

                <div className='sm:flex block w-full sm:py-[40px] sm:px-[40px] py-[20px] px-[20px] justify-between'>
                    <div className='sm:w-[30%] w-[100%] bg-white shadow-md rounded-md p-4 h-fit'>
                        <div className='flex gap-4 border-b-2 pb-3'>
                            <div className='w-[70px] h-[70px] rounded-full border-blue-600 bg-white border flex items-center justify-center'>
                                <img src={logo} alt="" className='p-1 w-[90%] h-[90%] rounded-full bg-slate-300' />
                            </div>
                            <div>
                                <p className='text-gray-400 text-sm'>Since 2023</p>
                                <h1 className='text-lg text-gray-900 font-medium'>{supplier.companyName}</h1>
                                <p className='text-gray-500'>{supplier.companyEmail}</p>
                            </div>
                        </div>
                        <div className='flex justify-evenly items-center py-5 border-b-2 border-dashed'>
                            <div>
                                <p className='text-gray-500 text-center'>{products?.length}</p>
                                <h1 className='text-center'>Products</h1>
                            </div>
                            <div>
                                <p className='text-gray-500 text-center'>{supplier.dollarExchangeRate}</p>
                                <h1 className='text-center'>Exchange Rate</h1>
                            </div>
                            <div>
                                <p className='text-gray-500 text-center'>{ads?.length}</p>
                                <h1 className='text-center'>Ads & Events</h1>
                            </div>
                        </div>
                        <div className='py-5'>
                            <div className='flex items-center gap-3 mb-3'>
                                <FaLocationDot />
                                <p>{supplier.address}</p>
                            </div>
                            <div className='flex items-center gap-3'>
                                <FaPhone />
                                <p>{supplier.phoneNumber}</p>
                            </div>
                        </div>
                        <div className='flex items-center justify-evenly'>
                            <a href={`mailto:${supplier.email}`} className='w-[40%] h-[80px] text-white bg-blue-300 rounded-md' style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                <TiContacts size={35} />
                                Contact
                            </a>
                            <a href={`/supplier/preview/${supplier._id}`} className='w-[40%] h-[80px] text-white rounded-md bg-orange-200' style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                <MdOutlineShoppingBag size={35} />
                                Products
                            </a>
                        </div>
                    </div>
                    <div className='sm:w-[68%] w-[100%]'>
                        <div className='grid grid-cols-2 gap-4'>
                            {ads.length === 0 ? (
                                <h2>This Supplier has no Running Ads</h2>
                            ) : (
                            ads.map(ad => (
                                <AdCard key={ad._id} ad={ad}/>
                            ))
                            )}
                        </div>
                    </div>
                </div>
                <Footer />
                <div className='w-[100%] px-10 text-black hidden sm:flex items-center justify-between'>
                    <p className='text-sm'> <span>&copy;</span> Reseller Sprint. All rights reserved.</p>
                    <div className='flex gap-2 items-center'>
                        <a href="/privacy" className='underline text-sm text-orange-600'>Terms & conditions</a>
                        <a href="/privacy" className='underline text-sm text-orange-600'>Privacy Policy</a>
                    </div>
                    <div className='flex items-center gap-3'>
                        <FaFacebook size={18} color='#4267B2' />
                        <FaInstagram size={18} color='#cd486b' />
                        <FaTwitter size={18} color='#1DA1F2' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SupplierPreviewAds