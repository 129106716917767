import React, { useEffect, useState } from 'react'
import AdminSidebar from './components/AdminSidebar'
import AdminHeadbar from './components/AdminHeadbar'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { server } from '../../server'
import { toast } from 'react-toastify'

const EditAccount = () => {
    const {id} = useParams()
    const [admin, setAdmin] = useState(
        {   
            id: id,
            email: '',
            username: ''
        }
    );

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setAdmin(prevState => ({
            ...prevState,
            [name]: value
        }));
    };


    useEffect(() => {
        const fetchAdmin = async () => { 
          try {
            const response = await axios.get(`${server}/admins/get-admin/${id}`);
            setAdmin(response.data.admin);
          } catch (error) {
            
          } 
        };
        fetchAdmin();
      }, [id]);

      const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Send updated data to the server
            await axios.put(`${server}/admins/update-admin/${id}`, admin);
            // Optionally, you can fetch the updated data again to reflect changes in the UI
            toast.success('Data updated successfully!');
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        } catch (error) {
            console.error('Error updating reseller:', error);
            toast.error(error);
        }
    };

  return (
    <div className='flex'>
      <div className='w-[20%]'>
        <AdminSidebar />
      </div>
      <main className='w-[80%]'>
        <AdminHeadbar />
        <div className='p-[40px] bg-[#f8f9fc]'>
            <div className='border-b-2 border-dashed py-[30px] flex items-center'>
                <span className='font-medium text-xl'>Edit Account</span>
            </div>
            <div className='flex p-[30px]'>
                <div className='basis-[40%]'>
                    <h2 className='font-semibold mb-4'>Information</h2>
                    <p className='text-gray-600'>Edit your account information here</p>
                </div>
                <div className='basis-[60%] bg-white p-6'>
                    <form onSubmit={handleSubmit} class="max-w-sm mx-auto">
                        <div class="mb-5">
                            <label for="name" class="block mb-2 text-sm font-medium text-gray-900">Username</label>
                            <input type="name" id="name" name='username' value={admin.username} onChange={handleInputChange} class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div class="mb-5">
                            <label for="name" class="block mb-2 text-sm font-medium text-gray-900">Email</label>
                            <input type="name" id="name" name='email' value={admin.email} onChange={handleInputChange} class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        
                        <button type="submit" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Edit Account</button>
                    </form>
                </div>
            </div>
        </div>
        </main>
    </div>
  )
}

export default EditAccount