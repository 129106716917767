import React, { useEffect, useState } from 'react'
import { FaArrowAltCircleRight } from 'react-icons/fa'
import { useNavigate, useParams } from 'react-router-dom'
import { useAuth } from '../../middleware/AuthContext'
import { toast } from 'react-toastify'
import axios from 'axios'
import { server } from '../../server'
import AdminSidebar from './components/AdminSidebar'
import AdminHeadbar from './components/AdminHeadbar'
import Select from 'react-tailwindcss-select'

const AddSupplier = () => {
    const [categories, setCategories] = useState([]);
    const [brands, setBrands] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [categoriesData, setCategoriesData] = useState([]);
    const [formData, setFormData] = useState(
        {   
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            companyName: '',
            companyEmail: '', 
            companyType: '',
            dollarExchangeRate: '',
            country: '',
            address: '',
            password: '',
        }
    );

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const options = categoriesData.map(categoryData => ({
      value: categoryData.name,
      label: categoryData.name
  }));

  const handleCategoryChange = (selectedOption) => {
      setSelectedCategory(selectedOption)
  };

    useEffect(() => {
        const fetchCategoriesAndBrands = async () => {
            try {
                const categoriesResponse = await axios.get(`${server}/categories`);
                setCategories(categoriesResponse.data);

                const brandsResponse = await axios.get(`${server}/brands`);
                setBrands(brandsResponse.data);
            } catch (error) {
                console.error('Error fetching categories and brands:', error);
            }
        };

        fetchCategoriesAndBrands();
    }, []);

    useEffect(() => {
      const fetchCategories = async () => {
          try {
              const response = await axios.get(`${server}/categories`);
              setCategoriesData(response.data);
          } catch (error) {
              console.error('Error fetching categories:', error);
          }
      };
      fetchCategories();
  }, []);
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        // Create a copy of formData
        const formDataCopy = { ...formData };

        // Add selected category to categories array in formDataCopy
        formDataCopy.categories = [selectedCategory.value];
        try {
            // Send updated data to the server
            await axios.post(`${server}/suppliers/admin-create-supplier`, formDataCopy);
            // Optionally, you can fetch the updated data again to reflect changes in the UI
            toast.success('Supplier Created successfully!');
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        } catch (error) {
            console.error('Error creating supplier:', error);
            toast.error({error});
        }
    };

  return (
    <div className='flex'>
      <div className='w-[20%]'>
        <AdminSidebar />
      </div>
      <main className='w-[80%]'>
        <AdminHeadbar />
        <form className='p-[40px] bg-[#f8f9fc]' onSubmit={handleSubmit}> 
            <div className='border-b-2 border-dashed py-[30px] flex items-center'>
                <span className='font-medium text-xl'>Create a Supplier</span>
            </div>
            <div className='flex p-[30px] border-b-2 border-dashed'>
                <div className='basis-[30%]'>
                    <h2 className='font-semibold mb-4'>Information</h2>
                    <p className='text-gray-600'>Add personal information</p>
                </div>
                <div className='basis-[70%] bg-white p-10'>
                    <div className=" mx-auto">
                        <div className="mb-5">
                            <label for="name" class="block mb-2 text-sm font-medium text-gray-900">Supplier First Name</label>
                            <input type="text" id="name" name='firstName' value={formData.firstName} onChange={handleChange} class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div className="mb-5">
                            <label for="price" class="block mb-2 text-sm font-medium text-gray-900">Supplier Last Name</label>
                            <input type="text" id="price" name='lastName' value={formData.lastName} onChange={handleChange}  class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="" required />
                        </div>
                        <div className="mb-5">
                            <label for="sku" class="block mb-2 text-sm font-medium text-gray-900">Email Address</label>
                            <input type="email" id="sku" name='email' value={formData.email} onChange={handleChange}  class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div className='mb-5'>
                            <label for="phoneNumber" class="block mb-2 text-sm font-medium text-gray-900">Phone Number</label>
                            <input type="tel" id="sku" name='phoneNumber' value={formData.phoneNumber} onChange={handleChange}  class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />                        </div>
                    </div> 
                </div>
            </div>
            <div className='flex p-[30px]'>
                <div className='basis-[30%]'>
                    <h2 className='font-semibold mb-4'>Company Information</h2>
                    <p className='text-gray-600'>Fill the form below or write us. We will help you create your account</p>
                </div>
                <div className='basis-[70%] bg-white p-10'>
                    <div className=" mx-auto">
                        <div className="mb-5">
                            <label for="name" class="block mb-2 text-sm font-medium text-gray-900">Category</label>
                            <Select
                            value={selectedCategory}
                            onChange={handleCategoryChange}
                            options={options}
                            // isMultiple={true}
                             />
                        </div>
                        <div className="mb-5">
                            <label for="warranty" class="block mb-2 text-sm font-medium text-gray-900">Company Name</label>
                            <input type="text" name="companyName" value={formData.companyName} onChange={handleChange} placeholder='company name' id="warranty" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div className="mb-5">
                            <label for="warranty" class="block mb-2 text-sm font-medium text-gray-900">Company Email</label>
                            <input type="email" name="companyEmail" value={formData.companyEmail} onChange={handleChange} placeholder='company@company.com' id="warranty" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div className="mb-5">
                            <label class="block mb-2 text-sm font-medium text-gray-900">Company Type</label>
                            <select name="companyType" id="" value={formData.companyType} onChange={handleChange}  class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                <option value="" disabled>Company Type</option>
                                <option value="supplier">Supplier</option>
                                <option value="manufacturer">Manufacturer</option>
                            </select>
                        </div>
                        <div className="mb-5">
                            <label for="warranty" class="block mb-2 text-sm font-medium text-gray-900">Country</label>
                            <input type="text" name="country" value={formData.country} onChange={handleChange} placeholder='Kenya' id="warranty" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div className="mb-5">
                            <label for="warranty" class="block mb-2 text-sm font-medium text-gray-900">Address</label>
                            <input type="text" name="address" value={formData.address} onChange={handleChange} placeholder='St Boulevard' id="warranty" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div className="mb-5">
                            <label for="warranty" class="block mb-2 text-sm font-medium text-gray-900">Dollar Exchange Rate</label>
                            <input type="number" name="dollarExchangeRate" value={formData.dollarExchangeRate} onChange={handleChange} placeholder='12' id="warranty" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div className="mb-5">
                            <label for="warranty" class="block mb-2 text-sm font-medium text-gray-900">Password</label>
                            <input type="text" name="password" value={formData.password} onChange={handleChange} placeholder='****' id="warranty" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                    </div> 
                </div>
            </div>
            <div className='w-full flex justify-between items-center'>
                <a href='/supplier/account' className='flex items-center gap-1 text-orange-600'>
                    <FaArrowAltCircleRight />
                    Go to account
                </a>
                <div className='flex gap-2'>
                    <a href='/admin/suppliers' class="border border-orange-600 hover:bg-orange-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Cancel</a>
                    <button type="submit" class="text-white bg-orange-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Create Supplier</button>
                </div>
            </div>
        </form>
        </main>
    </div>
  )
}

export default AddSupplier