import React, { useEffect, useState } from 'react'
import { FaEye, FaPlus } from 'react-icons/fa6'
import AdminSidebar from './components/AdminSidebar'
import AdminHeadbar from './components/AdminHeadbar'
import profile from '../../assets/GooglePlayStore.png'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import axios from 'axios'
import { server } from '../../server'
import SupplierReview from '../../components/SupplierReview'

const Suppliers = () => {
    const [suppliers, setSuppliers] = useState([]);
    const [selectedSupplier, setSelectedSupplier] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isRejectModalOpen, setRejectModalOpen] = useState(false);
    const [isReviewModalOpen, setReviewModalOpen] = useState(false);
    const [isHoldModalOpen, setHoldModalOpen] = useState(false);
    const [isApproveModalOpen, setApproveModalOpen] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        const fetchSuppliers = async () => { 
            try {
                const response = await axios.get(`${server}/suppliers/get-suppliers`);
                setSuppliers(response.data);
            } catch (error) {
                console.error(error);
            } 
        };
        
        fetchSuppliers();
    }, []);

    const filteredSuppliers = suppliers.filter((supplier) =>
        supplier.companyName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleApprove = async () => {
        try {
            const response = await axios.patch(`${server}/suppliers/approve-supplier/${selectedSupplier._id}`);
            toast.success(response.data.message);
            setApproveModalOpen(false);
            window.location.reload();
        } catch (error) {
            toast.error('Failed to approve supplier');
        }
    };
    const handleReject = async () => {
        try {
            const response = await axios.patch(`${server}/suppliers/reject-supplier/${selectedSupplier._id}`);
            toast.success(response.data.message);
            setRejectModalOpen(false)
            window.location.reload();
        } catch (error) {
            toast.error('Failed to reject supplier');
        }
    };
    const handleDelete = async () => {
        try {
            const response = await axios.delete(`${server}/suppliers/delete-supplier/${selectedSupplier._id}`);
            toast.success(response.data.message);
            setDeleteModalOpen(false)
            window.location.reload();
        } catch (error) {
            toast.error('Failed to delete supplier');
        }
    };
    const handleHold = async () => {
        try {
            const response = await axios.patch(`${server}/suppliers/hold-supplier/${selectedSupplier._id}`);
            toast.success(response.data.message);
            setHoldModalOpen(false)
            window.location.reload();
        } catch (error) {
            toast.error('Failed to reject supplier');
        }
    };

    const getStatusColor = (status) => {
        switch(status) {
            case 'Approved':
                return 'green';
            case 'Not approved':
                return 'blue';
            case 'On Hold':
                return 'orange';
            case 'Rejected':
                return 'red';
            default:
                return 'gray';
        }
    };
    
    const getActionButtons = (supplier) => {
        switch(supplier.status) {
            case 'Approved':
                return (
                    <>
                        <button onClick={() => toggleHoldModal(supplier)} className="text-blue-500" type="button">Hold</button>
                        <button onClick={() => toggleDeleteModal(supplier)} className="text-red-500" type="button">Delete</button>
                    </>
                );
                // return <button onClick={() => toggleModal(supplier)} className="text-blue-500" type="button">Delete</button>;
            case 'Not approved':
                return (
                    <>
                        <button onClick={() => toggleApproveModal(supplier)} className="text-green-500" type="button">Approve</button>
                        <button onClick={() => toggleRejectModal(supplier)} className="text-red-500" type="button">Reject</button>
                    </>
                );
            case 'On Hold':
                return (
                    <>
                        <button onClick={() => toggleApproveModal(supplier)} className="text-green-500" type="button">Approve</button>
                        <button onClick={() => toggleDeleteModal(supplier)} className="text-red-500" type="button">Delete</button>
                    </>
                );
            case 'Rejected':
                return (
                    <>
                        <button onClick={() => toggleApproveModal(supplier)} className="text-green-500" type="button">Approve</button>
                        <button onClick={() => toggleDeleteModal(supplier)} className="text-red-500" type="button">Delete</button>
                    </>
                );
            default:
                return null;
        }
    };
    

    const toggleModal = (supplier) => {
        setIsModalOpen(!isModalOpen);
        setSelectedSupplier(supplier)
    };

    const toggleRejectModal = (supplier) => {
        setRejectModalOpen(!isRejectModalOpen);
        setSelectedSupplier(supplier)
    }

    const toggleViewModal = (supplier) => {
        setReviewModalOpen(!isReviewModalOpen);
        setSelectedSupplier(supplier)
    }
    const toggleApproveModal = (supplier) => {
        setApproveModalOpen(!isApproveModalOpen);
        setSelectedSupplier(supplier)
    }

    const toggleHoldModal = (supplier) => {
        setHoldModalOpen(!isHoldModalOpen);
        setSelectedSupplier(supplier)
    }

    const toggleDeleteModal = (supplier) => {
        setDeleteModalOpen(!isDeleteModalOpen);
        setSelectedSupplier(supplier)
    }

  return (
    <div className='flex relative'>
      <div className='w-[20%]'>
        <AdminSidebar />
      </div>
      <main className='w-[80%]'>
      <AdminHeadbar />
        <div className='m-[30px] bg-[#f8f9fc]'>
        <div className='bg-[#fff] flex justify-between p-[20px] items-center mb-10'>
            <div className='border-l-4 border-orange-500 rounded-lg p-4'>
                <span className='text-2xl font-semibold'>Suppliers</span>
            </div>
            <div className='flex justify-between gap-[75px] w-[700px]'>   
                <form className="w-full">   
                    <label for="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only">Search</label>
                    <div className="relative">
                        <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                            <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                            </svg>
                        </div>
                        <input type="search" id="default-search" value={searchQuery} onChange={handleSearchChange} className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-orange-500 focus:border-orange-500" placeholder="Search by company name..." required />
                        <button type="submit" className="text-white absolute end-2.5 bottom-2.5 bg-orange-700 hover:bg-orange-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2">Search</button>
                    </div>
                </form>
                <Link to='../add-supplier' className='flex items-center justify-center bg-orange-600 gap-4 rounded-xl w-[200px] text-white'>
                    <FaPlus />
                    Add Supplier
                </Link>
            </div>
        </div>
        <div className='bg-[#fff] h-[70vh]'>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg overflow-y-auto">         
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 overflow-y-auto">
                    <thead className="text-xs text-gray-700 uppercase bg-[#fff]">
                        <tr>
                            <th scope="col" className="p-4">
                                <div className="flex items-center">
                                    <input id="checkbox-all-search" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                    <label for="checkbox-all-search" className="sr-only">checkbox</label>
                                </div>
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Name
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Company
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Status
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredSuppliers && filteredSuppliers.map((supplier) => (
                            <tr className="bg-white border-b hover:bg-gray-50" key={supplier._id}>
                                <td className="w-4 p-4">
                                    <div className="flex items-center">
                                        <input id="checkbox-table-search-1" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                        <label for="checkbox-table-search-1" className="sr-only">checkbox</label>
                                    </div>
                                </td>
                                <th scope="row" className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap">
                                    <img className="w-10 h-10 rounded-full" src={profile} alt="Jese images"/>
                                    <div className="ps-3">
                                        <div className="text-base font-semibold">{supplier.firstName} {supplier.lastName}</div>
                                        <div className="font-normal text-gray-500">{supplier.email}</div>
                                    </div>  
                                </th>
                                <td className="px-6 py-4">
                                    <div className='flex'>
                                        <span class="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full">{supplier.companyName}</span>
                                    </div>
                                </td>
                                <td className="px-6 py-4">
                                    <div className="flex items-center">
                                        <span className={`bg-${getStatusColor(supplier.status)}-100 text-${getStatusColor(supplier.status)}-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded border border-${getStatusColor(supplier.status)}-400`}>{supplier.status}</span>
                                    </div>
                                </td>
                                <td className="px-6 py-4">
                                    <div className='flex items-center justify-center gap-2'>
                                    {getActionButtons(supplier)}
                                    <button href="/" data-twe-toggle="tooltip" title="View Supplier">
                                        <FaEye color='blue' size={20} onClick={() => toggleViewModal(supplier)} />
                                    </button>
                                    </div>
                                </td>
                            </tr>
                        ))}       
                    </tbody>
                </table>
            </div>
        </div>
        </div>
      </main>
      {isReviewModalOpen && (
                <div tabIndex="-1" className="fixed top-[200px] left-[40%] z-50 justify-center items-center w-full">
                    <div className="relative p-4 w-full max-w-md max-h-full">
                        {/* Render the ResellerView component and pass the selected reseller data */}
                        <SupplierReview supplier={selectedSupplier} closeModal={toggleViewModal} />
                        {/* Close button or any other controls to close the modal */}
                        <button onClick={() => setIsModalOpen(false)}>Close Modal</button>
                    </div>
                </div>
            )}
        {isApproveModalOpen && (
                <div id="popup-modal" tabIndex="-1" className="fixed top-[100px] left-[40%] z-50 justify-center items-center w-full">
                    <div className="relative p-4 w-full max-w-md max-h-full">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <button type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={toggleModal}>
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                            <div className="p-4 md:p-5 text-center">
                                <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                                </svg>
                                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to approve this supplier account?</h3>
                                <button data-modal-hide="popup-modal" type="button" onClick={handleApprove} className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                                    Yes, I'm sure
                                </button>
                                <button data-modal-hide="popup-modal" onClick={toggleHoldModal} type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">No, cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
      {isHoldModalOpen && (
                <div id="popup-modal" tabIndex="-1" className="fixed top-[100px] left-[40%] z-50 justify-center items-center w-full">
                    <div className="relative p-4 w-full max-w-md max-h-full">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <button type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={toggleModal}>
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                            <div className="p-4 md:p-5 text-center">
                                <svg onClick={toggleHoldModal} className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                                </svg>
                                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to place this account on hold?</h3>
                                <button data-modal-hide="popup-modal" type="button" onClick={handleHold} className="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                                    Yes, I'm sure
                                </button>
                                <button data-modal-hide="popup-modal" onClick={toggleHoldModal} type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">No, cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {isRejectModalOpen && (
                <div id="popup-modal" tabIndex="-1" className="fixed top-[100px] left-[40%] z-50 justify-center items-center w-full">
                    <div className="relative p-4 w-full max-w-md max-h-full">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <button type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={toggleDeleteModal}>
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                            <div className="p-4 md:p-5 text-center">
                                <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                                </svg>
                                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to reject this supplier?</h3>
                                <button data-modal-hide="popup-modal" type="button" onClick={handleReject} className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                                    Yes, I'm sure
                                </button>
                                <button data-modal-hide="popup-modal" onClick={toggleRejectModal} type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">No, cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {isDeleteModalOpen && (
                <div id="popup-modal" tabIndex="-1" className="fixed top-[100px] left-[40%] z-50 justify-center items-center w-full">
                    <div className="relative p-4 w-full max-w-md max-h-full">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <button type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={toggleDeleteModal}>
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                            <div className="p-4 md:p-5 text-center">
                                <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                                </svg>
                                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to delete this supplier?</h3>
                                <button data-modal-hide="popup-modal" type="button" onClick={handleDelete} className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                                    Yes, I'm sure
                                </button>
                                <button data-modal-hide="popup-modal" onClick={toggleDeleteModal} type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">No, cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
    </div>
  )
}

export default Suppliers