import React from 'react'
import Footer from '../components/Footer'
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa6'
import PlayImg from '../assets/play.png'
import AppImg from '../assets/appstore.png'
import MainNav from '../components/MainNav'
import Counter from '../components/Counter'
import gif from '../assets/ResellerSprint.gif'

const AboutPage = () => {
  return (
    <div>
      <MainNav />   
      <div className='h-[150px] top-section bg-red-500 mb-5'>
        <div className='top-overlay px-16' style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "end"}}>
          <h1 className='sm:text-6xl text-4xl text-center text-orange-600 font-bold mb-3 counter-text'>Our Story</h1>
          <h2 className='sm:text-2xl text-xl text-center text-gray-400 font-bold mb-5'>Transforming Reselling in East Africa</h2>
        </div>
      </div>
      <div>
        <div className='sm:flex sm:gap-2 sm:items-center p-[40px]'>
            <p className='sm:mb-0 mb-3 sm:text-start text-center'>At Resellersprint, our journey began with a vision – to reshape the reselling landscape in East Africa. Inspired by the dynamic markets of Kenya, Uganda, and Tanzania, our founders sought to create a platform that would not only connect resellers with suppliers but would become a driving force for growth and success.</p>
            <p className='sm:mb-0 mb-3 sm:text-start text-center'>The journey began with a simple yet powerful idea – to redefine how resellers, wholesalers, and manufacturers connect and thrive. Resellersprint emerged as a response to the challenges faced by businesses in obtaining real-time data, accessing a diverse range of products, and expanding their market reach.</p>
        </div>
        <div className='px-[20px]'>
          <img src={gif} alt="" className='w-[100%] sm:h-[600px]' />
        </div>    
        <div class="max-w-screen-lg mx-auto px-4 sm:px-6 lg:px-8 flex flex-col justify-between">
            <div class="text-center">
                <p class="mt-4 text-sm leading-7 text-gray-500 font-regular">
                    GUIDELINES
                </p>
                <h3 class="text-2xl sm:text-3xl leading-normal font-extrabold tracking-tight text-gray-900">
                    What drives <span class="text-orange-600 counter-text text-2xl sm:text-4xl">Reseller Sprint?</span>
                </h3>
            </div>
            <div class="mt-20">
                <ul class="md:grid md:grid-cols-3 md:col-gap-10 md:row-gap-10">
                    <li class=" bg-gray-100 p-5 pb-10 text-center">
                        <div class="flex flex-col items-center">
                            <div class="flex-shrink-0 relative top-0 -mt-16">
                                <div
                                    class="flex items-center justify-center h-20 w-20 rounded-full bg-orange-500 text-white border-4 border-white text-xl font-semibold">
                                    1
                                </div>
                            </div>
                            <div class="mt-4">
                                <h4 class="text-lg leading-6 font-semibold text-gray-900">Innovation</h4>
                                <p class="mt-2 text-base leading-6 text-gray-500">
                                Continuous innovation drives Resellersprint, introducing groundbreaking solutions to redefine reselling.
                                </p>
                            </div>
                        </div>
                    </li>
                    <li class=" bg-gray-100 p-5 pb-10 text-center">
                        <div class="flex flex-col items-center">
                            <div class="flex-shrink-0 relative top-0 -mt-16">
                                <div
                                    class="flex items-center justify-center h-20 w-20 rounded-full bg-orange-500 text-white border-4 border-white text-xl font-semibold">
                                    2
                                </div>
                            </div>
                            <div class="mt-4">
                                <h4 class="text-lg leading-6 font-semibold text-gray-900">Collaboration</h4>
                                <p class="mt-2 text-base leading-6 text-gray-500">
                                We foster a community where businesses thrive together through active collaboration.
                                </p>
                            </div>
                        </div>
                    </li>
                    <li class=" bg-gray-100 p-5 pb-10 text-center">
                        <div class="flex flex-col items-center">
                            <div class="flex-shrink-0 relative top-0 -mt-16">
                                <div
                                    class="flex items-center justify-center h-20 w-20 rounded-full bg-orange-500 text-white border-4 border-white text-xl font-semibold">
                                    3
                                </div>
                            </div>
                            <div class="mt-4">
                                <h4 class="text-lg leading-6 font-semibold text-gray-900">Empowerment</h4>
                                <p class="mt-2 text-base leading-6 text-gray-500">
                                Resellersprint is a catalyst for individual and collective empowerment.
                                </p>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div>
            <Counter />
        </div>
        <div className='w-[100%] bg-orange-400 p-10'>
          <div className='w-full'>
            <h2 className='text-white text-2xl text-center font-bold mb-2'>Access our services from your mobile device.</h2>
            <p className='text-white text-center text-xl font-semibold mb-5'>Mobile App Available</p>
            <div className='flex w-full items-center justify-center gap-4'>
              <a href="https://play.google.com/store/apps/details?id=com.codemunity.resellersprint">
                <img src={PlayImg} alt="" />
              </a>
              <a href="/">
                <img src={AppImg} alt="" />
              </a>
            </div>
          </div>
        </div>
        <Footer />
        <div className='hidden sm:w-[100%] sm:px-10 text-black sm:flex sm:items-center sm:justify-between'>
          <p className='text-sm'> <span>&copy;</span> Reseller Sprint. All rights reserved.</p>
          <div className='flex gap-2 items-center'>
            <a href="/privacy" className='underline text-sm text-orange-600'>Terms & conditions</a>
            <a href="/privacy" className='underline text-sm text-orange-600'>Privacy Policy</a>
          </div>
          <div className='flex items-center gap-3'>
            <FaFacebook size={18} color='#4267B2'/>
            <FaInstagram size={18} color='#cd486b' />
            <FaTwitter size={18} color='#1DA1F2' />
            </div>
        </div>
      </div>
    </div>
  )
}

export default AboutPage