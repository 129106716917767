import React, {useState, useEffect} from 'react'
import logo from '../assets/ResellerSprint logo.png'
import { FaFacebook, FaInstagram, FaLocationDot, FaPhone, FaTwitter } from 'react-icons/fa6'
import { MdOutlineEmail } from "react-icons/md";
import axios from 'axios'
import { server } from '../server';

const Footer = () => {
    const [info, setInfo] = useState([])

    useEffect(() => {
        const fetchSuppliers = async () => { 
          try {
            const response = await axios.get(`${server}/info`);
            setInfo(response.data[0]);
          } catch (error) {
            
          } 
        };
        fetchSuppliers();
      }, []);

  return (
    <div className='sm:grid sm:grid-cols-4 sm:p-10 block p-5 bg-black text-white'>
        <div className='p-4'>
            <img src={logo} alt="" className='mb-3' />
            <p className='mb-3 text-center sm:text-left'>A catalogue of wholesalers and suppliers across the globe.</p>
            <div className='flex w-full sm:justify-start justify-center items-center gap-3'>
                <FaFacebook size={24} />
                <FaInstagram size={24} />
                <FaTwitter size={24} />
            </div>
        </div>
        <div>
            <h2 className='font-bold mb-3 sm:text-start text-center'>Quick Links</h2>
            <ul>
                <li className='sm:text-start text-center'>
                    <a href="/partners" className='text-gray-600 hover:text-orange-600 text-[16px]'>Partnership</a>
                </li>
                <li className='sm:text-start text-center'>
                    <a href="/about" className='text-gray-600 hover:text-orange-600 text-[16px]'>Our Story</a>
                </li>
                <li className='sm:text-start text-center'>
                    <a href="/supplier-register" className='text-gray-600 hover:text-orange-600 text-[16px]'>Supplier registration</a>
                </li>
                <li className='sm:text-start text-center'>
                    <a href="/supplier-login" className='text-gray-600 hover:text-orange-600 text-[16px]'>Reseller registration</a>
                </li>
            </ul>
        </div>
        <div>
        <h2 className='font-bold mb-3 sm:text-start text-center'>Important Links</h2>
            <ul>
                <li className='sm:text-start text-center'>
                    <a href="/home" className='text-gray-600 hover:text-orange-600 text-[16px]'>Shop</a>
                </li>
                {/* <li className='sm:text-start text-center'>
                    <a href="/" className='text-gray-600 hover:text-orange-600 text-[16px]'>Careers</a>
                </li> */}
                <li className='sm:text-start text-center'>
                    <a href="/about" className='text-gray-600 hover:text-orange-600 text-[16px]'>About Us</a>
                </li>
                <li className='sm:text-start text-center'>
                    <a href="/faq" className='text-gray-600 hover:text-orange-600 text-[16px]'>FAQ</a>
                </li>
            </ul>
        </div>
        <div>
            <h2 className='font-bold mb-3 sm:text-start text-center'>Contact Info</h2>
            <div className='flex gap-2 items-center sm:justify-start justify-center text-gray-600'>
                <FaPhone />
                {info.phone}
            </div>
            <div className='flex gap-2 items-center sm:justify-start justify-center text-gray-600'>
                <MdOutlineEmail />
                {info.email}
            </div>
            <div className='flex gap-2 items-center sm:justify-start justify-center text-gray-600'>
                <FaLocationDot />
               {info.address}
            </div>
        </div>
    </div>
  )
}

export default Footer