import React, { useEffect, useState } from 'react';

const Counter = () => {
  const targets = [
    { id: 'resellersCount', count: 10000, suffix: '+' },
    { id: 'manufacturersCount', count: 50, suffix: '+' },
    { id: 'agenciesCount', count: 10, suffix: '+' }
  ];

  const [maxCount, setMaxCount] = useState(0);

  useEffect(() => {
    // Find the maximum count among all targets
    const max = Math.max(...targets.map(target => target.count));
    setMaxCount(max);
  }, []);

  // Function to animate count-up effect
  const AnimateCountUp = (target, duration) => {
    const [currentCount, setCurrentCount] = useState(0);
    const increment = Math.ceil(target.count / (duration / 10));

    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentCount(prevCount => {
          const newCount = prevCount + increment;
          return newCount >= target.count ? target.count : newCount;
        });
      }, 10);

      return () => clearInterval(interval);
    }, [target.count, increment]);

    return (
      <span id={target.id}>{currentCount + target.suffix}</span>
    );
  };

  return (
    <div className="w-full mt-10 mb-4">
      <div className="pt-12 bg-gray-50 sm:pt-20">
        <div className="max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8">
          <div className="max-w-4xl mx-auto text-center">
            <h2 className="text-3xl font-extrabold leading-9 text-gray-900  sm:text-4xl sm:leading-10">
              Trusted by businesses
            </h2>
            <p className="mt-3 text-xl leading-7 text-gray-600 sm:mt-4">
                Success is our story
            </p>
          </div>
        </div>
        <div className="pb-12 mt-10 bg-gray-50 sm:pb-16">
          <div className="relative">
            <div className="absolute inset-0 h-1/2 bg-gray-50 "></div>
            <div className="relative max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8">
              <div className="max-w-4xl mx-auto">
                <dl className="bg-gray-800 rounded-lg shadow-lg sm:grid sm:grid-cols-3">
                  {targets.map(target => (
                    <div key={target.id} className="flex flex-col p-6 text-center border-b border-gray-100 dark:border-gray-700 sm:border-0 sm:border-r">
                      <dt className="order-2 mt-2 text-lg font-medium leading-6 text-gray-500 dark:text-gray-400">
                        {target.id === 'resellersCount' ? 'Resellers' : target.id === 'manufacturersCount' ? 'Manufacturers/Wholesalers' : 'Marketing Agencies'}
                      </dt>
                      <dd className="order-1 text-5xl font-extrabold leading-none text-orange-600 dark:text-orange-100">
                        {AnimateCountUp(target, maxCount / 100)}
                      </dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Counter;
