import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { server } from '../server';
import { FaEye, FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa6';
import { CiCircleQuestion } from 'react-icons/ci';
import axios from 'axios';
import Footer from '../components/Footer';
import PlayImg from '../assets/play.png'
import AppImg from '../assets/appstore.png'
import MainNav from '../components/MainNav';
import ProductReview from '../components/ProductReview';

const SearchResults = () => {
  const [products, setProducts] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchQuery = queryParams.get('query');
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [suppliers, setSuppliers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedBrand, setSelectedBrand] = useState('');
  const [selectedSupplier, setSelectedSupplier] = useState('');

  useEffect(() => {
    // Mock fetch products from the database (replace with actual API call if needed)
    const fetchProducts = async () => {
      // Simulate fetching products from a database
      const response = await fetch(`${server}/products/get-products`);
      const data = await response.json();
      setProducts(data);

      const suppliersResponse = await axios.get(`${server}/suppliers/get-suppliers`);
      setSuppliers(suppliersResponse.data);

      const categoriesResponse = await axios.get(`${server}/categories`);
      setCategories(categoriesResponse.data);

      const brandsResponse = await axios.get(`${server}/brands`);
      setBrands(brandsResponse.data);
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    // Filter products based on the search query
    // const filteredProducts = products.filter((product) =>
    //   product.name.toLowerCase().includes(searchQuery.toLowerCase())
    // );
    // Filter products based on the search query and selected filters
    const filteredProducts = products.filter((product) => {
      // Check if the search query matches any of the fields
      const queryMatchesName = product.name.toLowerCase().includes(searchQuery.toLowerCase());
      const queryMatchesSku = product.sku.toLowerCase().includes(searchQuery.toLowerCase());
      const queryMatchesDescription = product.description.toLowerCase().includes(searchQuery.toLowerCase());
      const queryMatchesBrand = product.brand.toLowerCase().includes(searchQuery.toLowerCase());
      const queryMatchesCategory = product.category.toLowerCase().includes(searchQuery.toLowerCase());

      // Return true if any of the search query matches
      return queryMatchesName || queryMatchesSku || queryMatchesDescription || queryMatchesBrand || queryMatchesCategory;
    });
    setSearchResults(filteredProducts);
  }, [products, searchQuery]);

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleBrandChange = (event) => {
    setSelectedBrand(event.target.value);
  };

  const handleSupplierChange = (event) => {
    setSelectedSupplier(event.target.value);
  };

  const toggleModal = (product) => {
    setModalOpen(!modalOpen);
    setSelectedProduct(product)
  };

  const finalProducts = searchResults.filter(product => {
    return (
      (selectedCategory === '' || product.category === selectedCategory) &&
      (selectedBrand === '' || product.brand === selectedBrand) &&
      (selectedSupplier === '' || product.supplier === selectedSupplier)
    );
  });

  return (
    <div>
      <MainNav />
      <div className='h-[150px] top-section mb-3'>
        <div className='top-overlay px-16' style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "end" }}>
          <h1 className='text-6xl text-center text-orange-600 font-bold mb-5 counter-text'>Search Query: {searchQuery}</h1>
        </div>
      </div>
      <div className='p-[40px]'>
        <div className=''>
          <div className='sm:grid sm:grid-cols-3 w-full sm:px-[30px] py-[10px] gap-3 bg-[#fff] mb-4'>
            <div>
              <h2 className='text-gray-400 mb-2'>Filter by category</h2>
              <select name="" id="" className='rounded-md w-full h-[40px] border outline-none border-gray-400' onChange={handleCategoryChange}>
                <option value="">All</option>
                {categories.map(category => (
                  <option key={category._id} value={category.name}>{category.name}</option>
                ))}
              </select>
            </div>
            <div>
              <h2 className='text-gray-400 mb-2'>Filter by brand</h2>
              <select name="" id="" className='rounded-md w-full h-[40px] border outline-none border-gray-400' onChange={handleBrandChange}>
                <option value="">All</option>
                {brands.map(brand => (
                  <option key={brand._id} value={brand.name}>{brand.name}</option>
                ))}
              </select>
            </div>
            <div>
              <h2 className='text-gray-400 mb-2'>Filter by supplier</h2>
              <select name="" id="" className='rounded-md w-full h-[40px] border outline-none border-gray-400' onChange={handleSupplierChange}>
                <option value="">All</option>
                {suppliers.map(supplier => (
                  <option key={supplier._id} value={supplier._id}>{supplier.companyName}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className='flex flex-wrap gap-3 items-center justify-center'>
          <table className='shadow bg-slate-100 block p-8 overflow-scroll'>
            <thead className="text-xs text-gray-700 uppercase bg-[#fff]">
              <tr className='border-b-2'>
                <th scope="col" className="p-4">
                  <div className="flex items-center">
                    <input id="checkbox-all-search" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                    <label for="checkbox-all-search" className="sr-only">checkbox</label>
                  </div>
                </th>
                <th scope="col" className="px-4 py-3">
                  SKU
                </th>
                <th scope="col" className="px-6 py-3">
                  Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Price
                </th>
                <th scope="col" className="px-6 py-3">
                  Category
                </th>
                <th scope="col" className="px-6 py-3">
                  Brand
                </th>
                <th scope="col" className="px-4 py-3">
                  Warranty(M)
                </th>
                <th scope="col" className="px-6 py-3">
                  Supplier
                </th>
                <th scope="col" className="px-6 py-3">
                  Status
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {finalProducts && finalProducts.map(product => (
                <tr className="bg-white border-b-2 hover:bg-gray-50" key={product._id} >
                  <td className="w-4 p-4">
                    <div className="flex items-center">
                      <input id="checkbox-table-search-1" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                      <label for="checkbox-table-search-1" className="sr-only">checkbox</label>
                    </div>
                  </td>
                  <td className='w-4 p-4'>
                    <span>{product.sku}</span>
                  </td>
                  <th scope="row" className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap">
                    <div className="ps-3">
                    <div data-twe-toggle="tooltip" title={product.name} className="text-base font-semibold">{product.name.length > 30 ? `${product.name.slice(0, 30)}...` : product.name}</div>
                      <div className="font-normal text-gray-500">{product.category}</div>
                    </div>
                  </th>
                  <td className="px-4 py-4">
                    <span>${product.price}</span>
                  </td>
                  <td className="px-4 py-4">
                    <span>{product.category}</span>
                  </td>
                  <td className="px-4 py-4">
                    <span>{product.brand}</span>
                  </td>
                  <td className="px-4 py-4">
                    <span>{product.warranty}</span>
                  </td>
                  <td className="px-6 py-4">
                    <a href={`/supplier/preview/${product.supplier}`} className='text-blue-700 underline'>{suppliers.find(supplier => supplier._id === product.supplier)?.companyName}</a>
                  </td>
                  <td className="px-6 py-4">
                    {product.status === 'available' && (
                      <div className="flex items-center gap-1">
                        <div className="bg-green-600 w-2 h-2 rounded-full"></div>
                        <span className="text-green-600">Available</span>
                        <CiCircleQuestion className="cursor-pointer" data-twe-toggle="tooltip" title="Large quantity" />
                      </div>
                    )}
                    {product.status === 'limited' && (
                      <div className="flex items-center gap-1">
                        <div className="bg-orange-500 w-2 h-2 rounded-full"></div>
                        <span className="text-orange-500">Limited</span>
                        <CiCircleQuestion className="cursor-pointer" data-twe-toggle="tooltip" title="Limited quantity" />
                      </div>
                    )}
                    {product.status === 'unavailable' && (
                      <div className="flex items-center gap-1">
                        <div className="bg-red-600 w-2 h-2 rounded-full"></div>
                        <span className="text-red-600">Unavailable</span>
                        <CiCircleQuestion className="cursor-pointer" data-twe-toggle="tooltip" title="Not available" />
                      </div>
                    )}
                  </td>
                  <td className="px-6 py-4">
                    <div className='flex gap-2'>
                      <button onClick={() => toggleModal(product)} data-twe-toggle="tooltip" title="View Product Details">
                        <FaEye size={20} color="grey" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {modalOpen && (
            <div tabIndex="-1" className="fixed top-[150px] sm:left-[35%] z-50 justify-center items-center w-full">
              <div className="relative p-4 w-full max-w-md max-h-full">
                {/* Render the ResellerView component and pass the selected reseller data */}
                <ProductReview product={selectedProduct} closeModal={toggleModal} />
                {/* Close button or any other controls to close the modal */}
                <button onClick={() => setModalOpen(false)}>Close Modal</button>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='w-[100%] bg-orange-400 p-10'>
        <div className='w-full'>
          <h2 className='text-white text-2xl text-center font-bold mb-2'>Access our services from your mobile device.</h2>
          <p className='text-white text-center text-xl font-semibold mb-5'>Mobile App Available</p>
          <div className='flex w-full items-center justify-center gap-4'>
            <a href="https://play.google.com/store/apps/details?id=com.codemunity.resellersprint">
              <img src={PlayImg} alt="" />
            </a>
            <a href="/">
              <img src={AppImg} alt="" />
            </a>
          </div>
        </div>
      </div>
      <Footer />
      <div className='w-[100%] px-10 text-black sm:flex hidden items-center justify-between'>
        <p className='text-sm'> <span>&copy;</span> Reseller Sprint. All rights reserved.</p>
        <div className='flex gap-2 items-center'>
          <a href="/privacy" className='underline text-sm text-orange-600'>Terms & conditions</a>
          <a href="/privacy" className='underline text-sm text-orange-600'>Privacy Policy</a>
        </div>
        <div className='flex items-center gap-3'>
          <FaFacebook size={18} color='#4267B2' />
          <FaInstagram size={18} color='#cd486b' />
          <FaTwitter size={18} color='#1DA1F2' />
        </div>
      </div>
    </div>
  )
}

export default SearchResults