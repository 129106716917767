import React, { useEffect, useRef, useState } from 'react'
import AdminSidebar from './components/AdminSidebar'
import AdminHeadbar from './components/AdminHeadbar'
import Chart from 'chart.js/auto';
import SiteVisitsChart from './components/SiteVisitsChart';
import { FaArrowCircleRight, FaEye } from 'react-icons/fa'
import profile from '../../assets/rsprint.png'
import { MdOutlineDangerous } from "react-icons/md";
import { FaUserShield } from "react-icons/fa6";
import { useAuth } from '../../middleware/AuthContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { server } from '../../server';
import { toast } from 'react-toastify';

const Dashboard = () => {
    const [date, setDate] = useState(new Date());
    const [auth, setAuth] = useAuth();
    const [products, setProducts] = useState([])
    const [suppliers, setSuppliers] = useState([])
    const [resellers, setResellers] = useState([])
    const admin = auth.admin;
    const [recentSuppliers, setRecentSuppliers] = useState([]);
    const [selectedSupplier, setSelectedSupplier] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isRejectModalOpen, setRejectModalOpen] = useState(false);

    useEffect(() => {
        const fetchSuppliers = async () => { 
            try {
                const response = await axios.get(`${server}/suppliers/unapproved-suppliers`);
                setRecentSuppliers(response.data);
            } catch (error) {
                
            } 
        };
        
        fetchSuppliers();
    }, []);

    const handleApprove = async () => {
        try {
            const response = await axios.patch(`${server}/suppliers/approve-supplier/${selectedSupplier._id}`);
            toast.success(response.data.message);
            // Refresh the reseller list after approval
            const updatedSuppliers = recentSuppliers.filter(supplier => supplier._id !== selectedSupplier._id);
            setRecentSuppliers(updatedSuppliers);
            setIsModalOpen(false)
        } catch (error) {
            toast.error('Failed to approve supplier');
        }
    };
    const handleReject = async () => {
        try {
            const response = await axios.patch(`${server}/suppliers/reject-supplier/${selectedSupplier._id}`);
            toast.success(response.data.message);
            // Refresh the reseller list after approval
            const updatedSuppliers = recentSuppliers.filter(supplier => supplier._id !== selectedSupplier._id);
            setRecentSuppliers(updatedSuppliers);
            setIsModalOpen(false)
        } catch (error) {
            toast.error('Failed to reject supplier');
        }
    };

    const toggleModal = (supplier) => {
        setIsModalOpen(!isModalOpen);
        setSelectedSupplier(supplier)
    };

    const toggleRejectModal = (supplier) => {
        setRejectModalOpen(!isRejectModalOpen);
        setSelectedSupplier(supplier)
    }


    useEffect(() => {
        const fetchProducts = async () => { 
          try {
            const response = await axios.get(`${server}/products/get-products`);
            setProducts(response.data);
            
          } catch (error) {
            
          } 
        };
        const fetchSuppliers = async () => { 
          try {
            const response = await axios.get(`${server}/suppliers/get-suppliers`);
            setSuppliers(response.data);
            
          } catch (error) {
            
          } 
        };
        const fetchResellers = async () => { 
          try {
            const response = await axios.get(`${server}/resellers/get-resellers`);
            setResellers(response.data);
            
          } catch (error) {
            
          } 
        };
        
        fetchProducts();
        fetchSuppliers();
        fetchResellers();
      }, []);
  // const [siteVisits, setSiteVisits] = useState([]);

  // useEffect(() => {
  //   // Fetch site visit data when component mounts
  //   fetchSiteVisits()
  //     .then(data => setSiteVisits(data))
  //     .catch(error => console.error('Error fetching site visits:', error));

  //   // Fetch site visit data periodically (e.g., every 5 minutes)
  //   const intervalId = setInterval(() => {
  //     fetchSiteVisits()
  //       .then(data => setSiteVisits(data))
  //       .catch(error => console.error('Error fetching site visits:', error));
  //   }, 5 * 60 * 1000); // 5 minutes in milliseconds

  //   // Clean up interval when component unmounts
  //   return () => clearInterval(intervalId);
  // }, []);
  const chartRef = useRef(null);

  useEffect(() => {
    // Sample data for site visits
    const data = {
      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
      datasets: [
        {
          label: 'Site Visits',
          data: [1500, 2200, 1800, 2500, 2100, 2800],
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.1,
          fill: false,
        },
      ],
    };

    // Chart options
    const options = {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    };

    // Create the chart
    if (chartRef.current) {
      new Chart(chartRef.current, {
        type: 'line',
        data: data,
        options: options,
      });
    }
  }, []);
  
  return (
    
    <div className='flex'>
      <div className='w-[20%]'>
        <AdminSidebar />
      </div>
      <main className='w-[80%]'>
        <AdminHeadbar />
        <div className='flex justify-between sm:px-10 py-8 items-center'>
          <div>
          <h2 className='font-bold sm:text-xl text-sm'>Welcome back, Admin {admin.username}</h2>
          <p className='text-gray-400 sm:block hidden'>Your current status and analytics are here</p>
          </div>
          <p className='rounded-xl sm:block hidden p-2 border border-orange-500'>{date.toDateString()}</p>
        </div>
        <div className='w-full sm:px-10 px-2 sm:flex'>
          <div className='sm:w-[100%] w-[100%] flex flex-wrap gap-4'>
            <div className='shadow bg-white sm:w-[23%] w-[80%] h-[150px] p-4'>
              <h2 className='text-orange-600 mb-3 text-2xl counter-text '>Resellers</h2>
              <p className='text-2xl mb-3 font-bold'>{resellers?.length}</p>
              <p className='text-green-400'>5.27%</p>
              <a href='/admin/resellers' className='text-gray-400 underline'>Go to Resellers</a>
            </div>
            <div className='shadow bg-white sm:w-[23%] w-[80%] h-[150px] p-4'>
              <h2 className='text-orange-600 mb-3 text-2xl counter-text'>Suppliers</h2>
              <p className='text-2xl mb-3 font-bold'>{suppliers?.length}</p>
              <p className='text-green-400'>10.23%</p>
              <a href='/admin/suppliers' className='text-gray-400 underline'>Go to Suppliers</a>
            </div>
            <div className='shadow bg-white sm:w-[23%] w-[80%] h-[150px] p-4'>
              <h2 className='text-orange-600 mb-3 text-2xl counter-text'>Products</h2>
              <p className='text-2xl mb-3 font-bold'>{products?.length}</p>
              <p className='text-green-400'>5.27%</p>
              <a href='/admin/products' className='text-gray-400 underline'>Go to Products</a>
            </div>
            <div className='shadow bg-white sm:w-[23%] w-[80%] h-[150px] p-4'>
              <h2 className='text-orange-600 mb-3 text-2xl counter-text'>Ads & Events</h2>
              <p className='text-2xl mb-3 font-bold'>1</p>
              <p className='text-green-400'>5.27%</p>
              <a href='/admin/ads' className='text-gray-400 underline'>Go to Ads</a>
            </div>
          </div>
          {/* <div className='sm:w-[60%] sm:block hidden shadow'>
            <SiteVisitsChart />
          </div> */}
        </div>
        <div className='w-full shadow px-10 mt-5 sm:flex hidden gap-4 justify-between'>
          <div className='w-[100%]'>
            <div className='flex justify-between'>
            <h2 className='font-bold sm:text-xl text-sm mb-3'>Recent Supplier Registrations</h2>
            <a href="/admin/suppliers" className='text-orange-600 underline sm:flex hidden items-center justify-center gap-1'>
              Go to suppliers
              <FaArrowCircleRight />
            </a>
            </div>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg overflow-y-auto">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 overflow-y-auto">
                    <thead className="text-xs text-gray-700 uppercase bg-[#fff]">
                        <tr>
                            <th scope="col" className="p-4">
                                <div className="flex items-center">
                                    <input id="checkbox-all-search" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                    <label for="checkbox-all-search" className="sr-only">checkbox</label>
                                </div>
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Name
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Company Name
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Status
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {recentSuppliers && recentSuppliers.map((supplier) => (
                            <tr className="bg-white border-b hover:bg-gray-50" key={supplier._id}>
                                <td className="w-4 p-4">
                                    <div className="flex items-center">
                                        <input id="checkbox-table-search-1" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                        <label for="checkbox-table-search-1" className="sr-only">checkbox</label>
                                    </div>
                                </td>
                                <th scope="row" className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap">
                                    <img className="w-10 h-10 rounded-full" src={profile} alt="Jese images"/>
                                    <div className="ps-3">
                                        <div className="text-base font-semibold">{supplier.firstName} {supplier.lastName}</div>
                                        <div className="font-normal text-gray-500">{supplier.email}</div>
                                    </div>  
                                </th>
                                <td className="px-6 py-4">
                                    <div className='flex'>
                                        <span class="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full">{supplier.email}</span>
                                    </div>
                                </td>
                                <td className="px-6 py-4">
                                    <div className="flex items-center">
                                        <span class="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded  border border-red-400">{supplier.status}</span>
                                    </div>
                                </td>
                                <td className="px-6 py-4">
                                    <div className='flex items-center justify-center gap-2'>
                                    <div className=''>
                                        <button onClick={() => toggleModal(supplier)} className="text-green-500" type="button">Approve</button>
                                    </div>
                                    <div className=''>
                                        <button onClick={() => toggleRejectModal(supplier)} className="text-red-500" type="button">Reject</button>
                                    </div>
                                    </div>
                                </td>
                            </tr>
                        ))}       
                    </tbody>
                </table>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default Dashboard