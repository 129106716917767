import React, { useEffect, useState } from 'react'
import { FaArrowAltCircleRight } from 'react-icons/fa'
import AdminHeadbar from './components/AdminHeadbar'
import AdminSidebar from './components/AdminSidebar'
import axios from 'axios'
import { toast } from 'react-toastify'
import { server } from '../../server'

const Maintenance = () => {
    const [isChecked, setIsChecked] = useState(false);
    const [formData, setFormData] = useState({
        isActive: false,
        title: '',
        description: '',
    });

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get(`${server}/maintenance`);
            const data = response.data[0];
            console.log(data);
            setFormData({
                isActive: data.isActive,
                title: data.title,
                description: data.description,
            });
            setIsChecked(data.isActive); // Assuming isActive is a boolean value
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
        setFormData({ ...formData, isActive: !isChecked });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post(`${server}/maintenance`, formData);
            toast.success('Maintenance settings updated successfully!');
        } catch (error) {
            toast.error('An error occurred. Please try again.');
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

  return (
    <div className='flex'>
      <div className='w-[20%]'>
        <AdminSidebar />
      </div>
      <main className='w-[80%]'>
        <AdminHeadbar />
        <form className='p-[40px] bg-[#f8f9fc]' onSubmit={handleSubmit}>
            <div className='border-b-2 border-dashed py-[30px] flex items-center'>
                <span className='font-medium text-xl'>Maintenance Settings</span>
            </div>
            <div className='flex p-[30px] border-b-2 border-dashed'>
                <div className='basis-[30%]'>
                    <h2 className='font-semibold mb-4'>Activate</h2>
                    <p className='text-gray-600'>Change your site maintenance information from here</p>
                </div>
                <div className='basis-[70%] bg-white p-10'>
                    <div>
                        <label className='autoSaverSwitch relative inline-flex cursor-pointer select-none items-center'>
                            <input
                            type='checkbox'
                            name='isActive'
                            className='sr-only'
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                            />
                            <span
                            className={`slider mr-3 flex h-[26px] w-[50px] items-center rounded-full p-1 duration-200 ${
                                isChecked ? 'bg-orange-600' : 'bg-[#CCCCCE]'
                            }`}
                            >
                            <span
                                className={`dot h-[18px] w-[18px] rounded-full bg-white duration-200 ${
                                isChecked ? 'translate-x-6' : ''
                                }`}
                            ></span>
                            </span>
                            <span className='label flex items-center text-sm font-medium text-black'>
                            Maintenance Mode <span className='pl-1'> {isChecked ? 'On' : 'Off'} </span>
                            </span>
                        </label>
                    </div>
                </div>
            </div>
           
            <div className='flex p-[30px]'>
                <div className='basis-[30%]'>
                    <h2 className='font-semibold mb-4'>Maintenance information</h2>
                    <p className='text-gray-600'>Change your site maintenance information from here.</p>
                </div>
                <div className='basis-[70%] bg-white p-10'>
                    <div class="mb-5">
                        <label for="name" class="block mb-2 text-sm font-medium text-gray-900">Maintenance Title</label>
                        <input type="text" name='title' value={formData.title} onChange={handleChange} id="name" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                    </div>
                    <div class="mb-5">
                        <label for="name" class="block mb-2 text-sm font-medium text-gray-900">Maintenance Description</label>
                        <textarea name="description" value={formData.description} onChange={handleChange} id="" cols="30" rows="10"  class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"></textarea>
                    </div>
                </div>
            </div>
            <div className='w-full flex justify-between items-center'>
                <a href='/' className='flex items-center gap-1 text-orange-600'>
                    <FaArrowAltCircleRight />
                    go to site
                </a>
                <div className='flex gap-2'>
                    <button type="submit" class="border border-orange-600 hover:bg-orange-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Reset</button>
                    <button type="submit" class="text-white bg-orange-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Update Settings</button>
                </div>
            </div>
        </form>
        </main>
    </div>
  )
}

export default Maintenance