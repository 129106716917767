import React from 'react';
import { FaPhone } from 'react-icons/fa6';
import logo from '../assets/user.png'
import { FaUserTie } from "react-icons/fa";
import { CiShop } from "react-icons/ci";
import { FaLocationDot } from "react-icons/fa6";

const ResellerView = ({ reseller, closeModal }) => {
    return (
        <div>
            <div className='p-5 w-[400px] rounded-md shadow-md bg-white'>
                <div className='flex gap-2 border-b-2 border-dashed mb-3 pb-4'>
                    <div className='w-[50px] h-[50px] rounded-full bg-white border flex items-center justify-center border-blue-500'>
                        <img src={logo} alt='Reseller Avatar' className='w-[90%] h-[90%] rounded-full' />
                    </div>
                    <div>
                        <h1>{`${reseller.firstName} ${reseller.lastName}`}</h1>
                        <p>{reseller.email}</p>
                    </div>
                </div>
                <div>
                    <div className='flex justify-between text-gray-400 mb-3'>
                        <p className='flex gap-2 items-center justify-center'>
                            <FaPhone />
                            Phone :
                        </p>
                        <p>{reseller.phoneNumber}</p>
                    </div>
                    <div className='flex justify-between text-gray-400 mb-3'>
                        <p className='flex gap-2 items-center justify-center'>
                          <FaUserTie />
                            Role :
                        </p>
                        <p>{reseller.roles}</p>
                    </div>
                    <div className='flex justify-between text-gray-400 mb-3'>
                        <p className='flex gap-2 items-center justify-center'>
                            <CiShop />
                            Company :
                        </p>
                        <p>{reseller.companyName}</p>
                    </div>
                    <div className='flex justify-between text-gray-400 mb-3'>
                        <p className='flex gap-2 items-center justify-center'>
                            <FaLocationDot />
                            Address :
                        </p>
                        <p>{reseller.address}</p>
                    </div>
                    <div className="flex items-center justify-between">
                      <a href={`/admin/edit-reseller/${reseller._id}`} className='bg-orange-600 text-center w-[48%] text-white px-3 p-1 rounded-sm'>Edit Details</a>
                      <button className='bg-red-600 w-[48%] text-white px-3 p-1 rounded-sm' onClick={closeModal}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResellerView;
