// components/AdminChangePasswordForm.js

import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import AdminSidebar from './components/AdminSidebar';
import AdminHeadbar from './components/AdminHeadbar';
import { server } from '../../server';

const ChangePassword = () => {
    const {id} = useParams()
    const [formData, setFormData] = useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Send password change request to the server
            await axios.put(`${server}/admins/change-password/${id}`, formData);
            toast.success('Password updated successfully');
            setFormData({
                oldPassword: '',
                newPassword: '',
                confirmPassword: '',
            });
        } catch (error) {
            console.error('Error changing password:', error);
            toast.error('Failed to update password');
        }
    };

    return (
        
    <div className='flex'>
      <div className='w-[20%]'>
        <AdminSidebar />
      </div>
      <main className='w-[80%]'>
        <AdminHeadbar />
        <div className='p-[40px] bg-[#f8f9fc]'>
            <div className='border-b-2 border-dashed py-[30px] flex items-center'>
                <span className='font-medium text-xl'>Edit Account</span>
            </div>
            <div className='flex p-[30px]'>
                <div className='basis-[40%]'>
                    <h2 className='font-semibold mb-4'>Information</h2>
                    <p className='text-gray-600'>Edit your account information here</p>
                </div>
                <div className='basis-[60%] bg-white p-6'>
                    <form onSubmit={handleSubmit} class="max-w-sm mx-auto">
                        <div class="mb-5">
                            <label for="name" class="block mb-2 text-sm font-medium text-gray-900">Old Password</label>
                            <input type="password" id="name" name='oldPassword' value={formData.oldPassword} onChange={handleChange}class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div class="mb-5">
                            <label for="name" class="block mb-2 text-sm font-medium text-gray-900">New Password</label>
                            <input type="password" id="name" name='newPassword' value={formData.newPassword} onChange={handleChange} class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div class="mb-5">
                            <label for="name" class="block mb-2 text-sm font-medium text-gray-900">Confirm New Password</label>
                            <input type="password" id="name" name='confirmPassword' value={formData.confirmPassword} onChange={handleChange} class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        
                        <button type="submit" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Edit Password</button>
                    </form>
                </div>
            </div>
        </div>
        </main>
    </div>
    );
};

export default ChangePassword;
