import React, { useState } from 'react';
import axios from 'axios';
import { server } from '../../server';
import { useAuth } from '../../middleware/AuthContext';
import { read, utils } from 'xlsx';
import { toast } from 'react-toastify';
import { FaArrowAltCircleRight } from 'react-icons/fa';
import SupplierSidebar from './components/SupplierSidebar'
import SupplierHeadbar from './components/SupplierHeadbar'

const requiredFields = ['sku', 'name', 'category', 'price', 'brand', 'description', 'warranty']

const BulkProductUpload = () => {
  const [file, setFile] = useState(null);
  const [auth, setAuth] = useAuth();
  const supplier = auth.supplier;
  const [excelRows, setExcelRows] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };


  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const file = e.target.files[0];
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[sheetName];
        const json = utils.sheet_to_json(worksheet);
        console.log('json', json);
        setExcelRows(json);
      };
      reader.readAsArrayBuffer(file);
    }
  }


  const handleDownloadTemplate = async () => {
    try {
      // Send a GET request to download the template
      const response = await axios.get(`${server}/products/download-template`, {
        responseType: 'blob',
      });

      // Create a blob URL for the template file
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Construct the file name with supplier ID
      const fileName = `product_template.csv`;

      // Create a link element and click it to download the template
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      // If an error occurs, display an error message to the user
      alert('An error occurred while downloading the template.');
      console.error(error);
    }
  };

  const uploadData = async (e) => {
    e.preventDefault();
    try {
      const firstItemKeys = excelRows[0] && Object.keys(excelRows[0]);
      let requiredValidation = false;

      if (firstItemKeys.length) {
        requiredFields.forEach((element) => {
          if (!firstItemKeys.find((x) => x === element)) {
            requiredValidation = true;
          }
        })
      }

      if (requiredValidation) {
        alert("Required fields" + JSON.stringify(requiredFields))
        return;
      }

      const productsResponse = (
        await axios.get(`${server}/products/get-products-supplier/${supplier._id}`)
      ).data;
      const productList = productsResponse || [];

      const products = excelRows.map(obj => ({
        // _id: productList.find(x => x.productId == obj["id"])?._id,
        supplier: supplier._id, // Include supplierId
        sku: obj["sku"] || "",
        name: obj["name"] || "",
        category: obj["category"] || "",
        price: obj["price"] || "",
        brand: obj["brand"] || "",
        description: obj["description"] || "",
        warranty: obj["warranty"] || "",
      }))

      const newProducts = products.filter(x => !x._id)
      if (newProducts.length) {
        const result = (
          await axios.post(`${server}/products/upload-bulk`, newProducts)
        ).data;
        if (result) {
          toast.success("Successfully uploaded " + newProducts.length + " products")
        }
      }
      // fetchData()

    } catch (error) {
      console.log(`uploadedData error: `, error)
    }
  }


  return (
    <div className='flex'>
      <div className='w-[20%]'>
        <SupplierSidebar />
      </div>
      <main className='w-[80%]'>
        <SupplierHeadbar />
        <form className='sm:p-[40px] p-[20px] bg-[#f8f9fc]' onSubmit={uploadData}>
          <div className='border-b-2 border-dashed py-[30px] flex items-center'>
            <span className='font-medium text-xl'>Upload Bulk Products</span>
          </div>
          <div className='sm:flex sm:p-[30px] border-b-2 border-dashed'>
            <div className='sm:basis-[30%] basis-[100%] sm:mb-0 mb-4'>
              <h2 className='font-semibold mb-4'>Upload multiple products</h2>
              <p className='text-gray-600'>Download the template and insert data.Then upload the template once more</p>
            </div>
            <div className='sm:basis-[70%] bg-white sm:p-10 p-4'>
              <div className='mx-auto'>
                <div className="mb-5">
                  <button className='bg-orange-600 text-white p-3 rounded-md' onClick={handleDownloadTemplate}>Download Template</button>
                </div>
                <div className="mb-5">
                  <input
                    type="file"
                    name='file'
                    id='inputBulkProducts'
                    accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                    onChange={readUploadFile} />
                </div>
              </div>
            </div>
          </div>

          <div className='w-full sm:flex justify-between items-center'>
            <a href='/supplier/products' className='flex items-center gap-1 text-orange-600'>
              <FaArrowAltCircleRight />
              Go to products
            </a>
            <div className='flex gap-2'>
              <a href="/suppliers/products" className="border border-orange-600 hover:bg-orange-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Cancel</a>
              <button type='submit' className="text-white bg-orange-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Upload Products</button>
            </div>
          </div>
        </form>
      </main>
    </div>
  );
};

export default BulkProductUpload;
