import React, { useEffect, useState } from 'react'
import { FaFacebook, FaInstagram, FaLocationDot, FaPhone, FaTwitter } from 'react-icons/fa6'
import logo from '../assets/GooglePlayStore.png'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import Footer from '../components/Footer'
import MainNav from '../components/MainNav'
import { useAuth } from '../middleware/AuthContext'
import { toast } from 'react-toastify'
import { server } from '../server'

const ResellerProfile = () => {
    const { id } = useParams();
    const [reseller, setReseller] = useState(
        {
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            companyName: '',
            country: '',
            address: '',
        }
    );
    const [auth, setAuth] = useAuth();
    const navigate = useNavigate()


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setReseller(prevState => ({
            ...prevState,
            [name]: value
        }));
    };


    useEffect(() => {
        const fetchReseller = async () => {
            try {
                const response = await axios.get(`${server}/resellers/get-reseller/${id}`);
                setReseller(response.data.reseller);
            } catch (error) {

            }
        };
        fetchReseller();
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Send updated data to the server
            await axios.put(`${server}/resellers/update-reseller/${id}`, reseller);
            // Optionally, you can fetch the updated data again to reflect changes in the UI
            toast.success('Data updated successfully!');
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        } catch (error) {
            console.error('Error updating reseller:', error);
            toast.error(error);
        }
    };

    const handleLogout = () => {
        setAuth({
            ...auth,
            reseller: null,
            token: "",
        });
        localStorage.removeItem("auth");
        toast.success("Logout Successfully");
        navigate('/')
    };
    return (
        <div>   
            <MainNav />
            <div className='sm:h-[150px] h-[100px] top-section bg-red-500 mb-3'>
                <div className='top-overlay px-16' style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "end" }}>
                    <h1 className='sm:text-6xl text-3xl text-center text-orange-600 font-bold mb-5 counter-text'>My Profile</h1>
                </div>
            </div>
            <div>
                <div className='sm:flex w-full sm:py-[40px] sm:px-[40px] p-[20px] justify-between'>
                    <div className='sm:w-[30%] w-[100%] sm:mb-0 mb-4 bg-white shadow-md rounded-md p-4 h-fit'>
                        <div className='flex gap-4 border-b-2 pb-3'>
                            <div className='w-[70px] h-[70px] rounded-full border-blue-600 bg-white border flex items-center justify-center'>
                                <img src={logo} alt="" className='p-1 w-[90%] h-[90%] rounded-full bg-slate-300' />
                            </div>
                            <div>
                                <p className='text-gray-400 text-sm'>Since 2023</p>
                                <h1 className='text-lg text-gray-900 font-medium'>{reseller.firstName} {reseller.lastName}</h1>
                                <p className='text-gray-500'>{reseller.email}</p>
                            </div>
                        </div>
                        <div className='py-5'>
                            <div className='flex items-center gap-3 mb-3'>
                                <FaLocationDot />
                                <p>{reseller.address}</p>
                            </div>
                            <div className='flex items-center gap-3 mb-3'>
                                <FaPhone />
                                <p>{reseller.phoneNumber}</p>
                            </div>
                            <div className='flex items-center gap-3 mb-3'>
                                <FaLocationDot />
                                <p>{reseller.country}</p>
                            </div>
                        </div>
                        <div className='flex items-center justify-between'>
                            <a href={`/edit-password/${reseller._id}`} className='w-[48%] h-[40px] text-white bg-orange-300 rounded-md' style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                Change Password
                            </a>
                            <button onClick={handleLogout} className='w-[48%] h-[40px] text-white bg-red-300 rounded-md' style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                Logout
                            </button>

                        </div>
                    </div>
                    <div className='sm:w-[68%] w-[100%]'>
                        <form className='gap-4 flex bg-white shadow-md p-[30px]' onSubmit={handleSubmit}>
                            <div className='w-[100%]'>
                                <div className='mb-3'>
                                    <h1 className='text-xl font-medium'>Edit Personal Information</h1>
                                    <p className='text-lg sm:block hidden'>Fill the form below or write us. We will help you create your account</p>
                                </div>
                                <div className='border-b-2 mb-4'>
                                    <div className='sm:flex flex-wrap gap-2 w-full items-center justify-center mb-4'>
                                        <div className='sm:w-[48%] sm:mb-0 mb-4'>
                                            <label className='mb-3'>First Name*</label>
                                            <input type="text" name='firstName' value={reseller.firstName} onChange={handleInputChange} placeholder=' First Name of registering person' class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                                        </div>
                                        <div className='sm:w-[48%] sm:mb-0 mb-4'>
                                            <label className='mb-3'>Last Name*</label>
                                            <input type="text" name='lastName' value={reseller.lastName} onChange={handleInputChange} placeholder='Last Name of registering person' class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                                        </div>
                                        <div className='sm:w-[48%] sm:mb-0 mb-4'>
                                            <label className='mb-3'>Email Address*</label>
                                            <input type="email" name='email' value={reseller.email} onChange={handleInputChange} placeholder='Email Address of registering person' class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                                        </div>
                                        <div className='sm:w-[48%] sm:mb-0 mb-4'>
                                            <label className='mb-3'>Phone Number*</label>
                                            <input type="tel" name='phoneNumber' value={reseller.phoneNumber} onChange={handleInputChange} placeholder='Phone Number of registering person eg +2547..' class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                                        </div>
                                    </div>
                                </div>
                                <div className='mb-3'>
                                    <h1 className='text-xl font-medium'>Company Information</h1>
                                    <p className='text-lg sm:block hidden'>Fill the form below or write us. We will help you create your account</p>
                                </div>
                                <div className=''>
                                    <div className='sm:flex sm:flex-wrap sm:gap-2 w-full sm:items-center sm:justify-center'>
                                        <div className='sm:w-[48%] sm:mb-0 mb-4'>
                                            <label className='mb-3'>Company Name*</label>
                                            <input type="text" name='companyName' value={reseller.companyName} onChange={handleInputChange} placeholder='Enter Company Name' class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                                        </div>
                                        <div className='sm:w-[48%] sm:mb-0 mb-4'>
                                            <label className='mb-3'>Country*</label>
                                            <input type="text" name='country' placeholder='Country' value={reseller.country} onChange={handleInputChange} class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                                        </div>
                                        <div className='sm:w-[48%] sm:mb-0 mb-4'>
                                            <label className='mb-3'>Address*</label>
                                            <input type="text" name='address' value={reseller.address} onChange={handleInputChange} placeholder='Boulevard St. AMbank Building' class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                                        </div>
                                        <div className='w-full' style={{ display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center" }}>
                                            <button type='submit' className='bg-orange-600 text-white rounded-md p-2'>Update Account</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>

                <Footer />
                <div className='w-[100%] px-10 text-black sm:flex hidden items-center justify-between'>
                    <p className='text-sm'> <span>&copy;</span> Reseller Sprint. All rights reserved.</p>
                    <div className='flex gap-2 items-center'>
                        <a href="/privacy" className='underline text-sm text-orange-600'>Terms & conditions</a>
                        <a href="/privacy" className='underline text-sm text-orange-600'>Privacy Policy</a>
                    </div>
                    <div className='flex items-center gap-3'>
                        <FaFacebook size={18} color='#4267B2' />
                        <FaInstagram size={18} color='#cd486b' />
                        <FaTwitter size={18} color='#1DA1F2' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResellerProfile