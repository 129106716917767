import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { FaArrowAltCircleRight } from 'react-icons/fa'
import { toast } from 'react-toastify';
import { server } from '../../server';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../middleware/AuthContext';
import SupplierSidebar from './components/SupplierSidebar';
import SupplierHeadbar from './components/SupplierHeadbar';

const SupplierEditProduct = () => {
    const [auth, setAuth] = useAuth();
    const supplier = auth.supplier;
    const {id} = useParams()
    const [product, setProduct] = useState(
        {   
            supplierId: supplier._id,
            sku: '',
            name: '',
            category: '',
            price: '',
            brand: '',
            warranty: '',
            description: '',
            status: 'available',
            isFeatured: true
        }
    )
    const [categories, setCategories] = useState([]);
    const [brands, setBrands] = useState([]);

    useEffect(() => {
        const fetchCategoriesAndBrands = async () => {
            try {
                const categoriesResponse = await axios.get(`${server}/categories`);
                setCategories(categoriesResponse.data);

                const brandsResponse = await axios.get(`${server}/brands`);
                setBrands(brandsResponse.data);
            } catch (error) {
                console.error('Error fetching categories and brands:', error);
            }
        };

        fetchCategoriesAndBrands();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProduct(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Send updated data to the server
            await axios.put(`${server}/products/update-product/${id}`, product);
            // Optionally, you can fetch the updated data again to reflect changes in the UI
            toast.success('Data updated successfully!');
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        } catch (error) {
            console.error('Error updating product:', error);
            toast.error({error});
        }
    };

    useEffect(() => {
        const fetchProduct = async () => { 
          try {
            const response = await axios.get(`${server}/products/get-product/${id}`);
            setProduct(response.data.product);
            
          } catch (error) {
            
          } 
        };
        fetchProduct();
      }, [id]);
  return (
    <div className='flex'>
      <div className='w-[20%]'>
        <SupplierSidebar />
      </div>
      <main className='w-[80%]'>
        <SupplierHeadbar />
        <form className='sm:p-[40px] p-[10px] bg-[#f8f9fc]' onSubmit={handleSubmit}> 
            <div className='border-b-2 border-dashed py-[30px] flex items-center'>
                <span className='font-medium text-xl'>Edit Product</span>
            </div>
            <div className='sm:flex sm:p-[30px] border-b-2 border-dashed'>
                <div className='sm:basis-[30%] basis-[100%]'>
                    <h2 className='font-semibold mb-4'>Information</h2>
                    <p className='text-gray-600'>Edit product information</p>
                </div>
                <div className='sm:basis-[70%] basis-[100%] bg-white sm:p-10 p-2'>
                    <div className=" mx-auto">
                        <div className="mb-5">
                            <label for="name" class="block mb-2 text-sm font-medium text-gray-900">Product Name</label>
                            <input type="name" id="name" name='name' value={product.name} onChange={handleChange} class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div className="mb-5">
                            <label for="price" class="block mb-2 text-sm font-medium text-gray-900">Price(USD)</label>
                            <input type="number" id="price" name='price' value={product.price} onChange={handleChange}  class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="" required />
                        </div>
                        <div className="mb-5">
                            <label for="sku" class="block mb-2 text-sm font-medium text-gray-900">Serial Number(SKU)</label>
                            <input type="text" id="sku" name='sku' value={product.sku} onChange={handleChange}  class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                        <div className='mb-5'>
                            <label for="description" class="block mb-2 text-sm font-medium text-gray-900">Description</label>
                            <textarea name="description" value={product.description} onChange={handleChange}  id="description" cols="30" rows="10" class="shadow-sm bg-gray-50 border outline-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"></textarea>
                        </div>
                        <div class="flex items-start mb-5">
                            <div class="flex items-center h-5">
                            <input id="terms" type="checkbox" name='isFeatured' checked={product.isFeatured} onChange={() => setProduct({ ...product, isFeatured: !product.isFeatured })} class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300" required />
                            </div>
                            <label for="terms" class="ms-2 text-sm font-medium text-gray-900 mr-2">isFeatured?</label>
                            
                        </div>
                    </div> 
                </div>
            </div>
            <div className='sm:flex sm:p-[30px]'>
                <div className='sm:basis-[30%] basis-[100%]'>
                    <h2 className='font-semibold mb-4'>Groups & Categories</h2>
                    <p className='text-gray-600'>Select product group and categories from here</p>
                </div>
                <div className='sm:basis-[70%] basis-[100%] bg-white sm:p-10 p-2'>
                    <div className=" mx-auto">
                        <div className="mb-5">
                            <label for="name" class="block mb-2 text-sm font-medium text-gray-900">Category</label>
                            <select name="category" id="" value={product.category} onChange={handleChange} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                {categories && categories.map((category) => (
                                    <option value={category.name}>{category.name}</option>
                                ))}                                
                            </select>
                        </div>
                        <div className="mb-5">
                            <label for="name" class="block mb-2 text-sm font-medium text-gray-900">Brand</label>
                            <select name="brand" id="" value={product.brand} onChange={handleChange} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                            {brands && brands.map((brand) => (
                                    <option value={brand.name}>{brand.name}</option>
                                ))}  
                            </select>
                        </div>
                        <div className="mb-5">
                            <label for="name" class="block mb-2 text-sm font-medium text-gray-900">Availability</label>
                            <select name="status" id="" value={product.status} onChange={handleChange} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                <option value="available">Available</option>
                                <option value="limited">Limited</option>
                                <option value="unavailable">Unavailable</option>
                            </select>
                        </div>
                        <div className="mb-5">
                            <label for="warranty" class="block mb-2 text-sm font-medium text-gray-900">Warranty(Months)</label>
                            <input type="number" name="warranty" value={product.warranty} onChange={handleChange} placeholder='12' id="warranty" class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                        </div>
                    </div> 
                </div>
            </div>
            <div className='w-full flex justify-between items-center'>
                <a href='/supplier/products' className='sm:flex hidden items-center gap-1 text-orange-600'>
                    <FaArrowAltCircleRight />
                    Go to products
                </a>
                <div className='flex gap-2'>
                    <a href="/suppliers/products" type="submit" class="border border-orange-600 hover:bg-orange-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Go Back</a>
                    <button type="submit" class="text-white bg-orange-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Edit Product</button>
                </div>
            </div>
        </form>
        </main>
    </div>
  )
}

export default SupplierEditProduct