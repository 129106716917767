import React, { useEffect, useState } from 'react'
import { FaEye, FaPlus } from 'react-icons/fa6'
import AdminSidebar from './components/AdminSidebar'
import AdminHeadbar from './components/AdminHeadbar'
import profile from '../../assets/GooglePlayStore.png'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import axios from 'axios'
import { server } from '../../server'
import ResellerView from '../../components/ResellerView'

const Resellers = () => {
    const [resellers, setResellers] = useState([]);
    const [selectedReseller, setSelectedReseller] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isRejectModalOpen, setRejectModalOpen] = useState(false);
    const [isReviewModalOpen, setReviewModalOpen] = useState(false);
    const [isHoldModalOpen, setHoldModalOpen] = useState(false);
    const [isApproveModalOpen, setApproveModalOpen] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

    useEffect(() => {
        const fetchResellers = async () => { 
            try {
                const response = await axios.get(`${server}/resellers/get-resellers`);
                setResellers(response.data);
            } catch (error) {
                console.error(error);
            } 
        };
        
        fetchResellers();
    }, []);

    const handleApprove = async () => {
        try {
            const response = await axios.patch(`${server}/resellers/approve-reseller/${selectedReseller._id}`);
            toast.success(response.data.message);
            setIsModalOpen(false);
            window.location.reload();
        } catch (error) {
            toast.error('Failed to approve reseller');
        }
    };
    const handleReject = async () => {
        try {
            const response = await axios.patch(`${server}/resellers/reject-reseller/${selectedReseller._id}`);
            toast.success(response.data.message);
            setIsModalOpen(false);
            window.location.reload();
        } catch (error) {
            toast.error('Failed to reject reseller');
        }
    };
    const handleDelete = async () => {
        try {
            const response = await axios.delete(`${server}/resellers/delete-reseller/${selectedReseller._id}`);
            toast.success(response.data.message);
            setDeleteModalOpen(false)
            window.location.reload();
        } catch (error) {
            toast.error('Failed to delete reseller');
        }
    };
    const handleHold = async () => {
        try {
            const response = await axios.patch(`${server}/resellers/hold-reseller/${selectedReseller._id}`);
            toast.success(response.data.message);
            setHoldModalOpen(false)
            window.location.reload();
        } catch (error) {
            toast.error('Failed to reject supplier');
        }
    };

    const getStatusColor = (status) => {
        switch(status) {
            case 'Approved':
                return 'green';
            case 'Not approved':
                return 'blue';
            case 'On Hold':
                return 'orange';
            case 'Rejected':
                return 'red';
            default:
                return 'gray';
        }
    };
    
    const getActionButtons = (reseller) => {
        switch(reseller.status) {
            case 'Approved':
                return (
                    <>
                        <button onClick={() => toggleHoldModal(reseller)} className="text-blue-500" type="button">Hold</button>
                        <button onClick={() => toggleDeleteModal(reseller)} className="text-red-500" type="button">Delete</button>
                    </>
                );
            case 'Not approved':
                return (
                    <>
                        <button onClick={() => toggleApproveModal(reseller)} className="text-green-500" type="button">Approve</button>
                        <button onClick={() => toggleRejectModal(reseller)} className="text-red-500" type="button">Reject</button>
                    </>
                );
            case 'On Hold':
                return (
                    <>
                        <button onClick={() => toggleApproveModal(reseller)} className="text-green-500" type="button">Approve</button>
                        <button onClick={() => toggleDeleteModal(reseller)} className="text-red-500" type="button">Delete</button>
                    </>
                );
            case 'Rejected':
                return (
                    <>
                        <button onClick={() => toggleApproveModal(reseller)} className="text-green-500" type="button">Approve</button>
                        <button onClick={() => toggleDeleteModal(reseller)} className="text-red-500" type="button">Delete</button>
                    </>
                );
            default:
                return null;
        }
    };

    const toggleModal = (reseller) => {
        setIsModalOpen(!isModalOpen);
        setSelectedReseller(reseller)
    };

    const toggleRejectModal = (reseller) => {
        setRejectModalOpen(!isRejectModalOpen);
        setSelectedReseller(reseller)
    }

    const toggleViewModal = (reseller) => {
        setReviewModalOpen(!isReviewModalOpen);
        setSelectedReseller(reseller)
    }

    const toggleApproveModal = (reseller) => {
        setApproveModalOpen(!isApproveModalOpen);
        setSelectedReseller(reseller)
    }

    const toggleHoldModal = (reseller) => {
        setHoldModalOpen(!isHoldModalOpen);
        setSelectedReseller(reseller)
    }

    const toggleDeleteModal = (reseller) => {
        setDeleteModalOpen(!isDeleteModalOpen);
        setSelectedReseller(reseller)
    }

  return (
    <div className='flex relative'>
      <div className='w-[20%]'>
        <AdminSidebar />
      </div>
      <main className='w-[80%]'>
      <AdminHeadbar />
        <div className='m-[30px] bg-[#f8f9fc]'>
        <div className='bg-[#fff] flex justify-between p-[20px] items-center mb-10'>
            <div className='border-l-4 border-orange-500 rounded-lg p-4'>
                <span className='text-2xl font-semibold'>Resellers</span>
            </div>
            <div className='flex justify-between gap-[75px] w-[700px]'>   
                <form className="w-full">   
                    <label for="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only">Search</label>
                    <div className="relative">
                        <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                            <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                            </svg>
                        </div>
                        <input type="search" id="default-search" className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-orange-500 focus:border-orange-500" placeholder="Search by name..." required />
                        <button type="submit" className="text-white absolute end-2.5 bottom-2.5 bg-orange-700 hover:bg-orange-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2">Search</button>
                    </div>
                </form>
                <Link to='../add-admin' className='flex items-center justify-center bg-orange-600 gap-4 rounded-xl w-[200px] text-white'>
                    <FaPlus />
                    Add Reseller
                </Link>
            </div>
        </div>
        <div className='bg-[#fff] h-[70vh]'>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg overflow-y-auto">         
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 overflow-y-auto">
                    <thead className="text-xs text-gray-700 uppercase bg-[#fff]">
                        <tr>
                            <th scope="col" className="p-4">
                                <div className="flex items-center">
                                    <input id="checkbox-all-search" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                    <label for="checkbox-all-search" className="sr-only">checkbox</label>
                                </div>
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Name
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Email
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Status
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {resellers && resellers.map((reseller) => (
                            <tr className="bg-white border-b hover:bg-gray-50" key={reseller._id}>
                                <td className="w-4 p-4">
                                    <div className="flex items-center">
                                        <input id="checkbox-table-search-1" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                        <label for="checkbox-table-search-1" className="sr-only">checkbox</label>
                                    </div>
                                </td>
                                <th scope="row" className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap">
                                    <img className="w-10 h-10 rounded-full" src={profile} alt="Jese images"/>
                                    <div className="ps-3">
                                        <div className="text-base font-semibold">{reseller.firstName} {reseller.lastName}</div>
                                        <div className="font-normal text-gray-500">{reseller.email}</div>
                                    </div>  
                                </th>
                                <td className="px-6 py-4">
                                    <div className='flex'>
                                        <span class="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full">{reseller.email}</span>
                                    </div>
                                </td>
                                <td className="px-6 py-4">
                                    <div className="flex items-center">
                                        <span className={`bg-${getStatusColor(reseller.status)}-100 text-${getStatusColor(reseller.status)}-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded border border-${getStatusColor(reseller.status)}-400`}>{reseller.status}</span>
                                    </div>
                                </td>
                                <td className="px-6 py-4">
                                    <div className='flex items-center justify-center gap-2'>
                                    {getActionButtons(reseller)}
                                    <button href="/" data-twe-toggle="tooltip" title="View User">
                                        <FaEye color='blue' size={20} onClick={() => toggleViewModal(reseller)} />
                                    </button>
                                    </div>
                                </td>
                            </tr>
                        ))}       
                    </tbody>
                </table>
            </div>
        </div>
        </div>
      </main>
      {isReviewModalOpen && (
                <div tabIndex="-1" className="fixed top-[200px] left-[40%] z-50 justify-center items-center w-full">
                    <div className="relative p-4 w-full max-w-md max-h-full">
                        {/* Render the ResellerView component and pass the selected reseller data */}
                        <ResellerView reseller={selectedReseller} closeModal={toggleViewModal} />
                        {/* Close button or any other controls to close the modal */}
                        <button onClick={() => setIsModalOpen(false)}>Close Modal</button>
                    </div>
                </div>
            )}
        {isApproveModalOpen && (
                <div id="popup-modal" tabIndex="-1" className="fixed top-[100px] left-[40%] z-50 justify-center items-center w-full">
                    <div className="relative p-4 w-full max-w-md max-h-full">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <button type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={toggleModal}>
                                <svg className="w-3 h-3" onClick={toggleApproveModal} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                            <div className="p-4 md:p-5 text-center">
                                <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                                </svg>
                                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to approve this reseller account?</h3>
                                <button data-modal-hide="popup-modal" type="button" onClick={handleApprove} className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                                    Yes, I'm sure
                                </button>
                                <button data-modal-hide="popup-modal" onClick={toggleApproveModal} type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">No, cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
      {isHoldModalOpen && (
                <div id="popup-modal" tabIndex="-1" className="fixed top-[100px] left-[40%] z-50 justify-center items-center w-full">
                    <div className="relative p-4 w-full max-w-md max-h-full">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <button type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={toggleModal}>
                                <svg className="w-3 h-3" onClick={toggleHoldModal} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                            <div className="p-4 md:p-5 text-center">
                                <svg onClick={toggleHoldModal} className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                                </svg>
                                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to place this account on hold?</h3>
                                <button data-modal-hide="popup-modal" type="button" onClick={handleHold} className="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                                    Yes, I'm sure
                                </button>
                                <button data-modal-hide="popup-modal" onClick={toggleHoldModal} type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">No, cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {isRejectModalOpen && (
                <div id="popup-modal" tabIndex="-1" className="fixed top-[100px] left-[40%] z-50 justify-center items-center w-full">
                    <div className="relative p-4 w-full max-w-md max-h-full">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <button type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={toggleDeleteModal}>
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                            <div className="p-4 md:p-5 text-center">
                                <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                                </svg>
                                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to reject this reseller?</h3>
                                <button data-modal-hide="popup-modal" type="button" onClick={handleReject} className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                                    Yes, I'm sure
                                </button>
                                <button data-modal-hide="popup-modal" onClick={toggleRejectModal} type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">No, cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {isDeleteModalOpen && (
                <div id="popup-modal" tabIndex="-1" className="fixed top-[100px] left-[40%] z-50 justify-center items-center w-full">
                    <div className="relative p-4 w-full max-w-md max-h-full">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <button type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={toggleDeleteModal}>
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                            <div className="p-4 md:p-5 text-center">
                                <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                                </svg>
                                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to delete this reseller?</h3>
                                <button data-modal-hide="popup-modal" type="button" onClick={handleDelete} className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                                    Yes, I'm sure
                                </button>
                                <button data-modal-hide="popup-modal" onClick={toggleDeleteModal} type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">No, cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
    </div>
  )
}

export default Resellers