import React, { useState } from 'react'
import logo from '../assets/ResellerSprint logo.png'
import { RiMenu2Line } from "react-icons/ri";
import { RxCross2 } from "react-icons/rx";

const Navbar = () => {
    let [open, setOpen] =useState(false);

    return (
        <div 
        // className='h-[70px] bg-transparent flex justify-between px-5 sm:px-5'
        // className="shadow-md w-full bg-white sm:absolute"
        className="navbar"
        >
            <div className="h-full md:flex md:items-center md:justify-between py-4 md:px-10 px-7">
                <div>
                    <img src={logo} className='w-[150px] h-[50px] object-contain' alt="" />
                </div>
                <div className="sm:hidden justify-end items-center flex">
                    <div onClick={() => setOpen(!open)} className='absolute right-8 top-6 cursor-pointer md:hidden w-7 h-7'>
                        {open ? <RxCross2 color='black' size={30} /> : <RiMenu2Line color='black' size={30} />}
                    </div>  
                </div>
                <ul
                    // id='navbar'
                    // className='hidden sm:flex text-white gap-5'
                    className={`md:flex md:items-center md:text-black md:gap-5 md:pb-0 pb-12 absolute md:static bg-white md:bg-transparent md:z-auto z-[-1] left-0 text-end w-full md:w-auto md:pl-0 md:pr-0 pl-9 pr-2 transition-all duration-500 ease-in ${open ? 'top-18' : 'top-[-490px]'}`}
                >
                    <li className='nav-item sm:mb-0 mb-10 text-xl sm:text-[15px] hover:text-orange-600'>
                        <a href="/">Home</a>
                    </li>
                    <li className='nav-item sm:mb-0 mb-10 sm:text-[15px] text-xl hover:text-orange-600'>
                        <a href="/about">About Us</a>
                    </li>
                    <li className='nav-item sm:mb-0 mb-10 sm:text-[15px] text-xl hover:text-orange-600'>
                        <a href="/partners">Partners</a>
                    </li>
                    <li className='nav-item sm:mb-0 mb-10 sm:text-[15px] text-xl hover:text-orange-600'>
                        <a href="/privacy">Privacy Policy</a>
                    </li>
                    <li className='nav-item sm:mb-0 mb-10 sm:text-[15px] text-xl hover:text-orange-600 mr-5'>
                        <a href="/faq">FAQ</a>
                    </li>
                    <div className='sm:flex block items-center text-[15px] gap-2 mr-3'>
                        <a href="/login" className='sm:text-black text-black'>Sign In</a>
                    </div>
                    <a href="/signup" className='px-2 bg-orange-600 p-1 rounded-full text-white'>Create An Account</a>
                   
                </ul>
                
            </div>
            
        </div>
    )
}

export default Navbar