import React, { useEffect, useState } from 'react'
import { FaArrowAltCircleRight } from 'react-icons/fa'
import AdminHeadbar from './components/AdminHeadbar'
import AdminSidebar from './components/AdminSidebar'
import axios from 'axios'
import { toast } from 'react-toastify'
import { server } from '../../server'

const General = () => {
    const [isDragOver, setIsDragOver] = useState(false);
    const [previewImages, setPreviewImages] = useState({
        logo: '',
        icon: '',
        backgroundImage: '',
    });
    const [formData, setFormData] = useState({
        siteTitle: '',
        heroTitle: '',
        heroSubTitle: '',
        logo: null,
        icon: null,
        backgroundImage: null,
    });

    useEffect(() => {
        // Fetch existing data from an API endpoint or other source
        const fetchData = async () => {
            try {
                const response = await axios.get(`${server}/general`);
                const existingData = response.data[0]; // Assuming API returns data in the same format as formData
                setFormData(existingData);
                // setPreviewImages({
                //     logo: existingData.logo || '',
                //     icon: existingData.icon || '',
                //     backgroundImage: existingData.backgroundImage || '',
                // });
            } catch (error) {
                console.error('Error fetching existing data:', error.message);
            }
        };

        fetchData();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // const handleDeleteImage = (name) => {
    //     setFormData({ ...formData, [name]: null });
    //     setPreviewImages({ ...previewImages, [name]: '' });
    // };

    const handleFileChange = (name, file) => {
        setFormData({ ...formData, [name]: file[0] });
        displayPreview(name, file[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formDataToSend = new FormData();
            Object.entries(formData).forEach(([key, value]) => {
                formDataToSend.append(key, value);
            });
            await axios.post(`${server}/general`, formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            // Show success message or redirect to another page
            toast.success('General Settings updated successfully!');
        } catch (error) {
            // Handle error
            toast.error('An error occurred. Please try again!');
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        setIsDragOver(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setIsDragOver(false);
    };

    const handleDrop = (e, name) => {
        e.preventDefault();
        setIsDragOver(false);
        const file = e.dataTransfer.files[0];
        handleFileChange(name, [file]);
    };

    const displayPreview = (name, file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setPreviewImages({ ...previewImages, [name]: reader.result });
        };
    };
  return (
    <div className='flex'>
      <div className='w-[20%]'>
        <AdminSidebar />
      </div>
      <main className='w-[80%]'>
        <AdminHeadbar />
        <form className='p-[40px] bg-[#f8f9fc]' onSubmit={handleSubmit}>
            <div className='border-b-2 border-dashed py-[30px] flex items-center'>
                <span className='font-medium text-xl'>General Settings</span>
            </div>
            
            <div className='flex p-[30px] border-b-2 border-dashed'>
                <div className='basis-[30%]'>
                    <h2 className='font-semibold mb-4'>Logo</h2>
                    <p className='text-gray-600'>Upload your site logo from here.
                        Dimension of the logo should be  128x40 Pixel
                        Image size should not be more than  2 MB</p>
                </div>
                <div className='basis-[70%] bg-white p-10'>
                    <div
                        className={`w-full relative border-2 border-gray-300 border-dashed rounded-lg p-6 ${
                            isDragOver ? 'border-indigo-600' : ''
                        }`}
                        // onDragOver={handleDragOver}
                        // onDragLeave={handleDragLeave}
                        // onDrop={(e) => handleDrop(e, 'logo')}
                        >
                        <input
                            type="file"
                            className="absolute inset-0 w-full h-full opacity-0 z-50"
                            name='logo'
                            // value={formData.siteTitle}
                            // onChange={handleFileChange}
                            onChange={(e) => handleFileChange('logo', e.target.files)}
                        />
                        <div className="text-center">
                            <img
                            className="mx-auto h-12 w-12"
                            src="https://www.svgrepo.com/show/357902/image-upload.svg"
                            alt=""
                            />

                            <h3 className="mt-2 text-sm font-medium text-gray-900">
                            <label htmlFor="file-upload" className="relative cursor-pointer">
                                <span>Drag and drop</span>
                                <span className="text-indigo-600"> or browse </span>
                                <span>to upload</span>
                                <input id="file-upload" name="file-upload" type="file" className="sr-only" />
                            </label>
                            </h3>
                            <p className="mt-1 text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                            
                        </div>

                        {previewImages.logo && (
                            <img src={previewImages.logo} className="mt-4 mx-auto max-h-40" alt="Preview" />
                        )}
                        {/* {formData.logo && (
                            <div className="flex justify-center items-center mt-4">
                                <img src={formData.logo} className="max-h-40" alt="Preview" />
                                <button
                                className="ml-4 text-red-500 hover:text-red-700 focus:outline-none"
                                onClick={() => handleDeleteImage('logo')}
                                >
                                <FaTrash />
                                </button>
                            </div>
            )} */}
                    </div>
                </div>
            </div>
            <div className='flex p-[30px] border-b-2 border-dashed'>
                <div className='basis-[30%]'>
                    <h2 className='font-semibold mb-4'>Site Icon</h2>
                    <p className='text-gray-600'>Upload your site icon from here.
                        Dimension of the icon should be  40x40 Pixel
                        Image size should not be more than  2 MB</p>
                </div>
                <div className='basis-[70%] bg-white p-10'>
                <div
                    className={`w-full relative border-2 border-gray-300 border-dashed rounded-lg p-6 ${
                        isDragOver ? 'border-indigo-600' : ''
                    }`}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                    >
                    <input
                        type="file"
                        className="absolute inset-0 w-full h-full opacity-0 z-50"
                        name='icon'
                        onChange={(e) => handleFileChange('icon', e.target.files)}
                    />
                    <div className="text-center">
                        <img
                        className="mx-auto h-12 w-12"
                        src="https://www.svgrepo.com/show/357902/image-upload.svg"
                        alt=""
                        />

                        <h3 className="mt-2 text-sm font-medium text-gray-900">
                        <label htmlFor="file-upload" className="relative cursor-pointer">
                            <span>Drag and drop</span>
                            <span className="text-indigo-600"> or browse </span>
                            <span>to upload</span>
                            <input id="file-upload" name="file-upload" type="file" className="sr-only" />
                        </label>
                        </h3>
                        <p className="mt-1 text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                    </div>

                    {previewImages.icon && (
                        <img src={previewImages.icon} className="mt-4 mx-auto max-h-40" alt="Preview" />
                    )}
                    {/* {formData.icon && (
                        <div className="flex justify-center items-center mt-4">
                            <img src={formData.icon} className="max-h-40" alt="Preview" />
                            <button
                            className="ml-4 text-red-500 hover:text-red-700 focus:outline-none"
                            onClick={() => handleDeleteImage('icon')}
                            >
                            <FaTrash />
                            </button>
                        </div>
        )} */}
                    </div>
                </div>
            </div>
            <div className='flex p-[30px] border-b-2 border-dashed'>
                <div className='basis-[30%]'>
                    <h2 className='font-semibold mb-4'>Hero Background Image</h2>
                    <p className='text-gray-600'>Upload your site hero background from here.
                        
                        Image size should not be more than  10 MB</p>
                </div>
                <div className='basis-[70%] bg-white p-10'>
                <div
                    className={`w-full relative border-2 border-gray-300 border-dashed rounded-lg p-6 ${
                        isDragOver ? 'border-indigo-600' : ''
                    }`}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                    >
                    <input
                        type="file"
                        className="absolute inset-0 w-full h-full opacity-0 z-50"
                        name='backgroundImage'
                        onChange={(e) => handleFileChange('backgroundImage', e.target.files)}
                    />
                    <div className="text-center">
                        <img
                        className="mx-auto h-12 w-12"
                        src="https://www.svgrepo.com/show/357902/image-upload.svg"
                        alt=""
                        />

                        <h3 className="mt-2 text-sm font-medium text-gray-900">
                        <label htmlFor="file-upload" className="relative cursor-pointer">
                            <span>Drag and drop</span>
                            <span className="text-indigo-600"> or browse </span>
                            <span>to upload</span>
                            <input id="file-upload" name="file-upload" type="file" className="sr-only" />
                        </label>
                        </h3>
                        <p className="mt-1 text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                    </div>

                    {previewImages.backgroundImage && (
                        <img src={previewImages.backgroundImage} className="mt-4 mx-auto max-h-40" alt="Preview" />
                    )}
                    </div>
                </div>
            </div>
            <div className='flex p-[30px]'>
                <div className='basis-[30%]'>
                    <h2 className='font-semibold mb-4'>Information</h2>
                    <p className='text-gray-600'>Change your site information from here.</p>
                </div>
                <div className='basis-[70%] bg-white p-10'>
                    <div class="mb-5">
                        <label for="name" class="block mb-2 text-sm font-medium text-gray-900">Site Title</label>
                        <input type="text" id="name" name='siteTitle' value={formData.siteTitle} onChange={handleInputChange} class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                    </div>
                    <div class="mb-5">
                        <label for="name" class="block mb-2 text-sm font-medium text-gray-900">Hero Title</label>
                        <input type="text" id="name" name='heroTitle' value={formData.heroTitle} onChange={handleInputChange} class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                    </div>
                    <div class="mb-5">
                        <label for="name" class="block mb-2 text-sm font-medium text-gray-900">Hero Subtitle</label>
                        <input type="text" id="name" name='heroSubTitle' value={formData.heroSubTitle} onChange={handleInputChange} class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                    </div>
                    
                </div>
            </div>
            <div className='w-full flex justify-between items-center'>
                <a href='/' className='flex items-center gap-1 text-orange-600'>
                    <FaArrowAltCircleRight />
                    go to site
                </a>
                <div className='flex gap-2'>
                    <button type="submit" class="border border-orange-600 hover:bg-orange-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Reset</button>
                    <button type="submit" class="text-white bg-orange-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Update Settings</button>
                </div>
            </div>
        </form>
        </main>
    </div>
  )
}

export default General