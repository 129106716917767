import React, { useEffect, useState } from 'react'
import SupplierHeadbar from './components/SupplierHeadbar'
import SupplierSidebar from './components/SupplierSidebar'
import { FaTrash } from 'react-icons/fa6'
import { CiExport } from 'react-icons/ci'
import { FaRegEdit } from 'react-icons/fa'
import { useAuth } from '../../middleware/AuthContext'
import axios from 'axios'
import { server } from '../../server'
import { toast } from 'react-toastify'

const SupplierAds = () => {
    const [auth, setAuth] = useAuth();
    const [supplierAds, setSupplierAds] = useState([])
    const [selectedAd, setSelectedAd] = useState(null)
    const supplier = auth.supplier;
    const [modalOpen, setModalOpen] = useState(false)
    const [searchQuery, setSearchQuery] = useState('');

    // Function to format date string to yyyy-mm-dd format
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;
        return `${year}-${month}-${day}`;
    };

    const toggleModal = (supplierAd) => {
        setModalOpen(!modalOpen);
        setSelectedAd(supplierAd)
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
        const fetchAds = async () => { 
          try {
            const response = await axios.get(`${server}/ads/get-ads-supplier/${supplier._id}`);
            const formattedAds = response.data.ads.map(ad => ({
                ...ad,
                startDate: formatDate(ad.startDate),
                endDate: formatDate(ad.endDate)
            }));

            setSupplierAds(formattedAds)
            
          } catch (error) {
            
          } 
        };
        fetchAds();
      }, [supplier]);

      const handleDelete = async (e) => {
        try {
            console.log(selectedAd)
            await axios.delete(`${server}/ads/delete-ad/${selectedAd._id}`);
            
            toast.success('Data deleted successfully!');
            window.location.reload();
        } catch (error) {
            toast.error({error});
        }
    };
    const filteredAds = supplierAds.filter(supplierAd => {
        return (
          supplierAd.title.toLowerCase().includes(searchQuery.toLowerCase())
        )
    });

  return (
    <div className='flex'>
        <div className='w-[20%]'>
        <SupplierSidebar />
        </div>
        <main className='w-[80%]'>
       
        <SupplierHeadbar />
        <div className='p-10 w-full'>
        <div className='flex flex-wrap gap-3 items-center justify-center'>
            <table className='shadow bg-slate-100 block p-8 overflow-y-scroll'>
              <div className='flex justify-between mb-4'>
                <div className='flex gap-5 items-center justify-center'>
                  <form class="w-[200px] h-[40px]">   
                    <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only">Search</label>
                    <div class="relative">
                        <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                            <svg class="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                            </svg>
                        </div>
                        <input type="search" id="default-search" class="block w-[200px] h-[40px] p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-orange-500 focus:border-orange-500 outline-none" placeholder="Search Here..." onChange={handleSearchChange} required /> 
                    </div>   
                  </form>
                </div>
              </div>
              <thead className="text-xs text-gray-700 uppercase bg-[#fff]">
                        <tr className='border-b-2'>
                            <th scope="col" className="p-4">
                                <div className="flex items-center">
                                    <input id="checkbox-all-search" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                    <label for="checkbox-all-search" className="sr-only">checkbox</label>
                                </div>
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Title
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Description
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Product
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Initial Price
                            </th>
                            <th scope="col" className="px-6 py-3">
                                New Price
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Start Date
                            </th>
                            <th scope="col" className="px-6 py-3">
                                End Date
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                    {filteredAds && filteredAds.map(supplierAd => (
                            <tr key={supplierAd._id} className="bg-white border-b-2 hover:bg-gray-50">
                            <td className="w-4 p-4">
                                <div className="flex items-center">
                                    <input id="checkbox-table-search-1" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                                    <label for="checkbox-table-search-1" className="sr-only">checkbox</label>
                                </div>
                            </td>
                            <th scope="row" className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap">
                                <div className="ps-3">
                                    <div className="text-base font-semibold">{supplierAd.title}</div>
                                </div>  
                            </th>
                            <td className="px-4 py-4">
                                <span>{supplierAd.description}</span>
                            </td>
                            <td className="px-4 py-4">
                                <span>{supplierAd.productName}</span>
                            </td>
                            <td className="px-4 py-4">
                                <span>${supplierAd.initialPrice}</span>
                            </td>
                            <td className="px-4 py-4">
                                <span>${supplierAd.newPrice}</span>
                            </td>
                            <td className="px-4 py-4">
                                <span>{supplierAd.startDate}</span>
                            </td>
                            <td className="px-4 py-4">
                                <span>{supplierAd.endDate}</span>
                            </td>
                            <td className="px-6 py-4">
                                <div className='flex gap-2'>
                                    <a href={`/supplier/edit-ad/${supplierAd._id}`} data-twe-toggle="tooltip" title="Edit ad">
                                        <FaRegEdit color='green' size={20} />
                                    </a>  
                                    <button onClick={() => toggleModal(supplierAd)} data-twe-toggle="tooltip" title="Delete Ad">
                                        <FaTrash size={20} color="grey" />
                                    </button>
                                </div>
                            </td>
                        </tr>
                        ))}
                        
                    </tbody>
            </table>
        </div>
        </div>
        </main>
        {modalOpen && (
                <div id="popup-modal" tabIndex="-1" className="fixed top-[100px] left-[40%] z-50 justify-center items-center w-full">
                    <div className="relative p-4 w-full max-w-md max-h-full">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <button type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={toggleModal}>
                                <svg className="w-3 h-3" onClick={toggleModal} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                            <div className="p-4 md:p-5 text-center">
                                <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                                </svg>
                                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to delete this ad?</h3>
                                <button data-modal-hide="popup-modal" type="button" onClick={handleDelete} className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                                    Yes, I'm sure
                                </button>
                                <button data-modal-hide="popup-modal" onClick={toggleModal} type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">No, cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
  </div>
  )
}

export default SupplierAds