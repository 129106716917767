import React, { useEffect, useState } from 'react'
import { FaDollarSign, FaInfo, FaPhone } from 'react-icons/fa6';
import logo from '../assets/GooglePlayStore.png'
import { CiShop } from "react-icons/ci";
import axios from 'axios';
import { server } from '../server';


const ProductReview = ({product, closeModal}) => {
    const [suppliers, setSuppliers] = useState([]);

    useEffect(() => {
        const fetchSuppliers = async () => {
          try {
    
            const suppliersResponse = await axios.get(`${server}/suppliers/get-suppliers`);
            setSuppliers(suppliersResponse.data);
    
          } catch (error) {
    
          }
        };
        fetchSuppliers();
      }, []);

  return (
        <div>
            <div className='p-5 sm:w-[400px] w-[100%] rounded-md shadow-md bg-white'>
                <div className='sm:flex gap-2 border-b-2 border-dashed mb-3 pb-4'>
                    <div className='w-[50px] h-[50px] rounded-full bg-white border sm:flex hidden items-center justify-center border-blue-500'>
                        <img src={logo} alt='Reseller Avatar' className='w-[90%] h-[90%] rounded-full' />
                    </div>
                    <div>
                        <h1>{`${product.name}`}</h1>
                        <p>{product.category}</p>
                    </div>
                </div>
                <div>
                    <div className='flex justify-between text-gray-400 mb-3'>
                        <p className='flex gap-2 items-center justify-center'>
                            <CiShop />
                            Brand :
                        </p>
                        <p>{product.brand}</p>
                    </div>
                    <div className='flex justify-between text-gray-400 mb-3'>
                        <p className='flex gap-2 items-center justify-center'>
                            <FaDollarSign />
                            Price :
                        </p>
                        <p>$ {product.price}</p>
                    </div>
                    <div className='block text-gray-400 mb-3'>
                        <p className='flex gap-2 items-center'>
                          <FaInfo />
                            Description :
                        </p>
                        <p>{product.description}</p>
                    </div>
                    <div className='flex justify-between text-gray-400 mb-3'>
                        <p className='flex gap-2 items-center justify-center'>
                            <CiShop />
                            Company :
                        </p>
                        <a className='underline text-blue-400' href={`/supplier/preview/${product.supplier}`}>{suppliers.find(supplier => supplier._id === product.supplier)?.companyName}</a>
                    </div>
                    <div className='flex justify-between text-gray-400 mb-3'>
                        <p className='flex gap-2 items-center justify-center'>
                            <FaPhone />
                            Phone Number :
                        </p>
                       <p>{suppliers.find(supplier => supplier._id === product.supplier)?.phoneNumber}</p>
                    </div>
                    <div className="flex items-center justify-between">
                      {/* <a href={`/admin/edit-reseller/${supplier._id}`} className='bg-orange-600 text-center w-[48%] text-white px-3 p-1 rounded-sm'>Edit Details</a> */}
                      <button className='bg-orange-600 w-[100%] text-white px-3 p-1 rounded-md' onClick={closeModal}>Close Modal</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductReview